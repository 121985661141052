import { TYPE } from '../actions';
import { DataEvent } from '../../const';
import clone from '../../utils/clone';

export default function players(state, action) {
    if(state === undefined) {
        return null;
    }

    switch(action.type) {
        case TYPE.Init:
            return null;

        case TYPE.ErrorView:
            return null;

        case TYPE.Received:
            switch(action.data.type) {
                case DataEvent.Player.Player:
                    switch(action.data.player.type) {
                        case DataEvent.Player.Player:
                            if(state) {
                                const players = clone(state || { });
                                const player = clone(action.data.player.player);
                                players[ player.id ] = player;
                                return players;
                            } else {
                                return state;
                            }

                        case DataEvent.Player.Players:
                            return toObject(action.data.player.players);

                        default:
                            return clone(state);
                    }

                default:
                    return clone(state);
            }

        default:
            return clone(state);
    }
}

function toObject(playerArr) {
    const result = { };
    playerArr.forEach(p => result[ p.id ] = clone(p));
    return result;
}
