import React from 'react';
import { connect } from 'react-redux';

import Div from '../div';
import Button from '../button';
import * as Actions from '../../actions';
import * as Requests from '../../requests';

const Reconnect = ({ onReconnect }) =>
    <Div setup='c center center' hView='100'>
        <Div h='100'>
            <Div h='40'>Chyba spojenia</Div>
            <Div setup='c center center' h='60'>
                <Button text='Skús pripojiť...' w={140} onClick={onReconnect} delay={true} />
            </Div>
        </Div>
    </Div>

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => ({
    onReconnect: () => {
        dispatch(Actions.connection(Actions.CONNECTION.Sending, true));
        dispatch(Requests.sync());
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Reconnect)
