import React from 'react';
import { connect } from 'react-redux';

import Header from './header';
import WeekHeader from './weekHeader';
import GameListContainer from '../gameListContainer';
import Div from '../../../div';

const GamesContainer = ({ showGameDetail, futureGamesByWeek, isAdmin, now }) =>
    <React.Fragment>
        <Header />
        { futureGamesByWeek.map(
                (weekGames, n) => 
                    <Div key={n} setup='c start center' wRel='100'>
                        <Div wRel='100' h='60' class='fgDark fBig' style={{ textAlign: 'center' }}>
                            <WeekHeader now={now} weekGames={weekGames} />
                        </Div>
                        <GameListContainer games={weekGames.games}
                                           isAdmin={isAdmin}
                                           showGameDetail={game => showGameDetail(isAdmin, game)} />
                    </Div>
            )
        }
    </React.Fragment>

const mapStateToProps = state => ({
    futureGamesByWeek: state.get.futureGamesSplitByWeek(),
    isAdmin: state.user.player.isAdmin,
    now: state.time.now
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GamesContainer)
