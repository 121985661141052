import React from 'react';

import Div from '../../div';
import { AppConst } from '../../../../const';

export default ({ rink }) => {
    const rinkInfo = AppConst.RinksByKey[ rink ];
    return <Div css='ListItemDetail' style={{ backgroundColor:rinkInfo.bg }}>
        { rinkInfo.label }
    </Div>
}