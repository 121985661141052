import { TYPE } from '../actions';
import { DataEvent } from '../../const';
import clone from '../../utils/clone';

export default function ices(state, action) {
    if(state === undefined) {
        return null;
    }

    switch(action.type) {
        case TYPE.Init:
            return null;

        case TYPE.ErrorView:
            return null;

        case TYPE.Received:
            switch(action.data.type) {
                case DataEvent.Ice.Ice:
                    switch(action.data.ice.type) {
                        case DataEvent.Ice.Ices:
                            return clone(action.data.ice.ices);

                        case DataEvent.Ice.Ice:
                            const ices = clone(state || []);
                            const ice = clone(action.data.ice.ice);
                            mergeInto(ice, ices);
                            return ices;

                        default:
                            return clone(state);
                    }

                default:
                    return clone(state);
            }

        default:
            return clone(state);
    }
}

function mergeInto(ice, ices) {
    for(let i=0; i < ices.length; i++) {
        const match = ices[i];
        if(match.date.getTime() === ice.date.getTime() && match.rink === ice.rink) {
            ices[i] = ice;
            return;
        }
    }

    ices.push(ice);
}
