import React from 'react';
import { connect } from 'react-redux';

import EditModePlayerItem from './editMode/playerItem';
import EditModeUnregPlayerItem from './editMode/unregPlayerItem';
import MarkModePlayerItem from './markMode/playerItem';
import MarkModeUnregPlayerItem from './markMode/unregPlayerItem';
import PlayerItem from './playerItem';
import UnregPlayerItem from './unregPlayerItem';
import * as Requests from '../../../../requests';
import { DataEvent } from '../../../../../const';

const PlayerListContainer = ({ game, playerList, getPlayer, mode, actions, showPlayer, showPos, isAdmin, basePos=0 }) =>
    playerList.map(
        (playerId, i) => {
            const isUnregistered = playerId.indexOf(DataEvent.GameEvent.UnregisteredPlayer) === 0;

            switch(mode) {
                case 'edit':
                    return isUnregistered
                        ? <EditModeUnregPlayerItem key={playerId}
                                               game={game}
                                               playerId={playerId}
                                               actions={actions} />

                        : <EditModePlayerItem key={playerId}
                                              game={game}
                                              player={getPlayer(playerId)}
                                              actions={actions}
                                              showPlayer={showPlayer}
                                              isAdmin={isAdmin} />

                case 'mark':
                    return isUnregistered
                        ? <MarkModeUnregPlayerItem key={playerId}
                                               game={game}
                                               playerId={playerId}
                                               actions={actions} />

                        : <MarkModePlayerItem key={playerId}
                                              game={game}
                                              player={getPlayer(playerId)}
                                              actions={actions} />

                default:
                    return isUnregistered
                        ? <UnregPlayerItem key={playerId}
                                      game={game}
                                      playerId={playerId}
                                      pos={showPos ? basePos+i+1 : null} />

                        : <PlayerItem key={playerId}
                                      game={game}
                                      player={getPlayer(playerId)}
                                      showPlayer={showPlayer}
                                      pos={showPos ? basePos+i+1 : null}
                                      isAdmin={isAdmin} />
            }
        }
    );
    
const mapStateToProps = state => ({
    getPlayer: playerId => state.get.playerById(playerId),
    isAdmin: state.user.player.isAdmin
});

const mapDispatchToProps = dispatch => ({
    actions: {
        moveToBackup: (game, playerId) => dispatch(
            Requests.movePlayerToBackup(game.ice.date, game.ice.rink, game.gameDate, playerId)
        ),

        moveToPlayers: (game, playerId) => dispatch(
            Requests.movePlayerToPlayers(game.ice.date, game.ice.rink, game.gameDate, playerId)
        ),

        removePlayer: (game, playerId) => dispatch(
            Requests.removePlayer(game.ice.date, game.ice.rink, game.gameDate, playerId)
        ),

        markPlayerDark: (game, playerId, isDark) => dispatch(
            Requests.markPlayerDark(game.ice.date, game.ice.rink, game.gameDate, playerId, isDark)
        ),

        markPlayerPaid: (game, playerId, playerName, hasPaid) => {
            if(global.confirm(`${playerName} ${hasPaid ? 'platil?' : 'neplatil?'}`)) {
                dispatch(
                    Requests.markPlayerPaid(game.ice.date, game.ice.rink, game.gameDate, playerId, hasPaid)
                )
            }
        }
    }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerListContainer)
