import React from 'react';

import Div from '../../../../div';

export default ({ game }) => {
    const h = 50;
    const s = 42;
    const colors = countColors(game);

    return <React.Fragment>
        <Div h={h} />

        <Div setup='r center center' wRel='100' h={h} class='bgWhite'
             style={{ position: 'fixed', bottom: '60px', left:'0px' }}>
            <Div setup='r between center' class='container bgWhite padLeftSmall padRightSmall padTopSmall'
                 style={{borderTop: '1px solid gray'}}>
                <Div setup='r center center' fx={s+10} h={s} class={'cypressGameMark fMega bgOn'}>
                    {countPaid(game)}
                </Div>

                <Div> spolu </Div>

                <Div setup='r start center'>
                    <Div setup='r center center' h={s} w={s+10} class={'cypressGameMark fMega bgWhite bordSilver'}>
                        {colors.light}
                    </Div>
                    <Div w='8' />

                    <Div setup='r center center' h={s} w={s+10} class={'cypressGameMark fMega bgMarkDark fgWhite'}>
                        {colors.dark}
                    </Div>
                </Div>
            </Div>
        </Div>
    </React.Fragment>
}

function countPaid(game) {
    let paid = 0;
    game.players.forEach(id => game.mark.paid[ id ] === true ? paid++ : null);
    return paid;
}

function countColors(game) {
    let dark = 0;
    let light = 0;

    game.players.forEach(
        id => game.mark.color[ id ] === true
            ? dark++
            : (game.mark.color[ id ] === false
                ? light++
                : null
            )
    );

    return { dark, light };
}
