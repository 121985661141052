import React from 'react';

import Div from '../div';
import Input from '../formInput';

export default ({ prefix, telNoHint }) => <React.Fragment>
    <Div setup='c between start' wRel='100' hRel='100'>
        <Input name='firstName' prefix={prefix} type='text' label='Meno' maxLength='100' />
        <Input name='lastName' prefix={prefix} type='text' label='Priezvisko' maxLength='100'/>
        <Input name='email' prefix={prefix} type='text' label='Email' maxLength='100' />
        <Input name='tel' prefix={prefix} type='text' label='Telefón' maxLength='20' placeholder='+421901123456' />
    </Div>
</React.Fragment>
