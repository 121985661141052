import React from 'react';

import Div from './div';
import Button from './button';
import { Event } from '../events';
import { FormContext } from '../context';
import clone from '../../utils/clone';

export default class Form extends React.Component {
    constructor(props) {
        super(props);

        this.timeout = null;
        this.addTimeout = this.addTimeout.bind(this);
        this.clearTimeout = this.clearTimeout.bind(this);
        
        this.isValid = this.isValid.bind(this);
        this.setValue = this.setValue.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);

        this.events = {
            onChange: this.onChange,
            isValid: new Event(),
            focus: new Event()
        }

        this.validation = props.validation;

        this.state = {
            valid: true
        }
    }

    componentDidMount() {
        this.setState({ valid: this.isValid() });
    }

    componentWillUnmount() {
        this.clearTimeout();
        this.events.isValid.clear();
        this.events.focus.clear();
    }

    clearTimeout() {
        if(this.timeout !== null) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
    }

    addTimeout(func) {
        this.clearTimeout();
        this.timeout = setTimeout(func, 0);
    }

    isValid() {
        let valid = true;
        this.events.isValid.emit()
                           .forEach(result => valid = valid && result);
        return valid;
    }

    setValue(object, name, value) {
        if(Array.isArray(name)) {
            const prop = object[name[0]];
            if(prop === undefined || prop === null) {
                object[name[0]] = { };
            }
            object[name[0]][name[1]] = value;
        } else {
            object[name] = value
        }
    }

    onChange(name, value) {
        this.setValue(this.props.data, name, value);
        this.setState({ valid: true });
    }

    onSubmit(event) {
        this.props.onSubmit(clone(this.props.data));
    }

    onCancel(event) {
        this.props.onCancel();
    }

    render() {
        const p = this.props;
        
        const context = {
            data: p.data,
            events: this.events,
            validation: this.validation
        }

        this.addTimeout(
            () => {
                const isValid = this.isValid();
                if(isValid !== this.state.valid) {
                    this.setState({ valid: isValid })
                }
            }
        );

        return <Div setup='r center center' wRel='100' id={p.id}>
            <Div class='container padRightSmall'>
                <FormContext.Provider value={context}>
                    { p.children }
                </FormContext.Provider>

                { p.fixedButtons
                    ? fixedButtonSection(this.renderButtons())
                    : normalButtonSection(this.renderButtons())
                }
            </Div>
        </Div>
    }

    renderButtons() {
        const p = this.props;
        const s = this.state;

        return <Div setup='r around center' h='80' wRel='100' css={p.fixedButtons ? 'ButtonSection' : undefined}>
            <Button w={s.valid ? (p.submitWidth || 100) : (p.invalidWidth || 100)} id='cypress.form.submit'
                    enabled={s.valid} text={p.submitText} disabledText={p.invalidText}
                    onClick={this.onSubmit} delay={p.noSubmitDelay ? false : true} />
            { p.onCancel
                ? <Button w={p.cancelWidth || 100} text={p.cancelText} onClick={this.onCancel} id='cypress.form.cancel' />
                : null
            }
        </Div>
    }
}

function normalButtonSection(content) {
    return <Div setup='r around center' h='80'>
        { content }
    </Div>
}

function fixedButtonSection(content) {
    return <React.Fragment>
        <Div css='ButtonSectionPadding' />
        <Div setup='r center center' class='fixedDown' wRel='100'>
            <Div css='ButtonSection' setup='r around center' class='container'>
                { content }
            </Div>
        </Div>
    </React.Fragment>
}
