import React from 'react';

import Div from './div';
import * as Css from './css';

export default class Button extends React.Component {
    constructor(props) {
        super(props);
        this.state = { on: props.on || false };
        this.onClick = this.onClick.bind(this);
    }

    onClick(event) {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        
        const p = this.props;
        const enabled = p.enabled !== undefined ? p.enabled : true;

        if(p.confirm && !p.confirm()) {
            return;
        }
        
        if(p.controlled) {
            if(p.onClick) {
                p.onClick();
            }
        } else {
            if(enabled) {
                this.setState({ on: !this.state.on });

                if(p.onClick) {
                    if(p.delay) {
                        setTimeout(p.onClick, 400);
                    } else {
                        p.onClick();
                    }
                }
            }
        }

        return true;
    }

    render() {
        const p = this.props;
        const h = p.h || 50;
        const w = p.w || 100;
        const w2 = p.multiLine ? w - 20 : undefined;

        const on = p.controlled ? p.on : this.state.on;

        const enabled = p.enabled !== undefined ? p.enabled : true;
        const hHalf = h / 2;
        const style={
            borderRadius: (hHalf < 30 ? hHalf : 30) + 'px',
            transition: p.delay ? 'background-color 0.4s, color 0.4s' : undefined
        }

        const texts = enabled ? p.text : p.disabledText;
        const lines = Array.isArray(texts) ? texts : [ texts ];

        return <Div w={w} h={h} setup='r center center' style={style} id={p.id}
                    class={'button unselectable ' + Css.button(enabled, on)}
                    onClick={this.onClick}>
            <Div setup='c center center' w={w2} style={{ textAlign: 'center' }}>
                { lines.map((line, n) => <Div key={n}>{line}</Div>) }
            </Div>
        </Div>
    }
}

