import { connect } from 'react-redux';

import UserProfileForm from './userProfileForm';
import * as Requests from '../../../requests';
import * as Actions from '../../../actions';

const mapStateToProps = state => ({
    info: state.user.player.info,
    reg: state.user.player.reg
})

const mapDispatchToProps = dispatch => ({
    onSubmit: (info, reg, imageData) => {
    	const data = { };
    	if(info) {
    		data.info = info;
    	}
    	if(reg) {
    		data.reg = reg;
    	}
    	if(imageData) {
    		data.imageData = imageData;
    	}

        dispatch(Requests.updatePlayer(data));
        dispatch(Actions.popView());
    },
    onCancel: () => dispatch(Actions.popView())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserProfileForm)
