import React from 'react';
import FormComponentPlayerInfo from '../formComponentPlayerInfo';
import UserProfileImageEditor from  '../userProfileImageEditor';
import Div from '../../div';
import Form from '../../form';
import Input from '../../formInput';
import * as Format from '../../../../utils/format';
import * as Validation from '../../formValidation';
import clone from '../../../../utils/clone';

const validation = {
    info: {
        firstName:          { required: true },
        lastName:           { required: true },
        tel:                { required: true, validate: Validation.telNumber },
        email:              { required: true }
    }
}

function createData(info, reg) {
    return {
        info: {
            firstName: info.firstName,
            lastName: info.lastName,
            email: info.email,
            tel: Format.telNumber(info.tel || ''),
        },
        reg: {
            emailNotifyDummy: true,
            ruleMailinglist: reg.ruleMailinglist
        }
    }
}

export default class UserProfileForm extends React.Component {
    constructor(props) {
        super(props);

        this.profileImg = null;
        this.setProfileImgRef = this.setProfileImgRef.bind(this);
        this.formSubmit = this.formSubmit.bind(this);

        this.state = {
            data: createData(props.info, props.reg),
            origData: createData(props.info, props.reg)
        }
    }

    static getDerivedStateFromProps(nextProps, currentState) {
        const nextInfo = nextProps.info;
        const nextReg = nextProps.reg;
        const origInfo = currentState.origData.info;
        const origReg = currentState.origData.reg;

        if( nextInfo.firstName !== origInfo.firstName
            || nextInfo.lastName !== origInfo.lastName
            || Format.telNumber(nextInfo.tel || '') !== origInfo.tel
            || nextInfo.email !== origInfo.email
            || nextReg.ruleMailinglist !== origReg.ruleMailinglist
        ) {
            return {
                data: createData(nextInfo, nextProps.reg),
                origData: createData(nextInfo, nextProps.reg)
            }
        } else {
            return null;
        }
    }

    setProfileImgRef(profileImg) {
        this.profileImg = profileImg;
    }

    formSubmit(formResult, origData) {
        const props = this.props;

        if(this.profileImg) {
            const imageDataUrl = this.profileImg.getImage()
            if(imageDataUrl === false) {
                return;
            }
            submit(origData, formResult, imageDataUrl, props.onSubmit, props.onCancel)
        } else {
            submit(origData, formResult, null, props.onSubmit, props.onCancel)
        }
    }

    render() {
        const props = this.props;
        const state = this.state;
        const origData = clone(state.data);
    
        return <Div setup='c start center' css='Form'>
            <Div fx='360'>
                { props.info && props.info.imageUrl
                    ? <UserProfileImageEditor info={props.info} ref={this.setProfileImgRef} />
                    : null
                }
            </Div>
            <Form data={state.data} validation={validation} id='cypress.profile'
                  onSubmit={formResult => this.formSubmit(formResult, origData)} submitText='Ulož' submitWidth='100'
                  onCancel={props.onCancel} cancelText='Zavri' cancelWidth='100'
                  invalidText='vyplň chýbajúce' invalidWidth='160'
                  fixedButtons={true}>

                <b>Profil:</b>
                <FormComponentPlayerInfo prefix='info' />
                <br/>

                <hr />
                <b>Nastavenie emailov:</b>
                <Input prefix='reg' name='emailNotifyDummy' type='check' readonly={true} label='Emaily týkajúce sa tvojej účasti' />
                <Div style={{ paddingLeft: '25px', color: '#666' }}> - súpisky na ľady na ktoré si prihlásený, zmeny účasti a podobne</Div>
                <br />

                <Input prefix='reg' name='ruleMailinglist' type='check' label='Chcem byť v mailing liste' />
                <Div style={{ paddingLeft: '25px', color: '#666' }}> - informačné emaily, novinky, nové termíny</Div>
            </Form>
        </Div>
    }
}

function submit(input, formResult, imageData, onSubmit, onCancel) {
    delete formResult.reg.emailNotifyDummy;

    let changesInfo = Validation.hasChanged(input.info, formResult.info, 'firstName');
    changesInfo += Validation.hasChanged(input.info, formResult.info, 'lastName');
    changesInfo += Validation.hasChanged(input.info, formResult.info, 'email');
    changesInfo += Validation.hasChanged(input.info, formResult.info, 'tel');

    let changesReg = Validation.hasChanged(input.reg, formResult.reg, 'ruleMailinglist');
    
    if(changesInfo + changesReg > 0 || imageData) {
        onSubmit(
            changesInfo > 0 ? formResult.info : undefined,
            changesReg > 0 ? formResult.reg : undefined,
            imageData
        );
    } else {
        onCancel();
    }
}
