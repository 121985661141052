import React from 'react';

import FormComponentPlayerInfo from '../../formComponentPlayerInfo';
import UserProfileImageEditor from  '../../userProfileImageEditor';
import * as Validation from '../../../formValidation';
import Div from '../../../div';
import Form from '../../../form';
import Input from '../../../formInput';
import * as Format from '../../../../../utils/format';
import clone from '../../../../../utils/clone';

const validation = {
    info: {
        firstName:          { required: true },
        lastName:           { required: true },
        tel:                { required: true, validate: Validation.telNumber },
        email:              { required: true }
    }
}

function createData(player) {
    const info = player.info;
    const reg = player.reg;

    return {
        info: {
            firstName: info.firstName,
            lastName: info.lastName,
            email: info.email,
            tel: Format.telNumber(info.tel || '')
        },
        reg: {
            ruleEquipment: reg.ruleEquipment,
            ruleGames: reg.ruleGames,
            ruleAgreed: reg.ruleAgreed,
            ruleMailinglist: reg.ruleMailinglist,
            emailNotifyDummy: true
        },
        hadDidntRead: player.hadDidntRead,
        adminNote: player.adminNote
    }
}

export default class ProfileForm extends React.Component {
    constructor(props) {
        super(props);

        this.setProfileImgRef = this.setProfileImgRef.bind(this);
        this.formSubmit = this.formSubmit.bind(this);

        this.state = {
            data: createData(props.player),
            origData: createData(props.player)
        }
    }

    setProfileImgRef(profileImg) {
        this.profileImg = profileImg;
    }

    static getDerivedStateFromProps(nextProps, currentState) {
        const nextPlayer = nextProps.player;
        const nextInfo = nextPlayer.info;
        const nextReg = nextPlayer.reg;

        const origPlayer = currentState.origData;
        const origInfo = origPlayer.info;
        const origReg = origPlayer.reg;
        
        if( nextInfo.firstName !== origInfo.firstName
            || nextInfo.lastName !== origInfo.lastName
            || Format.telNumber(nextInfo.tel || '') !== origInfo.tel
            || nextInfo.email !== origInfo.email

            || nextReg.ruleEquipment !== origReg.ruleEquipment
            || nextReg.ruleGames !== origReg.ruleGames
            || nextReg.ruleAgreed !== origReg.ruleAgreed
            || nextReg.ruleMailinglist !== origReg.ruleMailinglist

            || nextPlayer.hadDidntRead !== origPlayer.hadDidntRead
            || nextPlayer.adminNote !== origPlayer.adminNote
        ) {
            return {
                data: createData(nextPlayer),
                origData: createData(nextPlayer)
            }
        } else {
            return null;
        }
    }

    formSubmit(formResult, origData) {
        const props = this.props;
        const player = props.player;

        if(this.profileImg) {
            const imageData = this.profileImg.getImage()
            if(imageData === false) {
                return;
            }
            submit(origData, formResult, imageData, player.id, props.onSubmit, props.onCancel)
        } else {
            submit(origData, formResult, null, player.id, props.onSubmit, props.onCancel)
        }
    }

    render() {
        const props = this.props;
        const state = this.state;
        const player = props.player;
        const origData = clone(state.data);

        return <Form data={state.data} validation={validation} bsPadding={true} fixedButtons={true}
              onCancel={props.onCancel} cancelText='Zahodiť zmeny' cancelWidth='140'
              onSubmit={formResult => this.formSubmit(formResult, origData)} submitText='Uložiť'
              invalidText='vyplň chýbajúce' submitWidth='100' invalidWidth='140'>
            <Div fx='360'>
                { player.info && player.info.imageUrl
                    ? <UserProfileImageEditor info={player.info} ref={this.setProfileImgRef} />
                    : null
                }
            </Div>
            <hr/>
            <Input name='hadDidntRead' type='check' label='zaškrtol chuja!' />
            <hr/>
            <b>Profil:</b>
            <FormComponentPlayerInfo prefix='info' telNoHint={false} />
            <Input name='ruleEquipment' prefix='reg' type='check' label='Poučený - výstroj' />
            <Input name='ruleGames' prefix='reg' type='check' label='Súhlasí s pravidlami odhlasovania' />
            <Input name='ruleAgreed' prefix='reg' type='check' label='Pozná pravidlá' />

            <hr />
            <b>Nastavenie emailov:</b>
            <Input name='emailNotifyDummy' prefix='reg' type='check' label='Notifikácie' readonly={true} />
            <Input name='ruleMailinglist' prefix='reg' type='check' label='Mailing list' />
            <hr />
            <Input name='adminNote' type='textArea' label='Admin poznámka (max 500 znakov)' maxLength={500} />
        </Form>
    }
}

function submit(input, formResult, imageData, playerId, onSubmit, onCancel) {
    delete formResult.reg.emailNotifyDummy;

    let changes = Validation.hasChanged(input.info, formResult.info, 'firstName');
    changes += Validation.hasChanged(input.info, formResult.info, 'lastName');
    changes += Validation.hasChanged(input.info, formResult.info, 'email');
    changes += Validation.hasChanged(input.info, formResult.info, 'tel');
    if(Object.keys(formResult.info).length === 0) {
        delete formResult.info;
    }

    changes += Validation.hasChanged(input.reg, formResult.reg, 'ruleEquipment');
    changes += Validation.hasChanged(input.reg, formResult.reg, 'ruleGames');
    changes += Validation.hasChanged(input.reg, formResult.reg, 'ruleAgreed');
    changes += Validation.hasChanged(input.reg, formResult.reg, 'ruleMailinglist');
    if(Object.keys(formResult.reg).length === 0) {
        delete formResult.reg;
    }

    changes += Validation.hasChanged(input, formResult, 'hadDidntRead');
    changes += Validation.hasChanged(input, formResult, 'adminNote');

    if(changes > 0 || imageData) {
        onSubmit(playerId, formResult, imageData);
    } else {
        onCancel();
    }
}
