import React from 'react';
import { connect } from 'react-redux';

import Menu from './menu';
import * as Actions from '../../actions';

const MenuContainer = ({ onLogout, getTopView, showView, canNavigateBack, navigateBack, navigateToBaseView, player }) =>
    <Menu onLogout={onLogout}
          canNavigateBack={canNavigateBack} navigateBack={navigateBack}
          navigateToBaseView={navigateToBaseView}
          isDev={player.isDev}
          isAdmin={player.isAdmin}
          showView={showView}          
          views={{
              playerProfile: Actions.VIEW.UserProfile,
              adminIces: Actions.VIEW.IceList,
              adminPlayers: Actions.VIEW.Players,
              adminMailingList: [ Actions.VIEW.PlayerEmails, { mailingList: true  } ],
              charvatContact: Actions.VIEW.CharvatContact
          }}
    />

const mapStateToProps = state => ({
    player: state.user.player,
    getTopView: () => state.get.view.topView(false),
    canNavigateBack: () => state.view.stack.length > 1
                           && state.view.connection === null
});

const mapDispatchToProps = dispatch => ({
    onLogout: () => dispatch(Actions.logout()),
    showView: props => {
        let view = props;
        let params = null;
        if(Array.isArray(props)) {
            view = props[0];
            params = props[1];
        }
        dispatch(Actions.pushView(view, params, true));
    },
    navigateBack: () => dispatch(Actions.popView()),
    navigateToBaseView: () => dispatch(Actions.baseView())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuContainer)
