import React from 'react';

import IceItemDate from './iceItemDate';
import Helper from './helper';
import IceRink from '../ice/rink';
import Div from '../../div';
import SkillLevel from '../../skillLevel';
import padStart from '../../../../utils/padStart';

export default ({ ice, inList, overrideInList, selectIce, clss = '', helperActions, isAdmin }) =>
    <Div setup='r between center' wRel='100' id='cypress.content.iceItem'
         css={inList || overrideInList ? 'AdminItemListItem' : 'AdminItemHeader'}
         class={clss + ' ' + (inList ? 'clickable' : undefined)}
         onClick={inList ? selectIce : null}>

        <Div setup='c around start'>
            <Div setup='r start center'>
                <IceItemDate from={ice.startDt} to={ice.endDt} vip={ice.vip} />
            </Div>
            <SkillLevel level={ice.lvl} showInfoView={false} />
            <Div class='fSmall'>
                <IceRink name='rink' value={ice.rink} isNew={false} />
            </Div>
            
            { isAdmin && helperActions ?(
                    helperActions.show
                    ? <Helper inList={inList} ice={ice} helperActions={helperActions} />
                    : null
                )
                : null
            }
        </Div>
        
        <Div fx='120' class='padRight' style={{ textAlign: 'right'}}>
            { time(ice.startDt) } { rink(ice.rink) }
        </Div>
    </Div>

function time(d) {
    return d.getHours() + ':'
           + padStart(d.getMinutes().toString(), 2, '0');
}

function rink(r) {
    return r.toUpperCase();
}
