import React from 'react';
import { connect } from 'react-redux';

import IceListHeader from './iceListHeader';
import IceListContainer from './iceListContainer';
import Div from '../../../div';

const WeeklyIcesContainer = ({ weeklyIces }) =>
    <React.Fragment>
        <IceListHeader />
        <Div setup='c start center' wRel='100'>
            <IceListContainer ices={weeklyIces} />
        </Div>
    </React.Fragment>

const mapStateToProps = state => ({
    weeklyIces: state.get.weeklyIces(),
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WeeklyIcesContainer)
