export default function clone(obj) {
    if(obj !== null && obj !== undefined) {
        if(obj instanceof Date) {
            return new Date(obj.getTime());
        } else {
            if(obj instanceof Array) {
                return obj.map(item => clone(item));
            } else {
                const type = typeof obj;

                if(type === 'object') {
                    const result = { };
                    Object.keys(obj).forEach(key => {
                        result[ key ] = clone(obj[ key ]);
                    });
					return result;
                } else {
                    if(type === 'string') {
                        return obj.slice(0);
                    } else {
                        return obj;
                    }
                }
            }
        }
    }

    return obj;
}
