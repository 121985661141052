import React from 'react';

import FormComponentPlayerInfo from '../formComponentPlayerInfo';
import Div from '../../div';
import Input from '../../formInput';
import RuleButton from '../ruleButton';

const skillOptions = [
    { label: [ 'áno,', 'skvele', '(100%)' ], value: 100 },
    { label: [ 'viacmenej', 'áno', '(70%)'], value: 70 },
    { label: [ 'horko', 'ťažko', '(30%)'], value: 30 },
    { label: [ 'vôbec', '(0%)'], value: 0 }
];

export default () => <React.Fragment>
    <h2>HKJ.SK: hokej pre amatérskych / hobby hráčov v Bratislave</h2>
    <b>Tento hokej je pre hobby hráčov</b>
    <p>Nie je pre bývalých hráčov, čo trénovali za kluby, ale ani bývalých dorastencov, juniorov, žiakov.<br/>
    Nevieš aká si úroveň? Brnkni mi.
    </p>

    <b>Registrácia si vyžaduje niekoľko minút pozornosti.</b>
    <p>Na ploche je nás 20. Prenájom ľadu je na 60 minút. Je dôležité rozumieť tomu, kedy sa má čo udiať.</p>
    
    <b>Stojíme iba o hráčov čo rešpektujú pokyny a pravidlá.</b>
    <p>Počas ľadu si chceme zahrať. Nie vysvetlovať pokyny alebo sa o nich dohadovať.</p>

    <Div fx='45' wRel='100' wMax='450' setup='r center center' w='400px'>
        <Div fx='240'>
            <RuleButton />
        </Div>
    </Div>

    <h2>Kontaktné údaje</h2>
    <Div style={{marginTop: '10px'}}>
        <FormComponentPlayerInfo prefix='info' />
    </Div>

    <h2>Skill</h2>
    <p>Každý hráč hrá nejakú úroveň. Spoznáme ťa podľa toho, že nám niečo o sebe povieš.</p>

    <Input  name='aboutMe' prefix='reg' type='textArea' maxLength='10000'
            label='Povedz niečo o sebe:' />
    <i>(napr: Ako dlho hrávaš? Kedy si naposledy hral? Ako si sa k nám dostal?...)</i><br/>

    <h3>Ovládam:</h3>
    <Input  name='skillBraking' prefix='reg' type='radio' options={skillOptions}
            label='Zaseknutie (zabrzdenie) doľava aj doprava' />
    <br/>

    <Input  name='skillTurning' prefix='reg' type='radio' options={skillOptions}
            label='Prekladanie dopredu' />
    <br/>
    
    <Input  name='skillTurningBack' prefix='reg' type='radio' options={skillOptions}
            label='Prekladanie dozadu' />
    <br/>

    <h3>Súhlasím:</h3>
    <Input name='ruleEquipment' prefix='reg' type='check' 
           label='Som si vedomý kedy sa vstupuje na ľad. Mám prilbu, bez ktorej nemôžem na ľad.' />
    <br/>
    
    <Input name='ruleGames' prefix='reg' type='check'
           label='Odhlásiť sa bezplatne je možné 2 dni pred ľadom do 13:00. Súhlasím, že si vstup zaplatím, ak nebudem môcť prísť a bude už po tomto termíne.' />
    <br/>

    <Input name='didntRead' prefix='reg' type='check'
           label='Viem, že keď zakliknem toto tlačítko, tak to znamená, že som chuj a nečítam, čo zaklikávam a moja registrácia je neplatná.' />
    <br/>

    <Input prefix='reg' name='ruleAgreed' type='check'
           label='Poznám ostatné pravidlá a súhlasím s nimi.' />
    <br/>

    <Input prefix='reg' name='ruleMailinglist' type='check'
           label='Viem, že budem zaradený do mailing listu, z ktorého sa môžem kedykoľvek odhlásiť.' />
    <br/>

    <Input prefix='reg' name='emailNotifyDummy' type='check'
           label='Viem, že budem dostávať emailom upozornenia na zmeny a súpisky, pre termíny na ktoré som prihlásený.'
           confirm={emailNotifyRequired} />
    <br/>

    <Input name='comment' prefix='reg' type='textArea' maxLength='10000'
           label='Ak chceš ešte niečo napísať, tu máš priestor:' />
    <i>(nápad, výhrada k pravidlám, pochvala, pozdrav, básnička o snehuliakovi... čokoľvek chceš)</i><br/>
</React.Fragment>

function emailNotifyRequired() {
    global.window.confirm('Upozornenia pre termíny na ktoré si prihlásený, sú povinné.');
    return false;
}
