import React from 'react';
import Modal from 'react-modal';

import Div from '../div';
import Button from '../button';

import './modal.css';

export default class ModalMessage extends React.Component{
    constructor(props) {
        super(props);
        
        this.state = {
            isOpen: this.props.isOpen
        }
    }

    open() {
        this.setState({ isOpen: true });
    }

    close(resolve, ignores = false) {
        const canIgnore =
            this.props.canIgnore !== null && this.props.canIgnore !== undefined
                ? this.props.canIgnore
                : true;

        if(!ignores || canIgnore) {
            this.setState({ isOpen: false });
            
            if(resolve) {
                resolve();
            }
        }
    }

    render() {
        const p = this.props;
        const appElement = global.document.getElementById('root');
        const textLines = Array.isArray(p.text) ? p.text : [ p.text ];

        return <Modal
            isOpen={this.state.isOpen}
            className={p.fullScreen ? (p.fromTop ? 'modalBase modalFullTop' : 'modalBase modalFull') : 'modalBase modal'}
            overlayClassName='modalBase modalOverlay'
            ariaHideApp={true}
            appElement={appElement}
            onRequestClose={() => this.close(null, true)}
            style={{ content: p.contentStyle }}>
            <Div setup='c around start' wRel='100' h={p.h || 120}>
                <Div class={p.smallFont ? 'fSmall' : 'fMedium'}>
                    { textLines.map(
                        (line, n) => <React.Fragment key={n}>
                            <Div>{ line }</Div>
                            <br/>
                        </React.Fragment>
                    )}
                </Div>
                <Div setup={`${p.vertical ? 'c' : 'r'} around center`} wRel='100' h={p.vertical ? undefined : 60}>
                    { p.textOk
                        ? <Button controlled={true} on={false} text={p.textOk} onClick={() => this.close(p.onOk)}
                                  w={p.wOk} h={p.hOk} multiLine={true} />
                        : null
                    }
                    { p.vertical ? <Div h='10' /> : null }
                    { p.textCancel
                        ? <Button controlled={true} on={false} text={p.textCancel} onClick={() => this.close(p.onCancel)} 
                                  w={p.wCancel} h={p.hCancel} multiLine={true} />
                        : null
                    }
                </Div>
            </Div>
        </Modal>
    }
}
