import React from 'react';

import Div from '../../div';
import * as DateTime from '../../../../utils/dateTime';

export default ({ now, date, from, clss, isVip }) =>
    <React.Fragment>
        { isVip ? <Div w='40' style={{ fontWeight: 'bold', color: 'red' }}>VIP</Div> : null }
        <Div class={clss}>{ dayDesc(date) + fromDesc(now, from) }</Div>
    </React.Fragment>

function dayDesc(date) {
    return DateTime.titleDayOfWeek(date.getDay(), true);
}

function fromDesc(now, from) {
    return from !== null && from > now
        ? ` od ${from.getDate()}.${from.getMonth()+1}`
        : '';
}
