import React from 'react';

import Div from '../../../div';

export default ({ playerId, game, pos }) => {
    const flags = game.playerFlags[ playerId ];
    const style = flags.warning ? { fontStyle: 'italic' } : undefined;

    return <Div setup='r start center' wRel='100' h='45'>
        { pos !== null
            ? <Div fx='50' class='fMega' style={{ textAlign: 'center' }}>{pos}.</Div>
            : <Div fx='50' />
        }

        <Div setup='c start start' style={style} wRel='100'>
            <Div wRel='100'>{flags.name}</Div>
            { flags.warning
                ? <Div class='fSmall'>hľadá náhradníka</Div>
                : null
            }
        </Div>

        <Div fx='50' />
    </Div>
}
