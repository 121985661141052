import React from 'react';

import * as DateTime from '../../../../../../utils/dateTime';

const days = [ 1, 2, 3, 4, 5, 6, 0 ].map(d => ({ key: d, label: DateTime.titleDayOfWeek(d, true, false) }));

export default ({ name, value, onChange, isNew }) =>
    isNew
        ? <select style={{width: '100%', height: '30px'}} className='fMedium'
                  onChange={event => onChange(name, parse(event.target.value))} value={value.getDay()}>
            { days.map(
                day => <option key={day.key} value={day.key}>{day.label}</option>
            )}
        </select>
        : days[ (value.getDay()+6)%7 ].label

function parse(value) {
    return value ? parseInt(value, 10) : value;
}
