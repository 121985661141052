import React from 'react';

import Div from '../../div';
import TelNumber from '../../telNumber';
import RuleButton from '../ruleButton';
import { AppConst } from '../../../../const';

const Charvat = AppConst.ContactCharvat;

const center = { };
const margin = { margin: '10px', marginTop: '0px' };
const h1Font = { fontSize: '1.8em' };
const h2Font = { fontSize: '1.3em' };
const gray = { color: '#555' };

export default ({ loginButton }) => <Div setup='c start start' style={margin}>
    <h1 style={Object.assign({}, center, h1Font)}>HKJ.SK: hokej pre amatérskych / hobby hráčov v Bratislave</h1>
    
    <b>Pre koho to je?</b>
    <span style={gray}>
        <p>Pre chalanov a chlapov, ktorí kedysi hrali hokej na rybníku a teraz sa chcú k tomu vrátiť.</p>
    </span>        
    
    <b>Ako sa hrá?</b>    
    <span style={gray}>
        <p>Hrá sa tímovo: tzn. nahrávať, aj striedať.<br/>
        Hráme slušne, gentlemansky a férovo.<br/>
        Hrá sa bezkontaktne.<br/>
        Netolerujeme porušovanie týchto zásad.</p>
    </span>

    <p><b>Prečo robíme tieto hokejové partičky?</b></p>
    <span style={Object.assign({}, center, h2Font)}>
        Aby si otcovia občas zahrali hokej,<br/>
        <b>pretože ich synovia to po nich budú chcieť opakovať.</b>
    </span>
    <br/>

    <b>Herné úrovne:</b>
    <span style={gray}>
        <ul>
            <li>hráš dosť zle (ľahká úroveň)</li>
            <li>ako tak dobre (ľahšia stredná úroveň)</li>
            <li>ide ti to lepšie ako väčšine (stredná úroveň)</li>
        </ul>
    </span>
    <br/>

    <Div fx='45' wRel='100' wMax='450' setup='r center center' w='400px'>
        <Div fx='240'>
            <RuleButton />
        </Div>
    </Div>
    <br/>
    <br/>

    <Div fx='45' wRel='100' wMax='450' setup='r center center' w='400px'>
        <Div fx='220'>
            { loginButton }<br/><br/>
            Nejde ti registrácia?<br/>
            Zavolaj mi na: <TelNumber tel={Charvat.Tel}/>
        </Div>
    </Div>
    <br/>

    <br/>
    <br/>

    <b>Kontakt:</b>
    <TelNumber tel={Charvat.Tel}/>
    <a href={'mailto:' + Charvat.Email}>{Charvat.Email}</a><br/>
</Div>
