import React from 'react';

import Div from '../../../div';
import Icon from '../../../icon';
import { AppConst } from '../../../../../const';
import clone from '../../../../../utils/clone';

export default ({ state, updateState, showSkillInfo }) =>
    <Div setup='r start start' wRel='100' class='margLeftSmall zIndexBack'>
        <Div fx='140' setup='c start start' style={{background: 'white'}}>
            <Div fx='35' setup='r start center' class='padLeftSmall fBold fMedium'>
                Zimný štadión
            </Div>
            { AppConst.RinkFilterGroups
                .map(
                    rinkGroup => renderCheckbox(
                        rinkGroup.key,
                        rinkGroup.label,
                        state.rinkGroup.indexOf(rinkGroup.key) !== -1,
                        () => updateState(createState(state, rinkGroup.key, null))
                    )
                )
            }
        </Div>
        <Div fx='150' setup='c start start' style={{background: 'white'}}>
            <Div fx='35' setup='r start center' class='padLeftSmall fBold fMedium'>
                Úroveň&nbsp;<Icon css='IconSmall' image='infoDark' onClick={showSkillInfo} />
            </Div>
            { Object.keys(AppConst.SkillLevelByKey)
                    .map(
                        lvl => renderCheckbox(
                            lvl,
                            AppConst.SkillLevelByKey[ lvl ].label,
                            state.lvl.indexOf(lvl) !== -1,
                            () => updateState(createState(state, null, lvl))
                        )
                    )
            }
        </Div>
    </Div>

function createState(state, rinkGroupKey, lvl) {
    const result = clone(state);
    if(rinkGroupKey) {
        updateArray(result.rinkGroup, rinkGroupKey);
    }
    if(lvl) {
        updateArray(result.lvl, lvl);
    }

    const rinkGrArr = AppConst.RinkFilterGroups;
    const rinkGrDef = AppConst.RinkFilterGroupsByKey;
    result.rinkGroup.sort((a, b) => rinkGrArr.indexOf(rinkGrDef[a]) - rinkGrArr.indexOf(rinkGrDef[b]));

    const levelDef = AppConst.SkillLevelByKey;
    result.lvl.sort((a, b) => levelDef[a].value - levelDef[b].value);

    return result;
}

function updateArray(arr, item) {
    const index = arr.indexOf(item);
    if(index !== -1) {
        arr.splice(index, 1);
    } else {
        arr.push(item);
    }
} 

function renderCheckbox(key, label, checked, onChange) {
    return <Div key={key} fx='40' setup='r start center'>
        <label>
            <input type='checkbox' checked={checked} value='dummy' onChange={() => onChange(!checked)} />
            { label }
        </label>
    </Div>
}
