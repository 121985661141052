import { put, select } from 'redux-saga/effects';

import * as Actions from '../../actions';
import { DataEvent } from '../../../const';

export function matchGamesDiff(action) {
    return  action.type === Actions.TYPE.Received
            && action.data.type === DataEvent.Game.Game
            && action.data.game.type === DataEvent.Game.GamesDiff;
}

export function* handleGamesDiff(action) {
    const user = yield select(state => state.user);
    const data = yield select(state => state.data);
    const playerId = user && user.player ? user.player.id : null;
    
    if(playerId) {
        const stateGames = data.games;
        const actionGames = action.data.game.gamesDiff;

        for(let sGameKey in stateGames) {
            const sGame = stateGames[ sGameKey ];

            if(sGame.gameFlags.isLocked) {
                const sFlags = sGame.playerFlags[ playerId ];

                if(sFlags && sFlags.players) {
                    const aGame = actionGames[ sGameKey ];

                    if(aGame) {
                        const aFlags = aGame.playerFlags[ playerId ];
                        
                        if(aFlags && aFlags.cancelled && !aFlags.warning) {
                            yield put(
                                // successfuly cancelled with backup existing
                                Actions.pushNotify({
                                    text: [
                                        'Je po termíne, kedy sa dá voľne odhlasovať, ale v poradí je náhradník, ktorý pôjde za teba. Odhlásenie prebehlo úspešne.',
                                        'PS: Dávaj si pozor, aby si sa odhlasoval do 13:00 2 dni pred ľadom a vyhol sa zbytočnému plateniu vstupu.'
                                    ],
                                    textOk: 'Rozumiem',
                                    h: 300
                                })
                            );
                        }
                    }
                }
            }
        }
    }
}
