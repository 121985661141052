import { AppConst } from '../const';
import padStart from './padStart';

const millisPerDay = 24 * 3600 * 1000;

export function isPast(now, date, dontConsiderTime = false) {
    if(dontConsiderTime) {
        return  now.getFullYear() > date.getFullYear()
                || (
                    now.getFullYear() === date.getFullYear()
                    && now.getMonth() > date.getMonth()
                )
                || (
                    now.getFullYear() === date.getFullYear()
                    && now.getMonth() === date.getMonth()
                    && now.getDate() > date.getDate()
                );
    } else {
        return now > date;
    }
}

export function isHistory(now, date, historyDays) {
    const nowDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const dateDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const diffDays = Math.floor((nowDate - dateDate) / millisPerDay);
    return now > date && diffDays <= historyDays;
}

export function isSoon(now, date, definition) {
    const nowDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const dateDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const diffDays = Math.floor((dateDate - nowDate) / millisPerDay);

    if(diffDays > definition.days) {
        return false;
    } else {
        if(diffDays === definition.days && now.getHours() < definition.hours) {
            return false;
        } else {
            return true;
        }
    }
}

export function isLocked(now, date) {
    return isSoon(now, date, AppConst.GameLocked);
}

export function isToday(now, date) {
    return isSoon(now, date, AppConst.GameToday);
}

export function changeDayOfWeek(date, now, day) {
    const current = (date.getDay() + 6) % 7;
    const diff = ((day + 6) % 7) - current;
    
    const result = new Date(date.getTime());
    result.setDate(result.getDate() + diff);

    if(result < now) {
        result.setDate(result.getDate() + 7);
    } else {
        while(result - now >= (7*millisPerDay)) {
            result.setDate(result.getDate() - 7);
        }
    }

    return result;
}

export function titleDayOfWeek(day, full = true, single = false) {
    switch(day) {
        case 0: return full ? (single ? 'Nedeľa'    : 'Nedele')     : 'Ne';
        case 1: return full ? (single ? 'Pondelok'  : 'Pondelky')   : 'Po';
        case 2: return full ? (single ? 'Utorok'    : 'Utorky')     : 'Ut';
        case 3: return full ? (single ? 'Streda'    : 'Stredy')     : 'Str';
        case 4: return full ? (single ? 'Štvrtok'   : 'Štvrtky')    : 'Št';
        case 5: return full ? (single ? 'Piatok'    : 'Piatky')     : 'Pi';
        case 6: return full ? (single ? 'Sobota'    : 'Soboty')     : 'So';
        default: throw new Error('Bad day of week');
    }
}

export function titleMonth(month) {
    switch(month) {
        case 0: return 'Jan';
        case 1: return 'Feb';
        case 2: return 'Mar';
        case 3: return 'Apr';
        case 4: return 'Máj';
        case 5: return 'Jún';
        case 6: return 'Júl';
        case 7: return 'Aug';
        case 8: return 'Sep';
        case 9: return 'Okt';
        case 10: return 'Nov';
        case 11: return 'Dec';
        default: throw new Error('Bad month number');
    }
}

export function titleDate(date) {
    return `${date.getDate().toString()}.${(date.getMonth()+1).toString()}`;
}

export function titleDateSlash(date) {
    return `${date.getFullYear()}/${padStart((date.getMonth()+1).toString(), 2, '0')}/${padStart(date.getDate().toString(), 2, '0')}`;
}

export function titleTime(date) {
    return `${padStart(date.getHours().toString(), 2, '0')}:${padStart(date.getMinutes().toString(), 2, '0')}`;
}

export function isoWeekNum(date) {
    var dt = new Date(date.getTime());
    dt.setHours(0, 0, 0, 0);
    
    // Thursday in current week decides the year.
    dt.setDate(dt.getDate() + 3 - (dt.getDay() + 6) % 7);
    // January 4 is always in week 1.
    var week1 = new Date(dt.getFullYear(), 0, 4);

    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((dt.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
}

export function isoYear(date) {
    const week = isoWeekNum(date);
    // days in iso week 1 that are still in december, are already considered next iso year
    if(week === 1 && date.getMonth() === 11) {
        return date.getFullYear() + 1;
    }
    return date.getFullYear();
}

export function makeDates(startDate, count) {
    const result = [ ];
    for(let i = 0; i < count; i++) {
        const nextDate = new Date(startDate.getTime());
        nextDate.setDate(nextDate.getDate() + i * 7);
        result.push(nextDate);
    }
    return result;
}

export function isDate(value) {
    if (Object.prototype.toString.call(value) !== "[object Date]") {
        // not a date
        return false;
    } else {
        // it is a date
        if (isNaN(value.getTime())) {  // d.valueOf() could also work
            return false;
        } else {
            // date is valid
            return true;
        }
    }
}

export function dateKey(date) {
    return date.getFullYear()*10000 + date.getMonth()*100 + date.getDate();
}
