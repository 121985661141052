import React from 'react';
import { connect } from 'react-redux';

import Div from '../div';
import Button from '../button';
import TelNumber from '../telNumber';
import * as Actions from '../../actions';
import { AppConst } from '../../../const';

const Charvat = AppConst.ContactCharvat;

const Contact = ({ onClose }) =>
    <Div setup='c center center' css='View' hView='100' style={{ userSelect: 'all' }} class='' id='cypress.charvatContact'>
        <address style={{ fontStyle: 'normal' }}>
            {Charvat.Name}<br/>
            <br/>
            píš: <a href={'mailto:' + Charvat.Email}>{Charvat.Email}</a>.<br/> 
            <br/>
            volaj: <TelNumber tel={Charvat.Tel}/><br/>
            <br/>
            <a target='_blank' rel="noopener noreferrer" href={Charvat.FB.value}>{Charvat.FB.info}</a>
        </address>

        <Div css='ButtonSectionPadding' />
        <Div css='ButtonSection' setup='c center center' h='80'>
            <Button text='Zavri' w={100} onClick={onClose} />
        </Div>
    </Div>

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => ({
    onClose: () => dispatch(Actions.popView())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Contact)
