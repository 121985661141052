import React from 'react';
import { connect } from 'react-redux';

import Name from './name';
import Div from '../../div';
import Icon from '../../icon';
import ProfileImage from '../../profileImage';
import SkillLevel from '../../skillLevel';
import * as Css from '../../css';
import { OpenMenuEvent } from '../../../events';

import * as Actions from '../../../actions';

function isLongName(info) {
    return (info.firstName || '').length + (info.lastName || '').length >= 25;
}

const User = ({ player, openProfile }) => {
    const longName = isLongName(player.info);

    return <Div setup='r center center' wRel='100' class='userBackground'>
            <Div setup='r between center' class={Css.User}>
                <Div setup='r start center'>
                    <ProfileImage info={player.info} css='ImgProfileBig' onClickAction={openProfile} />
                    <Div setup='c start start' class='padLeft' onClick={openProfile}>
                        <Name info={player.info} split={longName} />
                        <SkillLevel level={player.skillLevel} />
                    </Div>
                </Div>
                <Icon css='IconBig' image='menu' id='cypress.menuButton' onClick={OpenMenuEvent.emit} />
            </Div>
        </Div>
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => ({
    openProfile: () => dispatch(Actions.pushView(Actions.VIEW.UserProfile)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(User)
