import * as DateTime from '../../utils/dateTime';

export const Form = 'padSmall';
export const FormInputText= 'fMedium';

export const ImgProfileBig = 'imgProfileBig';
export const ImgProfileMedium = 'imgProfileMedium';

export const IconBig = 'icon iconBig';
export const IconNormal = 'icon iconNormal';
export const IconMedium = 'icon iconMedium';
export const IconSmall = 'icon iconSmall';
export const IconMini = 'icon iconMini';

export const Menu = 'mainMenu';
export const MenuOverlay = 'mainMenuOverlay';

export const User =         'user padSmall bgWhite fgDark container hBig';
export const UserDetail =   'fSmall';

export const ButtonSection = 'bgWhite hBig';
export const ButtonSectionPadding = 'hBigger';

export const View = '';
export const ViewHeader = 'bgMedium padSmall fgWhite fBig hBig';
export const AdminView = 'padSmall fMedium';

export const Header = 'hBig';

export const AdminItemListItem = 'fgDark fBig hBigger bordBottom padSmall';
export const AdminItemListItemSmall = 'fgDark fBig hBig bordBottom padSmall';
export const AdminItemHeader = 'bgOn fgBlack fBig hBig padSmall';
export const AdminPlayerLetter = 'bgOn fgWhite fBig';
export const AdminPlayerLetterNotNormal = 'bgMaybe fgDark fBig';
export const AdminPlayerLetterBad = 'bgWarn fgWhite fBig';
export const AdminPlayerLetterEmpty = 'bgWhite fgDark fBig';

export const ListItem = 'bgWhite fgDark fBig hBiggest bordBottom padTopSmall padLeftSmall padRightSmall';
export const ListItemNonPlayer = 'bgWhite fgDark fBig hBigger bordBottom padTopSmall padLeftSmall padRightSmall';
export const ListItemDetail = 'fSmall';

export const Bold = 'fBold';

export function button(enabled, on = true) {
    return enabled
        ? (
            'clickable ' + (
                on ? 'bgOn fgWhite fMedium' : 'bgOff fgBlack fMedium'
            )
        )
        : 'bgWarn fgWhite fMedium';
}

export function weekTitle(now, year, week) {
    const nowYear = now.getFullYear();
    const nowWeek = DateTime.isoWeekNum(now);

    const follows = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7);
    const followsYear = follows.getFullYear();
    const followsWeek = DateTime.isoWeekNum(follows);

    const yearStr = year !== nowYear && week === 1 ? '('+year.toString()+')' : '';
    const weekStr = week.toString() + '.';

    if(week === nowWeek) {
        return `Tento týždeň (${weekStr + yearStr})`;
    }

    if(year === followsYear && week === followsWeek) {
        return `Nasledujúci týždeň (${weekStr + yearStr})`;
    }

    return `${weekStr} týždeň ${yearStr}`;
}
