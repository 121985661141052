import React from 'react';

import Div from './div';
import { FormContext } from '../context';

export default class Input extends React.Component {
    getValue(object) {
        const name = this.props.name;
        const prefix = this.props.prefix;

        if(object) {
            if(prefix !== undefined && prefix !== null) {
                if(object[ prefix ]) {
                    return object[ prefix ][ name ];
                }
            } else {
                if(object) {
                    return object[ name ];
                }
            }
        }

        return null;
    }

    handleEmptyValue(value) {
        const props = this.props;

        if(value === null || value === undefined) {
            if(props.type === 'text' || props.type === 'textArea') {
                return '';
            } else {
                return null;
            }
        }

        return value;
    }

    validateValue(context, value) {
        const validation = this.getValue(context.validation);
        const required = validation ? validation.required : false;
        const validate = validation ? validation.validate : null;

        let isValid = validate ? validate(value) : true;        
        if(isValid && required && isEmpty(value)) {
            isValid = false;
        }

        return isValid;
    }

    render() {
        const props = this.props;

        return <FormContext.Consumer>
            {
                context => {
                    const value = this.handleEmptyValue(this.getValue(context.data));
                    const isValid = this.validateValue(context, value);

                    return  <InputInner context={context}
                                        value={value}
                                        isValid={isValid}
                                        name={props.name}
                                        prefix={props.prefix}
                                        type={props.type}
                                        options={props.options}
                                        maxLength={props.maxLength}
                                        label={props.label}
                                        placeholder={props.placeholder}
                                        readonly={props.readonly}
                                        confirm={props.confirm} />
                }
            }
        </FormContext.Consumer>
    }
}

class InputInner extends React.Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.isValid = this.isValid.bind(this);
        this.focus = this.focus.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.onValueSubmit = this.onValueSubmit.bind(this);
    }

    componentDidMount() {
        const events = this.props.context.events;
        events.isValid.register(this.isValid);
        events.focus.register(this.focus);
    }

    componentWillUnmount() {
        const events = this.props.context.events;
        events.isValid.unregister(this.isValid);
        events.focus.unregister(this.focus);
    }

    onChange(event) {
        const props = this.props;
        const events = props.context.events;
        
        let value = null;
        if(props.type === 'check' || props.type === 'radio') {
            value = event;
        } else {
            value = event.target.value;
        }

        if(props.prefix) {
            events.onChange([ props.prefix, props.name ], value);
        } else {
            events.onChange(props.name, value);
        }
    }

    onKeyPress(event) {
        if(event.key === 'Enter') {
            this.onValueSubmit();
        }
    }

    onValueSubmit() {
        this.props.context.events.focus.callNext(this.focus);
    }

    focus() {
        const props = this.props;
        const elem = global.document.getElementById(props.prefix + props.name);
        if(elem) {
            elem.focus();
        }
    }

    isValid() {
        return this.props.isValid;
    }

    render() {
        const props = this.props;
        
        const isValid = props.isValid;
        const style = {
            border: isValid ? undefined : 'solid 2px red',
            padding: isValid ? '3px' : '1px'
        }

        switch(props.type) {
            case 'text':
                return <Div setup='r between center' wRel='100' fx='35' style={style}>
                    <Div fx='100'>{ props.label }</Div>
                    <Div wRel='100'>
                        <input type='text' value={props.value} id={(props.prefix || '') + props.name}
                               onChange={this.onChange} onKeyPress={this.onKeyPress} 
                               style={{ width: '97%', height: '25px', fontSize: '17px' }}
                               maxLength={props.maxLength} placeholder={props.placeholder}
                               readOnly={props.readonly ? 'readonly' : undefined} />
                    </Div>
                </Div>

            case 'textArea':
                return <Div setup='c between start' wRel='100' style={style}>
                    <Div fx='20'>{ props.label }</Div>
                    <Div wRel='100'>
                        <textarea rows='5' cols='40' value={props.value} 
                                  onChange={this.onChange} style={{ width: '100%', fontFamily: 'arial', fontSize: '18px' }}
                                  maxLength={props.maxLength}
                                  readOnly={props.readonly ? 'readonly' : undefined} />
                    </Div>
                </Div>

            case 'radio':
                return <Div setup='c between center' wRel='100' style={style}>
                    <u><Div>{ props.label }</Div></u>
                    <br/>
                    <Div setup='r between center' wRel='100'>
                        { props.options.map(
                            (item, n) => this.renderRadioButton(
                                n, item.value, item.label,
                                props.value === item.value
                            )
                        )}
                    </Div>
                </Div>

            case 'check':
                return <Div style={style}>
                    { this.renderCheckbox(props.label, props.value === true, props.readonly, props.confirm) }
                </Div>

            default:
                throw new Error('bad type');
        }
    }

    renderRadioButton(index, value, label, checked) {
        const props = this.props;
        const name = radioGroupName(props.name, props.prefix);
        
        return <label key={index}>
            <Div setup='c around center'>                                
                {label.map(
                    (labelPart, index2) => <Div key={index+'_'+index2}>{labelPart}</Div>
                )}
                <Div>
                    { checked
                        ? <input type='radio' name={name} onChange={() => this.onChange(value)} checked={true} value='dummy' />
                        : <input type='radio' name={name} onChange={() => this.onChange(value)} checked={false} value='dummy' />
                    }
                </Div>
            </Div>
        </label>
    }

    renderCheckbox(label, checked, readonly, confirm) {
        return <label>
            <input type='checkbox' checked={checked} value='dummy'
                   onChange={
                        () => {
                            if(!confirm || confirm()) {
                                this.onChange(!checked);
                            }
                        }
                   }
                   disabled={readonly ? 'disabled' : undefined} />
            { label }
        </label>
    }
}

function isEmpty(value) {
    if(value === null || value === undefined) {
        return true;
    } else {
        if(typeof value === 'string') {
            return value.trim().length === 0;
        } else {
            return false;
        }
    }
}

function radioGroupName(name, prefix) {
    return (prefix + '.' || '') + name;
}
