import { TYPE } from '../actions';

const STATIC_OFFSET = 1000;

export default function(state, action) {
    const now = new Date();

    switch(action.type) {
        case TYPE.Cypress:
            if(action.setDate) {
                cypressOffset = now.getTime() - action.date.getTime();
            }
            
            return {
                now: getDate(now)
            }

        default:    
            return {
                now: getDate(now)
            }
    }
}

let cypressOffset = null;

function getDate(now) {
    return cypressOffset === null ? now : new Date(now.getTime() - cypressOffset + STATIC_OFFSET);
}
