export function Event() {
    const handlers = [ ];

    this.clear = function() {
        handlers.length = 0;
    }

    this.register = function(handler) {
        handlers.push(handler);
    }

    this.unregister = function(handler) {
        const index = handlers.indexOf(handler);
        handlers.splice(index, 1);
    }

    this.emit = function(...args) {
        const results = [ ];

        handlers.forEach(
            handler => {
                const handlerResult = handler(...args);
                if(handlerResult !== undefined) {
                    results.push(handlerResult);
                }
            }
        );

        return results;
    }

    // used in forms to focus next input on keyPress===enter
    this.callNext = function(currentHandler) {
        const index = handlers.indexOf(currentHandler);
        if(index !== -1 && index < handlers.length - 1) {
            handlers[ index + 1 ]();
        }
    }
}

export const OpenMenuEvent = new Event();

export const SwipeRightEvent = new Event();

export const PageReloadEvent = new Event();
