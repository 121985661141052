import React from 'react';

import { AppConst } from '../../../../const';

export default ({ name, value, onChange, isNew }) =>
    isNew
        ? <select style={{width: '100%', height: '30px'}} className='fMedium'
                  onChange={event => onChange(name, event.target.value)}>
            { AppConst.Rinks.map(
                rink => <option key={rink.key} value={rink.key}>{rink.label}</option>
            )}
        </select>
        : <span style={{ backgroundColor: AppConst.RinksByKey[ value ].bg }}>{ AppConst.RinksByKey[ value ].label }</span>

