import React from 'react';

import * as Css from './css';

const ProfileImage = ({ info, css, onClickAction }) => (
    <img src={(info && info.imageUrl) || ''}
         className={Css[ css ]}
         alt='?' onClick={event => onClick(event, info, onClickAction)}
    />
)

function onClick(event, info, onClickAction) {
	event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    
    if(onClickAction) {
    	onClickAction();
    } else {
		window.open(info.imageUrlBig || info.imageUrl);
	}
}

export default ProfileImage;
