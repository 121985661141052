export const TYPE = {
    Send: 'Send',
    Received: 'Received',
    Connection: 'Connection',

    Init: 'Init',
    Token: 'Token',
    Session: 'Session',
    Logout: 'Logout',
    
    LoginView: 'LoginView',
    RegistrationView: 'RegistrationView',
    ContentView: 'ContentView',
    ErrorView: 'ErrorView',

    EmptyView: 'EmptyView',
    PushView: 'PushView',
    PopView: 'PopView',
    BaseView: 'BaseView',

    Notify: 'Notify',
    SetGlobalParam: 'SetGlobalParam',
    Cypress: 'Cypress'
}

export const CONNECTION = {
    Sending: 'sending',
    Reconnect: 'reconnect',
    Connected: 'connected'
}

export const VIEW = {
    /* Base */
    Reconnect: 'reconnect',
    Sending: 'sending',
    Error: 'error',
    Login: 'login',
    Registration: 'registration',
    Content: 'content',
    NonPlayerContent: 'nonPlayerContent',
    
    /* Menu */
    UserProfile: 'userProfile',
    CharvatContact: 'charvatContact',

    /* Content - admin */
    IceList: 'iceList',
    IceDetail: 'iceDetail',
    IceWeeklyPlayers: 'iceWeeklyPlayers',
    Players: 'players',
    PlayerList: 'playerList',
    PlayerDetail: 'playerDetail',
    PlayerDetailEdit: 'playerDetailEdit',
    PlayerDetailGames: 'playerDetailGames',
    PlayerEmails: 'playerEmails',
    SkillLevelInfo: 'skillLevelInfo',

    /* Content */
    GameDetail: 'gameDetail',
    GameDetailAdmin: 'gameDetailAdmin',
    PlayerContacts: 'playerContacts',

    /* Extra */
    ModalUkraine: 'modalUkraine'
}

export const GLOBAL_PARAM ={
    AdminPlayersMode: 'adminPlayersMode',
    AdminGameMode: 'adminGameMode',
    UnloadEventEnabled: 'unloadEventEnabled',
    GameFilterExpanded: 'gameFilterExpanded'
}

export function init() {
    return {
        type: TYPE.Init
    };
}

export function token(loginResult) {
    return {
        type: TYPE.Token,
        token: loginResult
    };
}

export function session(sessionData) {
    return {
        type: TYPE.Session,
        session: sessionData
    };
}

export function logout() {
    return {
        type: TYPE.Logout
    };
}

export function errorView(message) {
    return {
        type: TYPE.ErrorView,
        params: { message }
    };
}

export function emptyView() {
    return {
        type: TYPE.EmptyView
    }
}

export function loginView() {
    return {
        type: TYPE.LoginView
    };
}

export function registrationView() {
    return {
        type: TYPE.RegistrationView
    }
}

export function contentView() {
    return {
        type: TYPE.ContentView
    }
}

export function pushView(view, params, isRootMenuItem = false) {
    return {
        type: TYPE.PushView,
        view: {
            type: view,
            params,
            isRootMenuItem
        },
        scrollPos: global.window.scrollY
    }
}

export function pushViewKeepContext(view, params, context) {
    return {
        type: TYPE.PushView,
        view: {
            type: view,
            params,
        },
        scrollPos: global.window.scrollY,
        context
    }
}

export function popView(count = 1, until = null) {
    return {
        type: TYPE.PopView,
        count,
        until
    }
}

export function baseView() {
    return {
        type: TYPE.BaseView
    }
}

export function pushNotify(msg) {
    return {
        type: TYPE.Notify,
        push: msg
    }
}

export function popNotify() {
    return {
        type: TYPE.Notify,
        pop: true
    }
}

export function connection(state, clearSendCount = false) {
    return {
        type: TYPE.Connection,
        state,
        scrollPos: global.window.scrollY,
        clearSendCount
    }
}

export function setGlobalParam(name, value) {
    return {
        type: TYPE.SetGlobalParam,
        name,
        value
    }
}

export function cypressSetAppDate(date) {
    return {
        type: TYPE.Cypress,
        setDate: true,
        date
    }
}
