import React from 'react';
import { connect } from 'react-redux';

import PlayerContextMenu from '../../playerContextMenu';
import Div from '../../../div';
import ProfileImage from '../../../profileImage';
import * as Actions from '../../../../actions';

class PlayerItem extends React.Component {
    constructor(props) {
        super(props);
        this.contextMenuRef = React.createRef();
        this.onPlayerClick = this.onPlayerClick.bind(this);
    }

    onPlayerClick() {
        if(this.props.isAdmin) {
            this.refs.contextMenu.onOpen();
        }
    }

    render() {
        const props = this.props;
        const game = props.game;
        const player = props.player;
        const flags = game.playerFlags[ player.id ];
        
        const warn = [];
        if(flags.warning) {
            warn.push('nemá náhradníka');
        }
        
        const isHelper = game.helpers[ player.id ];
        if(isHelper) {
            const ice = props.getIce(game);
            if(ice.helpers[ player.id ]) {
                warn.push('pravidelný šatniar');
            } else {
                warn.push('šatniar');
            }
        }
        if(player.isAdmin) {
            warn.push('admin');
        }
        const style = (isHelper || player.isAdmin) ? { color: 'blue' } : null;

        return <Div setup='r start center' wRel='100'>
            { props.pos !== null
                ? <Div fx='42' class='fMega' style={{ textAlign: 'center' }}>{props.pos}.</Div>
                : <Div fx='42' />
            }

            <Div setup='c start start' wRel='100' onClick={this.onPlayerClick} class={props.isAdmin ? 'clickable' : ''} style={style}>
                <Div wRel='100' style={{ textAlign: 'center' }}>{flags.name}</Div>
                { warn.length > 0
                    ? <Div class='fSmall' wRel='100' style={{ textAlign: 'center' }}>{warn.join(', ')}</Div>
                    : null
                }
                { props.isAdmin
                    ? <PlayerContextMenu ref='contextMenu' player={player} showPlayer={() => props.showPlayer(player)}
                                         enableUnloadEvent={props.enableUnloadEvent}
                                         gameKey={{ iceDate: game.ice.date, iceRink: game.ice.rink, gameDate: game.gameDate }} />
                    : null
                }
            </Div>

            <Div>
                <ProfileImage info={flags} css='ImgProfileMedium' />
            </Div>
        </Div>
    }
}

const mapStateToProps = state => ({
    getIce: game => state.get.iceByKey(game.ice.date, game.ice.rink)
});

const mapDispatchToProps = dispatch => ({
    enableUnloadEvent: enabled => dispatch(
        Actions.setGlobalParam(
            Actions.GLOBAL_PARAM.UnloadEventEnabled,
            enabled
        )
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerItem)
