import { Getters } from '../selectors';

export default (state, action, args) => {
    if(!state) {
        return new Getters(args);
    }
    
    state.setState(args);
    return state;
}
