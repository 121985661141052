import React from 'react';

import Div from '../../../div';

export default ({ isCancelled, onChange }) =>
    <label style={{width:'100%'}}>
        <Div setup='r center center' h='40' wRel='100'>
                <Div>{ isCancelled ? 'Zrušený' : 'Zrušiť termín?' }</Div>
                <Div>
                    <input type='checkbox' checked={isCancelled} onChange={e => preventDefault(e, onChange)} value='dummy' readOnly={true}
                           style={{ width: '30px', height: '30px' }} />
                </Div>
        </Div>
    </label>

function preventDefault(e, onChange) {
    e.preventDefault();
    onChange();
}
