import React from 'react';

import Div from '../../../div';

export default ({ game, playerId, actions, pos }) => {
    const flags = game.playerFlags[ playerId ];
    const name = playerId.slice(playerId.indexOf(':') + 1);

    return <Div setup='r start center' wRel='100' h='45'>
        { pos !== null
            ? <Div fx='42' class='fMega' style={{ textAlign: 'center' }}>{pos}.</Div>
            : <Div fx='42' />
        }

        <Div setup='c start start' wRel='100'>
            <Div wRel='100' style={{ textAlign: 'center' }}>{name}</Div>
            <Div wRel='100' class='fSmall' style={{ textAlign: 'center', fontStyle: 'italic' }}>
                (nereg.) { flags.warning ? ' nemá náhradníka' : '' }
            </Div>
        </Div>

        <Div fx='50' />
    </Div>
}
