import React from 'react';

export default class LastAction extends React.Component {
    render() {
        const action = this.props.action;
        return <div id='cypress.lastAction'
                    action={action ? action.type : null}
                    actiondata={action && action.data ? action.data.type : null} />
    }
}
