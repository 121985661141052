import React from 'react';

import * as Css from './css.js';
import clone from '../../utils/clone';

const flexDir = {
    r: 'row',
    rr: 'row-reverse',
    c: 'column',
    cr: 'column-reverse',
    init: 'initial',
    inh: 'inherit'
}

const flexAttr = {
    start: 'flex-start',
    end: 'flex-end',
    center: 'center',
    around: 'space-around',
    between: 'space-between',
    stretch: 'stretch',
    base: 'baseline'
}

function isDef(val) {
    return val !== undefined && val !== null;
}

function getStyle(props) {
    const style = Object.assign({ }, props.style);

    if(isDef(props.w)) {
        style.width = props.w + 'px';
    }
    if(isDef(props.wRel)) {
        style.width = props.wRel + '%';
    }
    if(isDef(props.wView)) {
        style.width = props.wView + 'vw';
    }
    if(isDef(props.wMax)) {
        style.maxWidth = props.wMax + 'px';
    }

    if(isDef(props.h)) {
        style.height = props.h + 'px';
    }
    if(isDef(props.hRel)) {
        style.height = props.hRel + '%';
    }
    if(isDef(props.hView)) {
        style.height = props.hView + 'vh';
    }

    if(isDef(props.fx)) {
        const flex = props.fx.toString();
        const simple = flex.length < 3 || flex.charAt(1) !== ' ';

        if(simple) {
            style.flex = '0 0 ' + flex + 'px';
        } else {
            style.flex = flex + 'px';
        }
    }

    if(isDef(props.setup)) {
        style.display = 'flex';
        
        const setup = props.setup.split(' ');
        style.flexDirection = flexDir[ setup[0] ];

        if(setup.length > 1) {
            style.justifyContent = flexAttr[ setup[1] ];

            if(setup.length > 2) {
                style.alignItems = flexAttr[ setup[2] ];

                if(setup.length > 3) {
                    style.alignContent = flexAttr[ setup[3] ];
                }
            }
        }
    }

    return style;
}

export default props => {
    let className = null;
    if(props.class || props.css) {
        className = (props.class ? props.class+' ' : '') + (props.css ? Css[ props.css ] : '');
    }
    
    const style = Object.assign(clone(props.style || { }), getStyle(props));
    return <div id={props.id} style={style} className={className}
                onClick={props.onClick ? event => props.onClick(event) : undefined}>
        {props.children}
    </div>
}
