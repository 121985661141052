import React from 'react';
import { connect } from 'react-redux';

import Div from '../../div';
import Button from '../../button';
import * as Actions from '../../../actions';
import clone from '../../../../utils/clone';
import {  } from '../../../../const';

const EMAILS = 'playerEmails';

class PlayerEmailsView extends React.Component {
    static createState(props) {
        const mailingList = props.mailingList || false;
        return {
            mode: null,
            params: {
                nonPlayers: false,
                mailingList,
                allSkillLevels: true,
                skillLevel: {
                    none: true,
                    l: true,
                    ls: true,
                    s: true
                },
            },
            props: {
                mailingList
            }
        }
    }

    static getDerivedStateFromProps(props, state) {
        if(state.props.mailingList !== props.mailingList) {
            return PlayerEmailsView.createState(props);
        }
        return null;
    }

    constructor(props) {
        super(props);
        this.clickMode = this.clickMode.bind(this);
        this.state = PlayerEmailsView.createState(props);
    }

    clickMode(newMode) {
        this.setState(
            {
                mode: newMode,
                params: clone(this.state.params),
                props: clone(this.state.props),
            },
            () => copyToClipboard(EMAILS)
        );
    }

    updateParamNonPlayers(newVal) {
        const state = clone(this.state);
        state.params.nonPlayers = newVal;
        this.setState(state);
    }

    updateParamMailingList(newVal) {
        const state = clone(this.state);
        state.params.mailingList = newVal;
        this.setState(state);
    }

    updateParamAllSkills(newVal) {
        const state = clone(this.state);
        state.params.allSkillLevels = newVal;
        this.setState(state);
    }

    updateParamSkillLevel(name, newVal) {
        const state = clone(this.state);
        state.params.skillLevel[ name ] = newVal;
        this.setState(state);
    }

    render() {
        const props = this.props;
        const state = this.state;
        const modeChar = state.mode === null ? '' : state.mode;
        const levelsEnabled = !state.params.allSkillLevels;
        const emails = props.getEmails(state.params);

        return <Div setup='c start start' wRel='100' css='View'>
            <Div wRel='100' hRel='100'>
                <textarea id={EMAILS} type='textarea'
                          value={emails.join(modeChar + '\n')}
                          style={{ width: '96%', height: '240px' }} 
                          rows={emails.length} onChange={() => {}} />
            </Div>

            <Div setup='c start start'>
                <Div fx='25' setup='r start center'>
                    <label>
                        <input type="checkbox" checked={state.params.nonPlayers ? 'checked' : ''}
                               onChange={() => this.updateParamNonPlayers(!state.params.nonPlayers)} />
                        zahrnúť aj N (nehrajúci)
                    </label>
                </Div>

                <Div fx='25' setup='r start center'>
                    <label>
                        <input type='radio' checked={!state.params.mailingList ? 'checked' : ''} 
                               onChange={() => this.updateParamMailingList(false)} />
                        všetci
                    </label>
                    <label>
                        <input type='radio' checked={state.params.mailingList ? 'checked' : ''} 
                               onChange={() => this.updateParamMailingList(true)} />
                        iba čo súhlasili s novinkami
                    </label>
                </Div>

                <Div fx='25' setup='r start center'>
                    <label>
                        <input type="checkbox" checked={state.params.allSkillLevels ? 'checked' : ''}
                               onChange={() => this.updateParamAllSkills(!state.params.allSkillLevels)} />
                        všetky úrovne
                    </label>
                </Div>

                <Div fx='25' setup='r start center' style={{color: levelsEnabled ? 'black' : 'silver'}}>
                    <label>
                        <input type="checkbox" checked={state.params.skillLevel.l ? 'checked' : ''}
                               onChange={() => this.updateParamSkillLevel('l', !state.params.skillLevel.l)}
                               disabled={!levelsEnabled} />
                        ľahká
                    </label>
                    <label>
                        <input type="checkbox" checked={state.params.skillLevel.ls ? 'checked' : ''}
                               onChange={() => this.updateParamSkillLevel('ls', !state.params.skillLevel.ls)}
                               disabled={!levelsEnabled} />
                        ľ. stred
                    </label>
                    <label>
                        <input type="checkbox" checked={state.params.skillLevel.s ? 'checked' : ''}
                               onChange={() => this.updateParamSkillLevel('s', !state.params.skillLevel.s)}
                               disabled={!levelsEnabled} />
                        stredná
                    </label>
                    <label>
                        <input type="checkbox" checked={state.params.skillLevel.none ? 'checked' : ''}
                               onChange={() => this.updateParamSkillLevel('none', !state.params.skillLevel.none)}
                               disabled={!levelsEnabled} />
                        bez úrovne
                    </label>
                </Div>
            </Div>

            <Div setup='r around center' wRel='100' h='60'>
                <Button text='Kopíruj' onClick={() => this.clickMode('')}
                        controlled={true} on={state.mode===''} />
                <Button text='Kopíruj s ,' onClick={() => this.clickMode(',')}
                        controlled={true} on={state.mode===','} />
                <Button text='Kopíruj s ;' onClick={() => this.clickMode(';')}
                        controlled={true} on={state.mode===';'} />
            </Div>

            <Div css='ButtonSectionPadding' />
            <Div setup='r center center' class='fixedDown' wRel='100'>
                <Div css='ButtonSection' setup='r around center' class='container'>
                    <Button text='Zavri' onClick={props.closeView} />
                </Div>
            </Div>
        </Div>
    }
}

function copyToClipboard(elementId) {
    const elem = global.document.getElementById(elementId);
    if(elem) {
        if(elem.focus) {
            elem.focus();
        }
        if(elem.select) {
            elem.select();
        }

        global.document.execCommand('copy');
    }
}

const mapStateToProps = state => ({
    getEmails: params => state.get.playerEmails(params)
});

const mapDispatchToProps = dispatch => ({
    closeView: () => dispatch(Actions.popView())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerEmailsView)
