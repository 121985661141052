import padStart from './padStart';

export function parse(str) {
	return JSON.parse(str, parseReplacer);
}

export function stringify(obj, padding) {
	return JSON.stringify(obj, stringifyReplacer, padding);
}

function s2i(str, start, length) {
	return parseInt(str.substr(start, length), 10);
}

function i2s(i, padLength) {
	return padStart(i.toString(), padLength, '0');
}

function isDateKey(key) {
    if(key === 'ts') {
        return true;
    }
    if(key === 'gameDateOverride') {
        return true;
    }
    
    const end = key ? (key.length >= 4 ? key.substr(-4, 4).toLowerCase() : key.toLowerCase()) : null;
    return end === 'date';
}

function isDtKey(key) {
    const end = key ? (key.length >= 2 ? key.substr(-2, 2).toLowerCase() : key.toLowerCase()) : null;
    return end === 'dt';
}

function parseReplacer(key, value) {
    if(value !== null && value !== undefined) {
        if(isDateKey(key)) {
            const len = value.length;
            return new Date(
                s2i(value, 0, 4),
                s2i(value, 4, 2) - 1,
                s2i(value, 6, 2),
                s2i(value, 9, 2),
                s2i(value, 11, 2),
                len > 13 ? s2i(value, 13, 2) : 0,
                len > 15 ? s2i(value, 16, 3) : 0
            );
        } else {
            if(isDtKey(key)) {
                return new Date(
                    2000 + s2i(value, 0, 2),
                    s2i(value, 2, 2) - 1,
                    s2i(value, 4, 2),
                    s2i(value, 7, 2),
                    s2i(value, 9, 2)
                );
            }
        }
    }

    return value;
}

function stringifyReplacer(key, value) {
    if(value === null) {
        return null;
    }

    if(value === undefined) {
        return undefined;
    }

    if(value !== null) {
        if(isDateKey(key)) {
            value = new Date(value);
            return dateToString(value);
        } else {
            if(isDtKey(key)) {
                value = new Date(value)
                return `${i2s(value.getFullYear() % 100, 2)}${i2s(value.getMonth()+1,2)}${i2s(value.getDate(),2)}-${i2s(value.getHours(),2)}${i2s(value.getMinutes(),2)}`;
            } else {
                if (value instanceof Error) {
                    var error = { };

                    Object.getOwnPropertyNames(value)
                          .forEach(key => error[key] = value[key]);

                    return error;
                }
            }
        }
    }

    return value;
}

export function dateToString(date) {
    return `${i2s(date.getFullYear(), 4)}${i2s(date.getMonth()+1,2)}${i2s(date.getDate(),2)}-${i2s(date.getHours(),2)}${i2s(date.getMinutes(),2)}${i2s(date.getSeconds(),2)}.${i2s(date.getMilliseconds(),3)}`
}
