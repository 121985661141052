import React from 'react';

import { AppConst } from '../../../../../../const';

const levels = { };
AppConst.SkillLevel.forEach(l => levels[ l.key ] = l);

export default ({ value, confirm, update }) => {
    return <select className='fMedium' style={{width: '180'}} value={value || ''}
                   onChange={event => confirmChange(event, confirm, update, value)}
                   id='adminPlayerLevel'>
        { 
            [ { key: '', label: 'neposúdená' } ]
                .concat(AppConst.SkillLevel)
                .map(
                    level => <option key={level.key} value={level.key}>{level.label}</option>
                )
        }
    </select>
}

function confirmChange(event, confirm, update, oldValue) {
    const value = event.target.value;
    const level = value === '' ? null : levels[ value ];
    
    setTimeout(
        () => {
            if(confirm(level ? level.label : null)) {
                update(value);
            } else {
                global.document.getElementById('adminPlayerLevel').value = oldValue;
            }
        },
        0
    );
}
