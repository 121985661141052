import React from 'react';

import UnregPlayerTextBox from './unregPlayerTextbox';
import PlayerListContainer from '../playerListContainer';
import Div from '../../../../div';

export default ({ game, getUnregId, addUnregPlayer, showPlayer }) => {
    console.log(game);
    
    return <React.Fragment>
        <Div setup='c start start' wRel='100' id='cypressGamePlayers'>
            <Div setup='r center center' h='50' wRel='100' class='fgDark fBigger bgLighter'>
                <Div>{
                    (game.gameFlags.isLocked ? 'Súpiska: ' : 'Poradie: ') +
                    (game.players.length)
                }</Div>
            </Div>
            <PlayerListContainer game={game} playerList={game.players} mode='edit' showPlayer={showPlayer} />
            { !game.gameFlags.isLocked || !game.gameFlags.isFull
                ? <UnregPlayerTextBox game={game} getUnregId={getUnregId} onSubmit={addUnregPlayer} />
                : null
            }
        </Div>

        { game.gameFlags.isLocked
            ? <Div setup='c start start' wRel='100' id='cypressGameBackups'>
                <Div setup='r center center' h='50' wRel='100' class='fgDark fBigger bgLighter'>
                    <Div>Náhradníci: {game.backups.length}</Div>
                </Div>
                <PlayerListContainer game={game} playerList={game.backups} mode='edit' showPlayer={showPlayer} />
                { game.gameFlags.isFull
                    ? <UnregPlayerTextBox game={game} getUnregId={getUnregId} onSubmit={addUnregPlayer} />
                    : null
                }
            </Div>
            : null
        }

        { game.tooStrong.length > 0
            ? <Div setup='c start start' wRel='100' id='cypressGameTooStrong'>
                <Div setup='r center center' h='50' wRel='100' class='fgDark fBigger bgLighter'>
                    <Div>Silnejší náhradníci: {game.tooStrong.length}</Div>
                </Div>
                <PlayerListContainer game={game} playerList={game.tooStrong} mode='edit' showPlayer={showPlayer} />
            </Div>
            : null
        }
        
        { game.gameFlags.isLocked
            ? <Div setup='c start start' wRel='100' id='cypressGameCancelled'>
                <Div setup='r center center' h='50' wRel='100' class='fgDark fBigger bgLighter'>
                    <Div>Odhlásení: {game.cancelledOk.length + game.cancelledWarn.length}</Div>
                </Div>
                <PlayerListContainer game={game} playerList={game.cancelledOk} mode='edit' showPlayer={showPlayer} />
                <PlayerListContainer game={game} playerList={game.cancelledWarn} mode='edit' showPlayer={showPlayer} />
            </Div>
            : null
        }
    </React.Fragment>
}