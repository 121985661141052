import React from 'react';
import { connect } from 'react-redux';

import Player from './player';
import * as Requests from '../../../../requests';
import * as Actions from '../../../../actions';

const PlayerContainer = ({ playerId, getPlayer, confirm, update, openEdit, openGames, closeView, isDev }) =>
    <Player player={getPlayer(playerId)} confirm={confirm} update={update}
            openEdit={() => openEdit(playerId)} openGames={openGames}
            closeView={closeView} isDev={isDev} />

const mapStateToProps = state => ({
    isDev: state.user.player.isDev,
    getPlayer: playerId => state.get.playerById(playerId, true)
});

const mapDispatchToProps = dispatch => ({
    confirm: {
        nonPlayer: value => global.window.confirm(
            value
                ? 'Nastaviť hráča ako nehrajúceho pomocníka?'
                : 'Nastaviť hráča ako normálneho hokejistu?'
        ),
        resetReg: () => global.window.confirm('Hráč sa bude musieť nanovo registrovať, naozaj resetnúť?'),
        enable: () => global.window.confirm('Naozaj odblokovať hráča?'),
        disable: () => global.window.confirm('Naozaj zablokovať hráča?'),
        skillLevel: level => global.window.confirm('Nastaviť úroveň: ' + (level || 'neposúdená') + '?'),
        vip: value => global.window.confirm(value ? 'Naozaj nastaviť ako VIP?' : 'Naozaj odobrať VIP?'),
        verify: () => global.window.confirm('Skontrolovaný, všetko ok?')
    },

    update: {
        setNonPlayer: (player, value) => dispatch(Requests.updatePlayerFlags(player.id, { isNonPlayer: value })),
        resetReg: player => {
            dispatch(Requests.updatePlayerFlags(player.id, { resetReg: true }));
            dispatch(Actions.popView());
        },
        disable: player => dispatch(Requests.updatePlayerFlags(player.id, { disabled: true })),
        enable: player => dispatch(Requests.updatePlayerFlags(player.id, { disabled: false })),
        setSkill: (player, levelKey) => dispatch(Requests.updatePlayerFlags(player.id, { skillLevel: levelKey })),
        vip: (player, value) => dispatch(Requests.updatePlayerFlags(player.id, { vip: value })),
        verified: player => dispatch(Requests.updatePlayerFlags(player.id, { verified: true }))
    },

    openEdit: playerId => dispatch(
        Actions.pushView(Actions.VIEW.PlayerDetailEdit, { playerId })
    ),
        
    openGames: playerId => dispatch(
        Actions.pushView(Actions.VIEW.PlayerDetailGames, { playerId })
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerContainer)
