import React from 'react';
import { connect } from 'react-redux';

import PlayerContextMenu from '../../../playerContextMenu';
import Div from '../../../../div';
import Icon from '../../../../icon';
import * as Actions from '../../../../../actions';

class PlayerItem extends React.Component {
    constructor(props) {
        super(props);
        this.onPlayerClick = this.onPlayerClick.bind(this);
    }

    onPlayerClick() {
        if(this.props.isAdmin) {
            this.refs.contextMenu.onOpen();
        }
    }
    
    render() {
        const props = this.props;

        const game = props.game;
        const player = props.player;
        const flags = game.playerFlags[ player.id ];
        const actions = props.actions;

        const isLocked = game.gameFlags.isLocked;

        const isBackup = flags.backups || flags.tooStrong;
        const isCancelled = flags.cancelled;

        const warn = [];
        if(flags.warning) {
            warn.push('nemá náhradníka');
        }
        const isHelper = game.helpers[ player.id ];
        if(isHelper) {
            const ice = props.getIce(game);
            if(ice.helpers[ player.id ]) {
                warn.push('pravidelný šatniar');
            } else {
                warn.push('šatniar');
            }
        }
        if(player.isAdmin) {
            warn.push('admin');
        }
        const style = (isHelper || player.isAdmin) ? { color: 'blue' } : null;

        return <Div setup='r start center' wRel='100' h='45'>
            <Div fx='50'>
                <Icon image={isCancelled ? 'cancelRed' : 'cancelDark'} css='IconMedium'
                      onClick={() => remove(game, player, isBackup, isCancelled, isLocked, actions)} />
            </Div>

            <Div setup='c start start' wRel='100' onClick={this.onPlayerClick} class={props.isAdmin ? 'clickable' : ''} style={style}>
                <Div wRel='100' style={{ textAlign: 'center' }}>{flags.name}</Div>
                { warn.length > 0
                    ? <Div class='fSmall' wRel='100' style={{ textAlign: 'center' }}>{warn.join(', ')}</Div>
                    : null
                }
                { props.isAdmin
                    ? <PlayerContextMenu ref='contextMenu' player={player} showPlayer={() => props.showPlayer(player)}
                                         enableUnloadEvent={props.enableUnloadEvent}
                                         gameKey={{ iceDate: game.ice.date, iceRink: game.ice.rink, gameDate: game.gameDate }} />
                    : null
                }
            </Div>

            <Div fx='50'>
                { isBackup && !game.gameFlags.isFull
                    ? <Icon image='arrowUp' css='IconMedium' onClick={() => toPlayers(game, player, actions)} />
                    : null
                }
                { isCancelled
                    ? <Icon image='arrowUp' css='IconMedium' onClick={() => toPlayers(game, player, actions)} />
                    : null
                }
            </Div>
        </Div>
    }
}

const mapStateToProps = state => ({
    getIce: game => state.get.iceByKey(game.ice.date, game.ice.rink)
});

const mapDispatchToProps = dispatch => ({
    enableUnloadEvent: enabled => dispatch(
        Actions.setGlobalParam(
            Actions.GLOBAL_PARAM.UnloadEventEnabled,
            enabled
        )
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerItem)

function toPlayers(game, player, actions) {
    if(global.window.confirm(`${player.info.lastName + ' ' + player.info.firstName} - pridať do súpisky?`)) {
        actions.moveToPlayers(game, player.id);
    }
}

function remove(game, player, isBackup, isCancelled, isLocked, actions) {
    const playerName = `${player.info.lastName + ' ' + player.info.firstName}`;

    const msg = isCancelled
        ? `${playerName} - úplne vymazať z termínu?`
        : (isLocked
            ? `${playerName} - vyhodiť ${(isBackup ? 'z náhradníkov' : 'zo súpisky')}?`
            : `${playerName} - vyhodiť z poradia?`
        );

    if(global.window.confirm(msg)) {
        actions.removePlayer(game, player.id);
    }
}
