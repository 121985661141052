import React from 'react';
import { GoogleLogin } from '@react-oauth/google';

import InfoSection from "./infoSection";
import Div from '../../div';
import { isProduction } from '../../../../const';
import TelNumber from '../../telNumber';

const style = {
    width: '300px',
    height: '45px',
    marginLeft: '10px',
    borderRadius: '3px',
    display: 'inlineBlock',
    background: 'rgb(209, 72, 54)',
    color: 'rgb(255, 255, 255)',
    paddingTop: '10px',
    paddingBottom: '10px',
    border: '1px solid transparent',
    fontSize: '20px',
    fontWeight: 'bold',
    fontFamily: 'Roboto'
};

const leftMargin = {
    marginLeft: '10px'
};

let LoginSingleton = null;

export default class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCookiesInfo: false
        }
        this.onError = this.onError.bind(this);
        LoginSingleton = this;
    }

    onError(error) {
        if(thirdPartyCookiesBlocked(error)) {
            LoginSingleton.setInnerState({ showCookiesInfo: true });
        } else {
            this.props.onLoginError(error);
        }
    }

    setInnerState(newState) {
        this.setState(() => newState);
    }

    renderLoginButton() {
        const onLoggedIn = this.props.onLoggedIn;
        return <GoogleLogin
            theme='filled_blue'
            width='300px'
            onSuccess={onLoggedIn}
            onError={error => {
                console.log('Login Failed');
                this.onError(error);
            }}
        />;
    }

    render() {
        LoginSingleton = this;
        const onLoggedIn = this.props.onLoggedIn;
        const showCookiesInfo = this.state.showCookiesInfo;

        return <Div setup='c start start' wRel='100' hView='100'>
            <Div fx='30' wRel='100' />
            <Div fx='45' wRel='100' wMax='450' setup='r center center' w='400px'>
                <Div fx='240'>
                { this.renderLoginButton() }
                </Div>
            </Div>

            { isProduction()
                ? null
                : <Div>
                    <input id='cypress.login.user' type='text' />
                    <Div id='cypress.login.submit' w='20' h='20' onClick={() => cypressLogin(onLoggedIn)} style={{ border: '1px solid gray' }} />
                </Div>
            }

            <InfoSection loginButton={this.renderLoginButton()} />

            { showCookiesInfo
                ? <Div fx='100' setup='c center center' wRel='90' style={leftMargin}>
                    <br/>
                    <Div>Sorry za problémy.</Div>
                    <br/>
                    <Div>Pre prihlásenie musíš povoliť cookies tretích strán, a znova načítať stránku.</Div>
                    <Div>Ak si to nevieš nastaviť, zavolaj mi na:</Div>
                    <TelNumber tel='421904560820' />
                </Div>
                : null
            }

        </Div>
    }
}

function thirdPartyCookiesBlocked(message) {
    return message && message.error && (
        message.error === 'idpiframe_initialization_failed'
        || message.error === 'popup_closed_by_user'
    );
}

function cypressLogin(onLoggedIn) {
    const elem = global.document.getElementById('cypress.login.user');
    const userId = elem && elem.value ? elem.value.trim() : null;
    if(userId) {
        onLoggedIn({
            userId: elem.value,
            userToken: elem.value
        });
    }
}
