import React from 'react';
import { connect } from 'react-redux';

import GameItem from '../../gameItemComponent';
import * as GameStatus from '../../../gameStatus';
import * as Actions from '../../../../actions';
import * as Requests from '../../../../requests';

const GameItemContainer = ({ player, getPlayer, game, confirm, toggle, setDedicatedHelper, removeDedicatedHelper }) => {
    return <GameItem game={game} isIce={false} confirm={confirm}
              isAdmin={player.isAdmin} isNonPlayer={player.isNonPlayer}
              toggle={toggle} setHelper={setDedicatedHelper} removeHelper={removeDedicatedHelper} getPlayer={getPlayer} />
}

const mapStateToProps = state => ({
    player: state.user.player,
    getPlayer: state.get.playerById,
    confirm: game => GameStatus.confirmGameToggle(game, state.user.player),
});

const mapDispatchToProps = dispatch => ({
    toggle: game => dispatch(
        Requests.toggleGame(game.ice.date, game.ice.rink, game.gameDate, !game.userFlags.going)
    ),

    setDedicatedHelper: game => dispatch(
        Actions.pushView(
            Actions.VIEW.Players,
            {
                playerFilter: playerId => playerId !== game.dedicatedHelperId,

                selectPlayer: player => {                
                    dispatch(
                        Actions.popView(null, Actions.VIEW.Players)
                    );

                    dispatch(
                        Requests.setDedicatedGameHelper(game.ice.date, game.ice.rink, game.gameDate, player.id)
                    )
                }
            }
        )
    ),

    removeDedicatedHelper: game => dispatch(
        Requests.setDedicatedGameHelper(game.ice.date, game.ice.rink, game.gameDate, null)
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GameItemContainer)
