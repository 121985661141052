import React from 'react';

import Div from '../../../div';
import Icon from '../../../icon';

export default ({ letter }) =>
    <Div setup='r between center' wRel='100' css='ViewHeader'>
        <Div setup='r start center' wRel='100'>
            <Div>
                <Icon css='IconBig' image='players' />
            </Div>
            <Div wRel='100'>Hráči: {letter.toUpperCase()}</Div>
        </Div>
    </Div>
