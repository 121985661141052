import React from 'react';

import Div from '../../../../div';
import Icon from '../../../../icon';

export default ({ game, playerId, actions }) => {
    const flags = game.playerFlags[ playerId ];
    const name = playerId.slice(playerId.indexOf(':') + 1);

    const isBackup = flags.backups || flags.tooStrong;
    const isCancelled = flags.cancelled;

    const isLocked = game.gameFlags.isLocked;

    return <Div setup='r start center' wRel='100' h='45'>
        <Div fx='50'>
            <Icon image={isCancelled ? 'cancelRed' : 'cancelDark'} css='IconMedium'
                  onClick={() => remove(game, playerId, name, isLocked, isBackup, isCancelled, actions)} />
        </Div>

        <Div setup='c start start' wRel='100'>
            <Div wRel='100' style={{ textAlign: 'center' }}>{name}</Div>
            <Div wRel='100' class='fSmall' style={{ textAlign: 'center' }}>
                (nereg.) { flags.warning ? ' nemá náhradníka' : '' }
            </Div>
        </Div>

        <Div fx='50'>
            { isBackup && !game.gameFlags.isFull
                ? <Icon image='arrowUp' css='IconMedium' onClick={() => toPlayers(game, playerId, name, actions)} />
                : null
            }
            { isCancelled
                ? <Icon image='arrowUp' css='IconMedium' onClick={() => toPlayers(game, playerId, name, actions)} />
                : null
            }
        </Div>
    </Div>
}

function toPlayers(game, playerId, name, actions) {
    if(global.window.confirm(`${name} - pridať do súpisky?`)) {
        actions.moveToPlayers(game, playerId);
    }
}

function remove(game, playerId, name, isLocked, isBackup, isCancelled, actions) {
    const msg = isCancelled
        ? `${name} - úplne vymazať z termínu?`
        : (isLocked
            ? `${name} - vyhodiť ${(isBackup ? 'z náhradníkov' : 'zo súpisky')}?`
            : `${name} - vyhodiť z poradia?`
        );

    if(global.window.confirm(msg)) {
        actions.removePlayer(game, playerId);
    }
}
