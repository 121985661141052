const Colors = {
    blue: '#5bd6fb',
    yellow: '#ffff9b',
    reddish: '#ff9d7a',
    green: '#a9ff8f'
}

export const AppConst = {
    Domain: 'hkj.sk',

    PlayerStrongDifference: 1,
    PlayerTooStrongDifference: 2,

    SkillLevel: [
        { key: 'ľ', label: 'ľahká', value: 1 },
        { key: 'ľs', label: 'ľahká stredná', value: 2 },
        { key: 's', label: 'stredná', value: 3 }
    ],
    Rinks: [
        { key: 'rža', label: 'Ružinov A: hlavná plocha',     bg: Colors.blue },
        { key: 'ržb', label: 'Ružinov B: vedľajšia plocha',  bg: Colors.blue },
        { key: 'lmč', label: 'Lamač',                        bg: Colors.yellow },
        { key: 'dúb', label: 'Dúbravka',                     bg: Colors.reddish },
        { key: 'slh', label: 'Slovan: hlavná plocha',        bg: Colors.green },
        { key: 'sla', label: 'Slovan: trénigová plocha A',   bg: Colors.green },
        { key: 'slb', label: 'Slovan: tréningová plocha B',  bg: Colors.green }
    ],
    RinkFilterGroups: [
        { key: 'rz', label: 'Ružinov', rinks: [ 'rža', 'ržb' ] },
        { key: 'lm', label: 'Lamač', rinks: [ 'lmč' ] },
        { key: 'db', label: 'Dúbravka', rinks: [ 'dúb' ] },
        { key: 'sl', label: 'Slovan', rinks: [ 'slh', 'sla', 'slb' ] },
    ],

    PlayerLimit: 20,
    HistoryDays: 14,
    FutureDays: 35,
    PastGamesVisibleHours: 24,

    GameLocked: {
        days: 2,
        hours: 13
    },
    GameToday: {
        days: 0,
        hours: 9
    },
    
    PlayerListBackupFuture: 7,
    GameEndIntervalSecs: 5 * 60,
    OpenPositionsNotifyHours: [ 10, 15 ],
    LogBackupIntervalSecs: 30 * 60,

    CalendarTransitionTime: 0.3,
    UnsuccessfulRegistrationDelay: 1000,

    ContactCharvat: {
        Name: 'Martin Charvát',
        Email: 'hokej@martincharvat.sk',
        Tel: '421904560820',
        FB: {
            info: 'facebook.com/martin.charvat',
            value: 'https://facebook.com/martin.charvat'
        }
    }
}

AppConst.SkillLevelByKey = { };
AppConst.SkillLevel.forEach(lvl => AppConst.SkillLevelByKey[ lvl.key ] = lvl);

AppConst.RinksByKey = { };
AppConst.Rinks.forEach(r => AppConst.RinksByKey[ r.key ] = r);

AppConst.RinkFilterGroupsByKey = { };
AppConst.RinkFilterGroups.forEach(r => AppConst.RinkFilterGroupsByKey[ r.key ] = r);

export const UserImageConst = {
    DATA_MIME:          'image/jpeg',
    DATA_BASE64_START:  23,                      // bytes
    IMAGE_MAX_BYTES:    8 * 1024 * 1024,         // 8MB, but base64
    IMAGE_MAX_SIZE:     1080,                    // pixels
    IMAGE_THUMB_SIZE:   100,                     // pixels
    FILE_EXT_BIG:       '.big.jpg',
    FILE_EXT:           '.jpg',
    REQ_PATH:           '/userImage'
}

export const DataEvent = {
    CurrentVersion: '0.9.12',

    Session: {
        Session: 'session',
        NoSession: 'noSession',
        Token: 'token',
        TokenInvalid: 'tokenInvalid',
        DropSession: 'dropSession'
    },

    Player: {
        Player: 'player',
        Players: 'players',
        UpdatePlayer: 'updatePlayer',
        UpdatePlayerByAdmin: 'updatePlayerByAdmin',
        UpdateFlags: 'updateFlags'
    },

    Ice: {
        Ice: 'ice',
        Ices: 'ices',
        CreateIce: 'createIce',
        UpdateIce: 'updateIce',
        EditAutoJoin: 'editAutoJoin',
        ToggleAutoHelper: 'toggleAutoHelper',
        SetDedicatedAutoHelper: 'setDedicatedAutoHelper'
    },

    GameEvent: {
        GameEvent: 'gameEvent',
        JoinLeaveWeekly: 'joinLeaveWeekly',
        JoinLeave: 'joinLeave',
        MarkPlayer: 'markPlayer',
        EditPlayer: 'editPlayer',
        CancelGame: 'cancelGame',
        SetGameNotice: 'setGameNotice',
        ToggleHelper: 'toggleHelper',
        SetDedicatedHelper: 'setDedicatedHelper',

        UnregisteredPlayer: 'unreg:'
    },

    Game: {
        Game: 'game',
        Games: 'games',
        GamesDiff: 'gamesDiff'
    },

    Sync: 'sync',
    SyncResponse: 'syncResponse',

    Feedback: 'feedback',

    Prefix: {
        GoogleUser: 'ggl:',
        CypressUser: 'cpr:'
    },

    Cypress: 'cypress',
    CypressResponse: 'cypressResponse'
}

export const ThirdParty = {
    Google: {
        AppId: '777767662360-rucpq96a5sv43imgpit7d3kf0ltcoglv.apps.googleusercontent.com',
        Prefix: 'ggl:'
    },
    Cypress: {
        Prefix: 'cpr:'
    }
}

export const SessionCookie = {
    name: AppConst.Domain + '.session',
    lifeYears: 5
}

export const Links = {
    CharvatInfo: 'http://hokej.martincharvat.sk',
    CharvatRules: 'https://docs.google.com/document/d/1TKgea_rWF9rK8YhceTAcCWvEe-i4hTapPt2PRRGIje0/edit?authkey=COXghdIO',
}

export const ApiConst = {
    SendingDelay: 3000,
    SendingErrorDelay: 10000,
    SyncDelay: 1000,
    AutoConnect: false,
    Reconnection: false,
    Timeout: 10000,
    TickIntervalSecs: 60,
    TickOffsetSecs: 2
}

export function isPlayerStrong(playerLvl, gameLvl) {
    if(playerLvl === null || playerLvl === undefined) {
        return false;
    }
    const playerVal = playerLvl ? AppConst.SkillLevelByKey[ playerLvl ].value : 0;
    const gameVal = AppConst.SkillLevelByKey[ gameLvl ].value;
    return playerVal - gameVal >= AppConst.PlayerStrongDifference;
}

export function isPlayerTooStrong(playerLvl, gameLvl) {
    if(playerLvl === null || playerLvl === undefined) {
        return false;
    }
    const playerVal = playerLvl ? AppConst.SkillLevelByKey[ playerLvl ].value : 0;
    const gameVal = AppConst.SkillLevelByKey[ gameLvl ].value;
    return playerVal - gameVal >= AppConst.PlayerTooStrongDifference;
}

export function isProduction() {
    if(global.window && global.window.location && global.window.location.hostname === 'localhost') {
        return false;
    }
    const isDev = process.env.NODE_ENV === 'dev' || process.env.NODE_ENV === 'devux';
    return !isDev;
}
