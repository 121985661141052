import React from 'react';
import { connect } from 'react-redux';

import PlayerItemComponent from './playerItemComponent';
import GameItemComponent from '../gameItemComponent';
import IceItemComponent from '../iceItemComponent';
import Div from '../../div';
import Button from '../../button';
import * as Actions from '../../../actions';

const PlayerDetailGamesView = ({ params, getPlayer, closeView }) => {
    const player = getPlayer(params.playerId);
    const games = player.games;
    const gameCount = games.games.length;

    return <Div setup='c start start' wRel='100' css='View'>
        <PlayerItemComponent player={player} />

        <Div setup='r start center' wRel='100' css='ViewHeader'>
            <Div class='padLeft'>Pravidelné prihlásenie</Div>
        </Div>
        {games.ices.map(
            (ice, n) => <IceItemComponent key={n} ice={ice} inList={true} />
        )}

        <Div setup='r start center' wRel='100' css='ViewHeader'>
            <Div class='padLeft'>Ľady ({gameCount}):</Div>
        </Div>
        {games.games.map(
            (game, n) => <GameItemComponent key={n} game={game} />
        )}

        <Div css='ButtonSectionPadding' />
        <Div setup='r center center' class='fixedDown' wRel='100'>
            <Div css='ButtonSection' setup='r around center' class='container'>
                <Button text='Zavri' onClick={closeView} />
            </Div>
        </Div>
    </Div>
}

const mapStateToProps = state => ({
    getPlayer: playerId => state.get.playerById(playerId, true)
});

const mapDispatchToProps = dispatch => ({
    closeView: () => dispatch(Actions.popView())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerDetailGamesView)
