import React from 'react';

import Profile from './profile';
import Level from './level';
import NonPlayer from './nonPlayer';
import RegStatus from './regStatus';
import Vip from './vip';
import * as Common from './common';
import PlayerItem from '../../playerItemComponent';
import * as GameStatus from '../../../../gameStatus';
import Div from '../../../../div';
import Button from '../../../../button';

const vipCheckbox = 'adminPlayerVipCheckbox';

export default ({ player, confirm, update, openEdit, closeView, openGames, isDev }) => {
    const reg = player.reg || { };
    const status = GameStatus.playerStatus(player);
    
    const regNotImportang = !player.isAdmin && player.isNonPlayer;
    const playerGameCount = player.games.games.length;

    return <Div wRel='100'>
        <PlayerItem player={player} />

        <Div class='padLeftSmall'>
            <Div h='25' setup='c end center' wRel='100' class={'fBold ' + status.class}>
                <Div>{status ? status.text : 'OK'}</Div>
            </Div>
            <hr/>

            { isDev ? player.id : null }
            <RegStatus player={player} />

            <Profile player={player} showRules={!regNotImportang} />
            <hr />

            { !player.isAdmin
                ? Common.row(
                    'Nehrajúci:',
                    <NonPlayer value={player.isNonPlayer || ''}
                           confirm={confirm.nonPlayer}
                           update={value => update.setNonPlayer(player, value)} />,
                    false,
                    80
                )
                : null
            }

            { regNotImportang
                ? null
                : <React.Fragment>
                    { Common.text('Brzdenie:', (reg.skillBraking || '') + '%') }
                    { Common.text('Prekladanie:', (reg.skillTurning || '') + '%') }
                    { Common.text('Pr. dozadu:', (reg.skillTurningBack || '') + '%') }
                            { Common.row(
                                'Úroveň:',
                                <Level value={player.skillLevel || ''}
                                confirm={confirm.skillLevel}
                                update={value => update.setSkill(player, value || null)} />,
                                false,
                                80
                                ) }
                </React.Fragment>
            
            }

            <Vip value={player.vip} onChange={ value => confirm.vip(value) ? update.vip(player, value) : null } id={vipCheckbox} />
            
            <hr />
            { player.adminNote
                ? <React.Fragment>
                    <Div setup="r start center" wRel="100" style={{whiteSpace: "pre-line"}}>
                        <Div class='fSmall padRight'>{"Admin\npozn.:"}</Div>{player.adminNote}
                    </Div>
                    <hr/>
                </React.Fragment>
                : null
            }
            <Div setup="r center center" h="60" wRel="100">
                <Button text={`Prihlásený na ľady: ${playerGameCount}`} w='200' controlled={true} on={false} 
                        onClick={() => openGames(player.id)} />
            </Div>
        </Div>

        <Div css='ButtonSectionPadding' h='180' />
        <Div setup='r center center' class='fixedDown' wRel='100' h='120'>
            <Div css='ButtonSection' setup='r around center' class='container'>
                <Div setup='r between center' wRel='100' style={footerDetail}>
                    <Div setup='c around center' wRel='100' h='120'>
                        <Div setup='r around center' wRel='100'>
                            { player.isDisabled
                                ? <Div w='105' />
                                : <Button text='Vymazať' w='105' controlled={true} on={false} 
                                confirm={confirm.resetReg} onClick={() => update.resetReg(player)} />
                            }

                            { player.isDisabled
                                ? <Button text='Odblokovať' w='105' controlled={true} on={false} 
                                          confirm={confirm.enable} onClick={() => update.enable(player)} />

                                : <Button text='Zablokovať' w='105' controlled={true} on={false} 
                                          confirm={confirm.disable} onClick={() => update.disable(player)} />
                            }
                        </Div>

                        <Div setup='r around center' wRel='100'>
                            <Button text='Upraviť' w='105' onClick={openEdit} controlled={true} on={false} />
                            <Button text='Zavri' w='105' controlled={true} on={false} onClick={closeView} />
                        </Div>
                    </Div>
                </Div>
            </Div>
        </Div>
    </Div>
}

const footerDetail = {
    paddingTop: '5px',
    paddingBottom: '5px',
    borderTop: '1px solid gray',
}
