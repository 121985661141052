import { all, takeEvery } from 'redux-saga/effects'

import * as Api from './api';
import * as User from './user';
import * as Data from './data';
import { TYPE } from '../../actions';

export default function* sagas() {
    yield all([
        // producers run in an infinite loop
        Api.produceReceivedAction(),
        Api.produceErrorAction(),

        // watchers react to actions
        yield takeEvery(TYPE.Send,                          Api.handleSend),

        yield takeEvery(TYPE.Init,                          User.handleInit),
        yield takeEvery(TYPE.Token,                         User.handleLoginToken),
        yield takeEvery(TYPE.Logout,                        User.handleLogout),
        yield takeEvery(User.matchSessionErrorReceived,     User.handleSessionError),
        yield takeEvery(User.matchPlayerReceived,           User.handleUserPlayer),

        yield takeEvery(TYPE.ContentView,                   Data.loadContentData)
    ]);
}
