import React from 'react';
import { connect } from 'react-redux';

import Div from '../div';
import Icon from '../icon';
import Button from '../button';
import * as Actions from '../../actions';

const Modal = ({ onClose }) =>
    <Div setup='c around center' css='View' hView='90' class='fMedium marg'>
        <Div setup='r around center' wRel='100' class='fBig margSmall'>
            <Icon css='IconMedium' image='flagUkraine' />
            <span class='fBigger'>Sláva Ukrajine!</span>
            <Icon css='IconMedium' image='flagUkraine' />
        </Div>

        <Div><b>Tí z vás, ktorí majú iný názor, viac nie sú na mojich partičkach vítaní.</b></Div>

        <Div>Snáď som sa vyjadril úplne otvorene, jasne a priamo. Nie je to pre mna výhodné. Pre slabý záujem sa teraz vela ľadov rušilo.<br/><br />
        Možno to teraz bude ešte horšie. Odháňať od seba hráčov ma bolí.<br /><br />
        Hodnoty, ktorým verím mi však nedovolia konať inak. Ale čo nič nestojí, tak vlastne za nič nestojí.</Div>
        <Div setup='r end center' wRel='100'><Div>Martin Charvát&nbsp;&nbsp;&nbsp;&nbsp;</Div></Div>

        <Div setup='r center center' wRel='100'><Div wRel='70'>Pomôcť môžete napríklad tu: <a href="https://ktopomozeukrajine.sk">https://ktopomozeukrajine.sk</a></Div></Div>
        <Div css='ButtonSection' setup='c center center' h='60'>
            <Button text='Zavri' w={100} onClick={onClose} />
        </Div>
    </Div>

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => ({
    onClose: () => dispatch(Actions.popView())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Modal)
