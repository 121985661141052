import React from 'react';

import Div from './components/div';
import Icon from './components/icon';
import ModalInfo from './components/modal/modalInfo';
import { AppConst } from '../const';

const lockedHourDesc = AppConst.GameLocked.hours + ':00';
const todayHourDesc = AppConst.GameToday.hours + ':00';

export default {
// zobrazuje sa v termine pod prepinacom
    // minulost
    GamePastNonPlayer: 'Posledný ľad (archív)',
    GamePastGoing:     'Posledný ľad (archív) - Bol si hrať',
    GamePastNotGoing:  'Posledný ľad (archív)',
    
    // kym nie je supiska
    GameGoing:         'Si na {playerPos}. mieste v súpiske',
    // prihlasovanie je uz zavezne a je supiska
    GameGoingLocked:   'Si na {playerPos}. mieste v súpiske, vidíme sa na ľade :)',
    GameBackup:        pos => `Termín je plný - si na ${pos}. mieste`,
    GameStrong:        'Nižšia úroveň ľadu ako je tvoja. Si v poradí náhradníkov',
    GameUserTooStrong: 'Úroveň ľadu je príliš nízka pre teba.',
    GameWarning:       'Nejdeš a hľadáš si náhradu, inak platíš vstup ak sa termín nenaplní',

// zobrazi sa ked sa v termine klikne na (i)
    GameInfoWarning:
        <div className='fSmall'>
            <b>Hráč ktorí hľadá náhradníka:</b>
            <p>Termín na odhlasovanie účasti je vždy 2 dni pred ľadom do 13:00. Vtedy sa tvoje nahlásenie považuje za záväzné a más isté miesto na ľade.</p>
            <p>Ak sa odhlásiš neskôr, je dobré ak si nájdeš za seba náhradníka, aby si nemusel za ľad platit. Náhradník musí byť hráč takej úrovne, aká ja vypísaná na daný termín. Na ľahkú uroveň môže iba náhradník ľahkej úrovne.</p>
            <p>Náhradníka ti pomáham hľadať aj ja. Je preto dobré mi brnknúť, aby som hneď na teba myslel. Ak sa ti nedá volať, tak SMS je lepšia ako nič. Email neodporúčam, lebo si nekontrolujem mailbox nonstop, niekedy iba raz za deň.</p>
            <p>Ak si nájdeš náhradníka sám, tak máš náhradníka za seba samozrejme ihneď a vec je vybavená. Daj mi vedieť, že tento hráč ide za teba. </p>
            Ak za teba hľadám náhradníka ja, tak je to vybavené vtedy, keď je na ľade 20 hráčov.
        </div>,

    GameInfoBackup:
        <div className='fSmall'>
            <p><b>Hráč náhradník:</b></p>
            <p>Si náhradník na daný termín. Ostatní hráči sa na ľad prihlásili skôr ako ty. V prípade, ak sa niekto odhlási, posunieš sa v poradí ďalej.</p>
            <p>V čase, kedy už je pred ľadom náhradníka kontaktujeme telefonicky, či ešte chce ísť na ľad. Ak už máš iný program, nič sa nedeje, môžeš sa rozhodnúť neísť. Tvoja účasť nie je záväzná do momentu, kým ju nepotvrdíš, keď ti budem volať.</p>
        </div>,
    
    GameInfoStrong:
        <div className='fSmall'>
            <p><b>Hráč náhradník z lepšej úrovne:</b></p>
            <p>Si hráčom z vyššej úrovne ako je daný ľad. V prvom rade dávam priestor na ľade hráčom rovnakej úrovne. Hráči z vyššej úrovne sa dostanú na ľad ak ostane voľné miesto. Hráči v rozdiele až o 2 úrovní na ľad púšťaní nie sú vôbec.</p>
            <p>Hráč čaká pod čiarou a v prípade ak zostanú voľné miesta poobede (okolo 15-17h), sú hráči obvolaní, či majú stále záujem. Účasť nie je záväzná, až kým ťa nekontaktujem a nepotvrdíš, že chceš ísť.</p>
        </div>,

// potvrdenie pri prihlaseni / odhlaseni
    // vseobecne
    GameConfirmGoing: gameDesc => ({
        vertical: true,
        h: 200,
        wCancel: 160,
        wOk: 160,
        text: {
            info: <span>Naozaj sa chceš prihlásiť na <b>{gameDesc}</b>?</span>,
            ok: <Div setup='r start center'>
                <Icon css='IconMedium' image='okGreen'/>
                Áno, prihlásiť
            </Div>,
            cancel: 'Nie, klikol som sem omylom'
        }
    }),

    GameConfirmLeave: gameDesc => ({
        vertical: true,
        h: 200,
        wCancel: 160,
        wOk: 160,
        text: {
            info: <span>Naozaj sa chceš odhlásiť z <b>{gameDesc}</b>?</span>,
            ok: <Div setup='r start center'>
                <Icon css='IconMedium' image='cancelDark'/>
                Áno, odhlášiť
            </Div>,
            cancel: 'Nie, klikol som sem omylom'
        }
    }),

    // 2 dni pred ladom je prohlasnie uz zavazne
    GameConfirmGoingLocked: gameDesc => ({
        vertical: true,
        h: 260,
        wCancel: 160,
        wOk: 200,
        text: {
            info: <span>Ľad <b>{gameDesc}</b> je čoskoro a odhlasovanie je už uzavreté. <br/>Preto upozorňujem, že tvoja účasť bude záväzná. Nebude sa dať odhlásiť, naozaj ideš?'</span>,
            ok: <Div setup='r start center'>
                    <Icon css='IconMedium' image='okGreen'/>
                    ÁNO, idem záväzne
            </Div>,
            cancel: 'Nie, nejdem'
        }
    }),

    // odhlasovanie 2 dni pred ladom
    GameConfirmNotGoingLocked: gameDesc => ({
        vertical: true,
        h: 280,
        wCancel: 240,
        wOk: 240, hOk: 80,
        text: {
            info: <span>Odhlasovanie z termínu <b>{gameDesc}</b> je už uzavreté. Ak nejdeš a nenájde sa za teba náhradník, budeš si musieť ľad zaplatiť</span>,
            ok: <Div setup='r start center'>
                    <Icon css='IconMedium' image='cancelRed'/>
                    <Div w='180'>NIE, NEJDEM. <br/>Ak bude treba, ľad zaplatím na účet</Div>
            </Div>,
            cancel: 'Ostávam, pôjdem na ľad.'
        }
    }),

    // hrac je silnejsi
    GameConfirmStrong: gameDesc => ({
        vertical: true,
        h: 280,
        wCancel: 160,
        wOk: 240, hOk: 60,
        text: {
            info: <span>Ľad <b>{gameDesc}</b> je nižšej úrovne ako je tvoja úroveň. Môžeš sa nahlásiť do náhradníkov a ak ostane miesto, zavoláme ťa na ľad. Vyhovuje ti to?</span>,
            ok: <Div setup='r start center'>
                <Icon css='IconMedium' image='okYellow'/>
                <Div w='180'>ÁNO, budem v poradí náhradníkov</Div>
            </Div>,
            cancel: 'Nie, nejdem'
        }
    }),

    // ak sa neskoro odhlasoval a este nema nahradnika, ale v den ladu sa rozhodne ze chce ist predsa len hrat
    GameConfirmWantsBackWarning: (gameDesc, tel) => ({
        h: 260,
        text: {
            info: [
                <span><b>{gameDesc}</b> - nie si v súpiske a hľadá sa za teba náhradník. Je už dosť neskoro a hľadám za teba náhradu. Aby nevzniklo nedorozumenie a chceš predsa ísť hrať, tak mi brnkni.</span>,
                tel
            ],
            cancel: 'Rozumiem'
        }
    }),

    // to iste, ale uz ma nahradnika a teda je uspesne odhlaseny
    GameConfirmWantsBack: (gameDesc, tel) => ({
        h: 260,
        text: {
            info: [
                <span><b>{gameDesc}</b> - nie si v súpiske a našiel sa za teba náhradník.<br/> Je už dosť neskoro, a aby nevzniklo nedorozumenie ak chceš ísť predsa len hrať, tak mi brnkni.</span>,
                tel
            ],
            cancel: 'Rozumiem'
        }
    }),

    // ak je 20 a viac hracov, dostane info ze ak sa prihlasi, musi si sledovat maily
    GameConfirmBackup: (gameDesc, playerCount, isLocked) => ({
        vertical: true,
        h: 480,
        wCancel: 240,
        hOk: 120, wOk: 240,
        text: {
            info: <React.Fragment>
                  V termíne <b>{gameDesc}</b> {`si na ${playerCount+1}. mieste. Znamená to, že ešte nie si v súpiske, lebo maximum je ${AppConst.PlayerLimit} hráčov.`}<br />
                  Čakáš ako náhradník. Kým si náhradník, môžeš účasť rušit hocikedy.<br />
                  {`Ak sa však dostaneš do súpisky, platí pravidlo o odhlasovaní do ${lockedHourDesc} ${AppConst.GameLocked.days} dni pred ľadom.`}<br />
                  <br />
                  <Div setup='r start center'>
                      <ModalInfo image='infoDark' size='Small'>
                          <Div class='fSmall'>
                              {`Momentálne si ${playerCount+1} v poradí. Maximum je ${AppConst.PlayerLimit} hráčov na ľade.`}
                              Do ľadu však ešte zostáva nejaký čas, hráči sa môžu ešte odhlásiť a dostaneš sa do súpisky. Ale aj nemusia. Vždy platí pravidlo kto sa skôr prihlási, ten je skôr na súpiske.<br/>
                              <br/>
                              {`Ak teraz potvrdíš, že chceš ostať prihlásený, tak do času ${todayHourDesc} ráno v deň ľadu budeš automaticky pri uvoľnení miesta pridaný do súpisky.`}
                              Tvoja účasť bude potom záväzná. <br/>
                              <br/>
                              {`Od ${todayHourDesc} v deň ľadu je už súpiska finálna - potom už neprebiehajú veci automaticky, ale volám.`}
                              Ak sa teda stále nedostaneš do súpisky ale budeš náhradník, tak v prípade uvoľnenia miesta po 9:00 v deň ľadu - ti už budem volať a pýtať sa ťa, či chceš ísť. Tvoja účasť bude záväzná až v momente keď mi ju v telefóne potvrdíš.<br/>
                              <br/>
                              Ak si ako náhradník, môžeš kedykoľvek svoju účasť rušiť. Ak ju zrušíš a znovu sa prihlásiš, no náhradníkov je viac, zaradíš sa na koniec radu náhradníkov.
                          </Div>
                      </ModalInfo>
                      Viac info o čakaní v poradí
                  </Div>
            </React.Fragment>,

            ok: <Div setup='r start center'>
                    <Icon css='IconMedium' image={isLocked ? 'okYellow' : 'okGreen'} />
                    <Div fx='160'>Áno, chcem čakať v poradí, záväzne pôjdem ak sa dostanem do súpisky</Div>
            </Div>,

            cancel: `Pozriem sa na to možno neskôr, či je voľné miesto.`
        }
    }),

// pravidelne prihlasovanie
    // chce hrat pravidelne
    AutomaticConfirmGoing: iceDesc => ({
        vertical: true,
        h: 400,
        wOk: 260,
        text: {
            info: [
                <span>Chceš sa naozaj prihlásiť trvalo na <b>{iceDesc}</b>?</span>,
                'Znamená to, že sa na ďalšie tieto termíny nemusíš prihlasovať po jednom. Je dôležité, aby si sa v prípade, keď nechceš ísť odhlásil včas z daného ľadu, aby si zbytočne neplatil vstup. Termín na odhlásenie je vždy 2 dni pred ľadom do 13:00.'
            ],
            ok: <Div setup='r start center'>
                    <Icon css='IconMedium' image='okGreen'/>
                    <Div fx='160'>ÁNO, chcem sa prihlásiť na trvalo</Div>
            </Div>,
            cancel: 'Nie'
        }
    }),

    // nechce viac chodit, ale najblizsi termin je uz zavezny
    AutomaticConfirmNotGoing: iceDesc => ({
        vertical: true,
        h: 270,
        wOk: 240,
        wCancel: 160,
        text: {
            info: [
                <span>Chceš zrušiť trvalé prihlásenie pre <b>{iceDesc}</b>?</span>,
                'Budeš odhlásený zo všetkých termínov, a budeš sa musieť prihlasovať ručne.'
            ],
            ok: <Div setup='r start center'>
                <Icon css='IconMedium' image='cancelDark'/>
                Odhlás ma z termínov
           </Div>,
            cancel: 'Ostávam'
        }
    }),

    // chce hravat pravidelne ale najblizsi termin uz je zavezny 
    AutomaticConfirmGoingLocked: dateDesc => ({
        vertical: true,
        h: 240,
        wOk: 180,
        wCancel: 180,
        text: {
            info: <span>Najbližší termín z tohto ľadu je už čoskoro (<b>{dateDesc}</b>) - preto, ak potvrdíš prihlásenie, je tvoja účasť záväzná.</span>,
            ok: <Div setup='r start center'>
                <Icon css='IconMedium' image='okGreen'/>
                Rozumiem
            </Div>,
            cancel: 'Aha, tak radšej nie'
        }
    }),

    // chce hravat pravidelne ale najblizsi termin je dnes 
    AutomaticConfirmGoingToday: dateDesc => ({
         vertical: true,
        h: 210,
        wOk: 180,
        wCancel: 180,
        text: {
            info: <span>Najbližší termín z tohto ľadu je už dnes (<b>{dateDesc}</b>), ak chceš ísť hrať aj dnes, tak mi brnkni.</span>,
            ok: <Div setup='r start center'>
                <Icon css='IconMedium' image='okGreen'/>
                Rozumiem
            </Div>,
            cancel: 'Aha, tak radšej nie'
        }
    }),

    // ZRUSIT
    // chce hravat pravidelne ale najblizsi termin je dnes a on sa odhlasoval, takze tento termin musi riesit s adminom
    AutomaticConfirmGoingCancelled:  dateDesc => ({
        vertical: true,
        h: 240,
        wOk: 160,
        wCancel: 180,
        text: {
            info: <span>Najbližší termín z tohto ľadu je už čoskoro (<b>{dateDesc}</b>) a ty si sa odhlasoval zo súpisky. Ak chceš ísť hrať ešte aj tento termín, musíš volať adminovi.</span>,
            ok: <Div setup='r start center'>
                <Icon css='IconMedium' image='okGreen'/>
                Rozumiem
            </Div>,
            cancel: 'Aha, tak radšej nie'
        }
    }),

    // nechce viac chodit, ale najblizsi termin je uz zavezny
    AutomaticConfirmNotGoingLocked: dateDesc => ({
        vertical: true,
        h: 280,
        wOk: 240, hOk: 70,
        wCancel: 160,
        text: {
            info: <span>Z termínu <b>{dateDesc}</b> sa už nedá automaticky odhlásiť, pretože je odhlasovanie uzavreté. Z ďalších nadchádzajúcich termínov budeš odhlásený</span>,
            ok: <Div setup='r start center'>
                <Icon css='IconMedium' image='cancelRed'/>
                <Div w={180}>Zrušiť ostatné termíny a z posledného sa idem odhlásiť ručne</Div>
            </Div>,
            cancel: 'Ostávam'
        }
    }),

    // chce chodit ale bol by pravidelny silnejsi hrac
    AutomaticConfirmStrong: iceDesc => ({
        vertical: true,
        h: 200,
        wOk: 240,
        wCancel: 160,
        text: {
            info: <span><b>{iceDesc}</b> - Je to nižšia úroveň ako si ty, budeš všade iba ako náhradník</span>,
            ok: <Div setup='r start center'>
                <Icon css='IconMedium' image='okYellow'/>
                <Div w={180}>OK, nevadí mi to. Budem náhradník</Div>
            </Div>,
            cancel: 'Nejdem'
        }
    }),

// administracia hracov
    PlayerBlocked:         'BLOKOVANÝ',
    PlayerBlockedSym:      '!',

    PlayerNonPlayer:       'nehrajúci',
    PlayerNonPlayerSym:    'N',

    PlayerDidntRead:       'dával chuja',
    PlayerDidntReadSym:    'ch',

    PlayerProblem:         'problém',
    PlayerProblemSym:      '!',

    PlayerNoLevel:         'bez úrovne',
    PlayerNoLevelSym:      '-',

    PlayerVip:             'VIP',
    PlayerVipSym:          'VIP',

    PlayerAdminNoteSym:    '✎'
}
