import React from 'react';
import format from 'string-template';

import TelNumber from './telNumber';
import TXT from '../text';
import { AppConst } from '../../const';
import * as DateTime from '../../utils/dateTime';

export function switchStatus(on, maybe, warning, tooStrong) {
    const click = 'clickable ';

    if(warning) {
        return {
            class: click + 'bgWarn',
            text: '!',
            textClass: 'fgWarn',
            statusImage: 'infoRed',
            info: () => switchStatusInfo('warning')
        }
    } else {
        if(on) {
            if(maybe || tooStrong) {
                return {
                    class: click + 'bgMaybe',
                    text: '?',
                    textClass: 'fgMaybe',
                    statusImage: 'infoYellow',
                    info: () => switchStatusInfo(tooStrong ? 'tooStrong' : 'backups')
                }
            } else {
                return {
                    class: click + 'bgOn',
                    icon: 'okGreen'
                }
            }
        } else {
            return {
                class: click + 'bgOff',
                icon: 'cancelLight'
            }
        }
    }
}

export function switchStatusInfo(flag) {
    switch(flag) {
        case 'warning': return  TXT.GameInfoWarning;
        case 'backups': return  TXT.GameInfoBackup;
        case 'tooStrong': return  TXT.GameInfoStrong;
        default: return null;
    }
}

export function gameStatus(playerFlags, gameFlags) {
    const playerPos = playerFlags.playerPos + 1;

    if(gameFlags.isPast) {
        if(playerFlags.going && !playerFlags.warning && !playerFlags.backups) {
            return {
                fg: 'fgOff',
                bg: 'fgOff',
                fg2: 'fgWhite',
                bg2: 'bgDark',
                msg: TXT.GamePastGoing
            };
        } else {
            return {
                fg: 'fgOff',
                bg: 'fgOff',
                fg2: 'fgMedium',
                bg2: 'bgWhite',
                msg: playerFlags.isNonPlayer ? TXT.GamePastNonPlayer : TXT.GamePastNotGoing
            };
        }
    }

    if(gameFlags.userTooStrong) {
        return {
            fg: 'fgWarn',
            bg: 'fgWarn',
            fg2: 'fgBlack',
            bg2: 'fgWarn',
            msg: TXT.GameUserTooStrong
        }
    } else {
        if(playerFlags.tooStrong) {
            return {
                fg: 'fgMaybe',
                bg: 'fgMaybe',
                fg2: 'fgBlack',
                bg2: 'bgMaybe',
                msg: TXT.GameStrong
            };
        } else {
            if(playerFlags.backups) {
                return {
                    fg: 'fgMaybe',
                    bg: 'fgMaybe',
                    fg2: 'fgBlack',
                    bg2: 'bgMaybe',
                    msg: TXT.GameBackup(playerPos)
                };
            } else {
                if(playerFlags.going) {
                    if(gameFlags.isLocked) {
                        return {
                            fg: 'fgOn',
                            bg: 'fgOn',
                            fg2: 'fgWhite',
                            bg2: 'bgOn',
                            msg: format(TXT.GameGoingLocked, { playerPos })
                        };
                    } else {
                        return {
                            fg: 'fgOn',
                            bg: 'fgOn',
                            fg2: 'fgWhite',
                            bg2: 'bgOn',
                            msg: format(TXT.GameGoing, { playerPos })
                        };
                    }
                } else {
                    if(playerFlags.warning) {
                        return {
                            fg: 'fgWarn',
                            bg: 'fgWarn',
                            fg2: 'fgWhite',
                            bg2: 'bgWarn',
                            msg: TXT.GameWarning
                        };
                    } else {
                        return {
                            fg: 'fgOff',
                            bg: 'fgOff',
                            fg2: 'fgBlack',
                            bg2: 'bgWhite',
                            msg: null
                        };
                    }
                }
            }
        }
    }
}

export function confirmGameToggle(game, player) {
    const flags = game.userFlags;
    const going = !flags.going;
    const gameFlags = game.gameFlags;

    const gameLvl = AppConst.SkillLevelByKey[ game.ice.lvl ];
    const playerLvl = AppConst.SkillLevelByKey[ player.skillLevel ];
    const isTooStrong = player.skillLevel !== null
                        && playerLvl.value - gameLvl.value >= AppConst.PlayerStrongDifference;
    const wantsToGoBack = flags.cancelled && going;

    const day = DateTime.titleDayOfWeek(game.gameDate.getDay(), true, true);
    const gameDesc = `${day} ${DateTime.titleDate(game.gameDateOverride || game.gameDate)} - ${DateTime.titleTime(game.gameDateOverride || game.gameDate)}`;

    if(isTooStrong && going) {
        if(gameFlags.isToday && wantsToGoBack) {
            return flags.warning
                    ? TXT.GameConfirmWantsBackWarning(gameDesc, <TelNumber tel={AppConst.ContactCharvat.Tel} />)
                    : TXT.GameConfirmWantsBack(gameDesc, <TelNumber tel={AppConst.ContactCharvat.Tel} />);
        } else {
            return TXT.GameConfirmStrong(gameDesc);
        }
    } else {
        if(gameFlags.isLocked) {
            if(gameFlags.isToday && wantsToGoBack) {
                return flags.warning
                    ? TXT.GameConfirmWantsBackWarning(gameDesc, <TelNumber tel={AppConst.ContactCharvat.Tel} />)
                    : TXT.GameConfirmWantsBack(gameDesc, <TelNumber tel={AppConst.ContactCharvat.Tel} />);
            } else {
                if(going) {
                    if(!gameFlags.isFull) {
                        return TXT.GameConfirmGoingLocked(gameDesc);
                    }
                } else {
                    if(flags.players) {
                        return TXT.GameConfirmNotGoingLocked(gameDesc);
                    }
                }
            }
        }
    }

    const playerCount = game.players.length + game.backups.length;
    const isAboveLimit = playerCount >= AppConst.PlayerLimit;

    if(isAboveLimit && going) {
        return TXT.GameConfirmBackup(gameDesc, playerCount, gameFlags.isLocked);
    }

    if(going) {
        return TXT.GameConfirmGoing(gameDesc);
    } else {
        return TXT.GameConfirmLeave(gameDesc);
    }
}

export function confirmWeeklyGameToggle(game, isTooStrong, getLockedGameByIce) {
    const confirmArr = [ ];

    const day = DateTime.titleDayOfWeek(game.gameDate.getDay(), true, false);
    const iceDesc = `${day} ${DateTime.titleTime(game.gameDate)}`;

    const going = !game.userFlags.going;

    if(going) {
        confirmArr.push(
            TXT.AutomaticConfirmGoing(iceDesc)
        );
    } else {
        confirmArr.push(
            TXT.AutomaticConfirmNotGoing(iceDesc)
        );
    }

    if(isTooStrong && going) {
        confirmArr.push(
            TXT.AutomaticConfirmStrong(iceDesc)
        );
    } else {
        const lockedGame = getLockedGameByIce(game.ice.date, game.ice.rink);
        if(lockedGame) {
            const playerFlags = lockedGame.userFlags;
            const dateDesc = lockedGame.gameDate.getDate() + '.' + (lockedGame.gameDate.getMonth()+1);
            const cannotGoBack = going && playerFlags.cancelled && lockedGame.gameFlags.isToday;

            if(going) {
                if(!playerFlags.players && (!lockedGame.gameFlags.isFull || playerFlags.cancelled)) {
                    if(cannotGoBack) {
                        confirmArr.push(
                            TXT.AutomaticConfirmGoingCancelled(dateDesc)
                        );
                    } else {
                        if(lockedGame.gameFlags.isToday) {
                            confirmArr.push(
                                TXT.AutomaticConfirmGoingToday(dateDesc)
                            );
                        } else {
                            confirmArr.push(
                                TXT.AutomaticConfirmGoingLocked(dateDesc)
                            );
                        }
                    }
                }
            } else {
                if(playerFlags.players) {
                    confirmArr.push(
                        TXT.AutomaticConfirmNotGoingLocked(dateDesc)
                    );
                }
            }
        }
    }

    return confirmArr;
}

export function playerStatus(player) {
    if(player.isDisabled) {
        return {
            text: TXT.PlayerBlocked,
            symbol: TXT.PlayerBlockedSym,
            class: 'fgBad'
        };
    }
    
    if(player.isNonPlayer) {
        return {
            text: TXT.PlayerNonPlayer,
            symbol: TXT.PlayerNonPlayerSym
        };
    }

    const txt = [];
    const sym = [];
    let clss = '';

    if(player.isProblem) {
        txt.push(TXT.PlayerProblem);
        clss = 'fgBad';
    }

    if(player.hadDidntRead) {
        txt.push(TXT.PlayerDidntRead);
        sym.push(TXT.PlayerDidntReadSym);
        clss = 'fgBad';
    }

    if(player.hasNoLevel) {
        txt.push(TXT.PlayerNoLevel);
    }

    if(player.vip) {
        txt.push(TXT.PlayerVip);
        sym.push(TXT.PlayerVipSym);
    }

    if(player.adminNote) {
        sym.push(TXT.PlayerAdminNoteSym)
    }

    return {
        text: txt.join(', '),
        symbol: sym.join(''),
        class: clss
    };
}
