import io from "socket.io-client";
import dbg from 'debug';

import { log } from './logger';
import * as json from '../../utils/json';
import { ApiConst, isProduction } from '../../const';

const debug = dbg('sockjs');

export default function WsSockjs() {
    const url = getUrl(global.window.location);
    
    const socket = io(
        url, {
            reconnection: ApiConst.Reconnection,
            autoConnect: ApiConst.Reconnection,
            timeout: ApiConst.Timeout,
        });

    socket.on(
        'connect',
        () => {
            log(debug, 'connected');
            send();
        }
    );

    socket.on(
        'message',
        msg => {
            let parsed = null;
            try {
                parsed = json.parse(msg);
            } catch (e) {
                debug(e);
            }

            if(parsed) {                
                if(onData !== null) {
                    onData(parsed);
                    onData = null;
                } else {
                    received.push(parsed);
                }
            }
        }
    );
    socket.on('disconnect', () => log(debug, 'disconnected'));
    socket.on('error', () => error());
    socket.on('connect_error', () => error());

    const waits = [ ];
    const received = [ ];

    let onData = null;
    let onError = null;

    connect();

    function connect() {
        log(debug, 'connecting to %s', url);
        socket.connect();
    }

    function send() {
        if(socket.connected) {
            while(waits.length > 0) {
                const data = waits.shift();
                log(debug, 'sending %O', data);
                socket.send(json.stringify(data));
            }
        }
    }

    function error() {
        waits.length = 0;
        onError();
    }

    this.send = function(data) {
        waits.push(data);

        if(socket.disconnected) {
            connect();
        } else {
            send();
        }
    }

    this.receive = function() {
        return new Promise(
            (resolve, reject) => {
                if(received.length > 0) {
                    resolve(received.shift());
                } else {
                    onData = resolve;
                }
            }
        );
    }

    this.error = function() {
        return new Promise(
            (resolve, reject) => {
                onError = resolve;
            }
        );
    }
}

function getUrl(loc) {
    if(isProduction()) {
        return `${loc.protocol}//${loc.host}`;
    } else {
        return 'https://localhost:3002';
    }
}
