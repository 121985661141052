import React from 'react';
import { connect } from 'react-redux';

/* Base views */
import Login from './login';
import Registration from './registration';
import Content from './content';
import NonPlayerContent from './nonPlayerContent';
import UserError from './userError';
import Sending from './sending';
import Reconnect from './reconnect';

/* Menu views */
import UserProfile from './userProfile';
import CharvatContact from './charvatContact';

/* Content views */
import IceList from './ice/iceList';
import IceDetail from './ice/iceDetail';
import IceWeeklyPlayers from './ice/iceWeeklyPlayers';
import Players from './player/players';
import PlayerList from './player/playerList';
import PlayerDetail from './player/playerDetail';
import PlayerDetailEdit from './player/playerDetailEdit';
import PlayerDetailGames from './player/playerDetailGames';
import PlayerEmails from './player/playerEmails';
import SkillLevelInfo from './skillLevelInfo';
import GameDetail from './game/gameDetail';
import GameDetailAdmin from './game/gameDetailAdmin';
import PlayerContacts from './playerContacts';

import ModalUkraine from './modalUkraine';

import * as Actions from '../../actions';
import clone from '../../../utils/clone';

const VIEW = Actions.VIEW;

class View extends React.Component {
    render() {
        const props = this.props;
        const view = clone(props.view);

        const player = props.player;
        const isAdmin = player ? player.isAdmin : null;
        const isNonPlayer = player ? player.isNonPlayer : null;

        const viewType =
            isNonPlayer && view.type === VIEW.Content
                ? VIEW.NonPlayerContent
                : ( // player can be shown admin version of game detail(limied), if he is a helper in the game
                    view.type === VIEW.GameDetail || view.type === VIEW.GameDetailAdmin
                        ? chooseGameDetailView(isAdmin, view.params.gameKey, props.getGame)
                        : props.view.type
                );

        switch(viewType) {
            /* Base */
            case VIEW.Error:
                return <UserError params={view.params} />

            case VIEW.Login:
                return <Login />;

            case VIEW.Registration:
                return <Scroll key={VIEW.Registration} to={view.scrollPos}>
                    <Registration params={view.params} />
                </Scroll>

            case VIEW.Sending:
                return <Sending params={view.params} />

            case VIEW.Reconnect:
                return <Reconnect params={view.params} />

            case VIEW.Content:
                return <Scroll key={VIEW.Content} to={view.scrollPos}>
                    <Content params={view.params} viewContext={view.context} />
                </Scroll>

            case VIEW.NonPlayerContent:
                return <Scroll key={VIEW.NonPlayerContent} to={view.scrollPos}>
                    <NonPlayerContent params={view.params} viewContext={view.context} />
                </Scroll>

            /* Menu */
            case VIEW.UserProfile:
                return <UserProfile params={view.params} />

            case VIEW.CharvatContact:
                return <CharvatContact params={view.params} />

            /* Content - admin */
            case VIEW.IceList:
                return <Scroll key={VIEW.IceList} to={view.scrollPos}>
                    <IceList params={view.params} />
                </Scroll>

            case VIEW.IceDetail:
                return <Scroll key={VIEW.IceDetail} to={view.scrollPos}>
                    <IceDetail params={view.params} />
                </Scroll>
                
            case VIEW.IceWeeklyPlayers:
                return <Scroll key={VIEW.IceWeeklyPlayers} to={view.scrollPos}>
                    <IceWeeklyPlayers params={view.params} viewContext={view.context} />
                </Scroll>

            case VIEW.Players:
                return <Scroll key={VIEW.Players} to={view.scrollPos}>
                    <Players params={view.params} viewContext={view.context} />
                </Scroll>

            case VIEW.PlayerList:
                return <Scroll key={VIEW.PlayerList} to={view.scrollPos}>
                    <PlayerList params={view.params} />
                </Scroll>

            case VIEW.PlayerDetail:
                return <Scroll key={VIEW.PlayerDetail} to={view.scrollPos}>
                    <PlayerDetail params={view.params} />
                </Scroll>
                
            case VIEW.PlayerDetailEdit:
                return <Scroll key={VIEW.PlayerDetailEdit} to={view.scrollPos}>
                    <PlayerDetailEdit params={view.params} />
                </Scroll>
                
            case VIEW.PlayerDetailGames:
                return <Scroll key={VIEW.PlayerDetailGames} to={view.scrollPos}>
                    <PlayerDetailGames params={view.params} />
                </Scroll>
                
            case VIEW.PlayerEmails:
                return <Scroll key={VIEW.PlayerEmails} to={view.scrollPos}>
                    <PlayerEmails mailingList={view.params ? view.params.mailingList : false} />
                </Scroll>
                
            case VIEW.SkillLevelInfo:
                return <Scroll key={VIEW.SkillLevelInfo} to={view.scrollPos}>
                    <SkillLevelInfo params={view.params} />
                </Scroll>

            /* Content */
            case VIEW.GameDetail: return <Scroll key={VIEW.GameDetail} to={view.scrollPos}>
                    <GameDetail params={view.params} />
                </Scroll>

            case VIEW.GameDetailAdmin: return <Scroll key={VIEW.GameDetailAdmin} to={view.scrollPos}>
                    <GameDetailAdmin params={view.params} viewContext={view.context} />
                </Scroll>

            case VIEW.PlayerContacts: return <Scroll key={VIEW.PlayerContacts} to={view.scrollPos}>
                    <PlayerContacts params={view.params} viewContext={view.context} />
                </Scroll>

            case VIEW.ModalUkraine: return <Scroll key={VIEW.ModalUkraine} to={view.scrollPos}>
                    <ModalUkraine />
                </Scroll>

            default:
                return null;
        }
    }
}

const mapStateToProps = state => ({
    player: state.user ? state.user.player : null,
    getGame: gameKey => state.get.gameByKey(gameKey.iceDate, gameKey.iceRink, gameKey.gameDate)
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(View)


class Scroll extends React.Component {
    componentDidMount() {
        const to = this.props.to;
        if(to !== undefined) {
            window.scrollTo(0, to);
        } else {
            window.scrollTo(0, 0);
        }
    }

    render() {
        return this.props.children;
    }
}

function chooseGameDetailView(isAdmin, game, getGame) {
    const currentGame = getGame(game);
    return  isAdmin || currentGame.userFlags.isHelper
                ? Actions.VIEW.GameDetailAdmin
                : Actions.VIEW.GameDetail;
}
