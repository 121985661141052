import React from 'react';

import PlayerListContainer from '../playerListContainer';
import Div from '../../../../div';

export default ({ game }) => 
    <React.Fragment>
        <Div setup='r between center' h='50' wRel='100' class='fgDark fBigger'>
            <Div>platil</Div>
            <Div class='fUnderline'>Šatňa</Div>
            <Div>dres</Div>
        </Div>
        <PlayerListContainer game={game} playerList={game.players} mode='mark' />
    </React.Fragment>
