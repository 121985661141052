import React from 'react';

import Div from '../../../../div';

export default ({ game, playerId, actions }) => {
    const mark = game.mark;
    const hasPaid = mark.paid[ playerId ] === true;
    const name = playerId.slice(playerId.indexOf(':') + 1);

    const h = 45;
    const s = 42;

    return <Div setup='r between center' wRel='100' h={h}>
        <Div setup='r center center' fx={s} h={s} onClick={() => markPaid(game, playerId, name, actions)}
             class={'cypressGameMark fMega clickable ' + (hasPaid ? 'bgOn' : 'bgOff')}>
             { hasPaid
                 ? <Div>€</Div>
                 : null
             }
        </Div>
        <Div w='8' />
        
        <Div setup='c start start' wRel='100'>
            <Div wRel='100' style={{ textAlign: 'center'}}>{name}</Div>
            <Div wRel='100' class='fSmall' style={{ textAlign: 'center', fontStyle: 'italic' }}>(nereg.)</Div>
        </Div>

        <Div setup='r start center'>
            <Div h={s} w={s} onClick={() => markDark(game, playerId, false, actions)}
                 class={'cypressGameMark clickable ' + (mark.color[ playerId ] === false ? 'bgMarkLight bordSilver' : 'bgLight')} />
            <Div w='8' />

            <Div h={s} w={s} onClick={() => markDark(game, playerId, true, actions)}
                 class={'cypressGameMark clickable ' + (mark.color[ playerId ] === true ? 'bgMarkDark' : 'bgLight')} />
        </Div>
    </Div>
}

function markPaid(game, playerId, playerName, actions) {
    let newValue = game.mark.paid[ playerId ] === true ? null : true;
    actions.markPlayerPaid(game, playerId, playerName, newValue);
}

function markDark(game, playerId, isDark, actions) {
    const currentValue = game.mark.color[ playerId ];
    let newValue = isDark;
    if(currentValue === isDark) {
        newValue = null;
    }

    actions.markPlayerDark(game, playerId, newValue);
}
