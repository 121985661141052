import React from 'react';
import { connect } from 'react-redux';

import User from './user';
import WindowEvents from './windowEvents';
import { SwipeRightEvent, PageReloadEvent } from '../../events';
import MenuContainer from './menuContainer';
import BannerUkraine from './banner-ukraine';

import View from '../view';
import Div from '../div';
import LastAction from '../lastAction';
import ModalConfirm from '../modal/modalConfirm';
import ErrorBoundary from '../errorBoundary';

import * as Actions from '../../actions';
import * as Requests from '../../requests';
import { AppConst, ApiConst,  isProduction } from '../../../const';
import Delay from '../../../utils/delay';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onBeforeUnload = this.onBeforeUnload.bind(this);
        this.onReload = this.onReload.bind(this);
        this.onError = this.onError.bind(this);

        this.windowEvents = new WindowEvents({
            onFocus: this.onFocus,
            onBlur: this.onBlur,
            onBeforeUnload: this.onBeforeUnload,
            onEscapePressed: SwipeRightEvent.emit,
            onError: this.onError
        });
        
        this.syncDelay = new Delay(ApiConst.SyncDelay);
    }

    componentDidMount() {
        this.windowEvents.register();
        PageReloadEvent.register(this.onReload);
    }

    componentWillUnmount() {
        this.windowEvents.unregister();
        PageReloadEvent.unregister(this.onReload);

        this.syncDelay.cancel();
    }

    onFocus() {
        const props = this.props;
        
        if(props.isLoginView) {
            props.init();
        } else {
            if(isProduction()) {
                this.syncDelay.start(
                    () => {
                        if( !this.props.isReconnectView
                            && (props.isContentView || props.isRegView)
                        ) {
                            props.requestSync();
                        }
                    }
                );
            }
        }
    }

    onBlur() {
        this.syncDelay.cancel();
    }

    onBeforeUnload(event) {
        this.syncDelay.cancel();
        
        const props = this.props;
        if(isProduction()) {
            if(!props.isRegView && !props.isContentContactView && !props.isLoginView && props.unloadEventEnabled) {
                const msg = `Naozaj odísť z ${AppConst.Domain}?`;
                event.returnValue = msg;
                return msg;
            }
        }
    }

    onReload() {
        this.windowEvents.unregister();
        this.syncDelay.cancel();
    }

    onError(error, info) {
        const stack = error && error.stack ? error.stack : error.message;
        const componentStack = info ? info.componentStack : '';
        this.props.logError(stack, componentStack);
    }

    render() {
        const notify = this.props.notify[0];

        if(this.props.view) {
            return this.props.isContentView
                ? <ErrorBoundary onError={this.onError}>
                    <React.Fragment>
                        { global.window.Cypress ? <LastAction action={this.props.lastAction} /> : null }
                        <Div setup='r center center' wRel='100'>
                            <Div class='container' style={{ position: 'relative' }}>
                                <MenuContainer />
                                { this.props.view.type !== Actions.VIEW.ModalUkraine
                                    ? <React.Fragment>
                                        <BannerUkraine />
                                        <User player={this.props.player} />
                                    </React.Fragment>
                                    : null
                                }
                                
                                <View view={this.props.view} />
                            </Div>
                        </Div>
                        { notify
                            ? <ModalConfirm text={notify.text} textOk={notify.textOk} isOpen={true} h={notify.h}
                                            onOk={this.props.popNotify} canIgnore={false} />
                            : null
                        }
                    </React.Fragment>
                </ErrorBoundary>
                : <ErrorBoundary onError={this.onError}>
                    <React.Fragment>
                        { global.window.Cypress ? <LastAction action={this.props.lastAction} /> : null }
                        <View view={this.props.view} />
                    </React.Fragment>
                </ErrorBoundary>
        } else {
            return null;
        }
    }
}

const mapStateToProps = state => ({
    view: state.get.view.topView(),
    player: state.user.player,
    isContentView: state.get.view.isBaseContent(),
    isContentContactView: state.get.view.isContentContact(),
    isLoginView: state.get.view.isBaseLogin(),
    isRegView: state.get.view.isBaseRegView(),
    isReconnectView: state.get.view.isReconnect(),
    notify: state.notify,
    lastAction: state.lastAction,
    unloadEventEnabled: state.globalParams[ Actions.GLOBAL_PARAM.UnloadEventEnabled ]
})

const mapDispatchToProps = dispatch => ({
    init: () => dispatch(Actions.init()),
    logout: () => dispatch(Actions.logout()),
    showSendingView: () => dispatch(Actions.connection(Actions.CONNECTION.Sending)),
    popNotify: () => dispatch(Actions.popNotify()),
    requestSync: () => dispatch(Requests.sync()),
    logError: (message, info) => dispatch(Requests.logError(message, info))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App)
