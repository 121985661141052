import { TYPE } from '../actions';
import clone from '../../utils/clone';
import { DataEvent } from '../../const';

export default function version(state, action) {
    if(state === undefined) {
        return {
            version: DataEvent.CurrentVersion,
            timestamp: null
        };
    }

    const ts = state.timestamp;
    
    switch(action.type) {
        case TYPE.Init:
            return {
                version: DataEvent.CurrentVersion,
                timestamp: null
            };

        case TYPE.Received:
            return {
                version: action.data.ver,
                timestamp: !ts || ts < action.data.ts
                    ? new Date(action.data.ts.getTime())
                    : new Date(ts.getTime())
            };
            
        default:
            return clone(state);
    }
}
