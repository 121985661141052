import React from 'react';
import { connect } from 'react-redux';

import RegistrationForm from './registrationForm';
import TelNumber from '../../telNumber';
import Div from '../../div';
import Button from '../../button';
import ModalInfo from '../../modal/modalInfo';
import * as Requests from '../../../requests';
import { AppConst } from '../../../../const';

const Charvat = AppConst.ContactCharvat;

class RegistrationFormView extends React.Component {
    componentDidMount() {
        this.startDate = new Date();
    }

    onSubmit(formResult) {
        if(this.startDate) {
            const endDate = new Date();
            formResult.reg.elapsed = Math.floor((endDate.getTime() - this.startDate.getTime()) / 1000);
            formResult.reg.date = endDate;
        }
        
        delete formResult.reg.emailNotifyDummy;

        this.props.onSubmit(formResult);
    }

    render() {
        return <React.Fragment>
            <Div setup='c center center' wRel='100'>
                <Div setup='c around center' class='container padSmall bordFatBlack' style={{ margin: '2px' }}>
                    <Div>Nemám čas na registráciu, prosím o zrýchlenú registráciu:</Div>
                    <Div setup='r center center' h={80}>
                        <Button text='Zrýchlená registrácia' w={240} onClick={() => this.refs.quickRegModal.onOpen()}
                                controlled={true} on={false} />
                    </Div>
                    <ModalInfo noImage='true' ref='quickRegModal'>
                        <Div class='fMedium'>
                            <Div setup='c around center' h={80}>
                                <Div>V tom prípade volaj:</Div>
                                <Div>
                                    <b><TelNumber tel={Charvat.Tel}/></b>
                                </Div>
                            </Div>
                            <p>Jednorázovo ťa nahlásim ručne bez registrácie. Ak chceš prísť aj do budúcna znovu, nájdi si prosím čas na registráciu.</p>
                        </Div>
                    </ModalInfo>
                </Div>
                <RegistrationForm player={this.props.player} onSubmit={this.onSubmit.bind(this)} />
            </Div>
        </React.Fragment>
    }
}

const mapStateToProps = state => ({
    player: state.user.player
});

const mapDispatchToProps = dispatch => ({
    onSubmit: player => dispatch(
        Requests.updatePlayer(player, true)
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationFormView)
