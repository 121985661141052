import React from 'react';
import { connect } from 'react-redux';

import ProfileForm from './profileForm';
import PlayerItem from '../playerItemComponent';
import Div from '../../../div';
import * as Actions from '../../../../actions';
import * as Requests from '../../../../requests';

const PlayerDetailEditView = ({ params, getPlayer, updatePlayerAdmin, closeView }) => {
    const player = getPlayer(params.playerId);

    return <Div css='View' hRel='100' wRel='100' setup='c start start' id='cypress.content.playerDetailEdit'>
        <PlayerItem player={player} />
        <ProfileForm player={player} onCancel={closeView} onSubmit={updatePlayerAdmin} />
    </Div>
}

const mapStateToProps = state => ({
    getPlayer: playerId => state.get.playerById(playerId)
});

const mapDispatchToProps = dispatch => ({
    updatePlayerAdmin: (playerId, playerData, imageData) => {
        dispatch(Actions.popView());
        dispatch(Requests.updatePlayerByAdmin(playerId, playerData, imageData));
    },

    closeView: () => dispatch(Actions.popView())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerDetailEditView)
