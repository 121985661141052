import * as Compare from './compare';
import * as Actions from '../actions';
import { DataEvent } from '../../const';
import clone from '../../utils/clone';
import * as DateTime from '../../utils/dateTime';
import { AppConst, isPlayerTooStrong } from '../../const';

const MILLIS_PER_HOUR = 60 * 60 * 1000;

export function dataPlayer(data) {
    return data.player.player;
}

export function isDataPlayer(data) {
    return data.type === DataEvent.Player.Player
           && ( data.player
                && data.player.type === DataEvent.Player.Player
                && data.player.player
           );
}

export function isDataCurrentPlayer(data, userId) {
    if(isDataPlayer(data)) {
        return data.player.player.id === userId;
    } else {
        return false;
    }
}

export function Getters(state) {
    this.setState = function(s) {
        state = s;
    }

    this.view = {
        topView: (includingReconnect = true) => {
            const view = state.view;
            const stackTop = view.stack.length > 0 ? view.stack[ view.stack.length - 1 ] : null;
            return view.connection || stackTop;
        },

        isBaseContent: () => {
            const view = state.view;
            const baseView = view.stack.length > 0 ? view.stack[0] : null;
            return baseView && baseView.type === Actions.VIEW.Content;
        },

        isBaseLogin: () => {
            const view = state.view;
            const baseView = view.stack.length > 0 ? view.stack[0] : null;
            return baseView && baseView.type === Actions.VIEW.Login;
        },

        isBaseRegView: () => {
            const view = state.view;
            const baseView = view.stack.length > 0 ? view.stack[0] : null;
            return baseView && baseView.type === Actions.VIEW.Registration;
        },

        isReconnect: () => {
            const currentView = this.view.topView();
            return currentView
                   && (currentView.type === Actions.VIEW.Reconnect
                       || currentView.type === Actions.VIEW.Sending
                   );
        },

        isContentContact: () => {
            const currentView = this.view.topView();
            return currentView && currentView.type === Actions.VIEW.CharvatContact;
        }
    }

    this.ices = function() {
        const prepare = prepareIce.bind(null, state.data.players);

        return clone(state.data.ices)
                   .filter(ice => ice.deleted !== true)
                   .sort(Compare.ices)
                   .map(prepare);
    }

    this.iceByKey = function(date, rink) {
        const found = state.data.ices.find(ice => ice.date.getTime() === date.getTime() && ice.rink === rink);
        return found ? prepareIce(state.data.players, clone(found)) : null;
    }

    this.weeklyIces = function(onlyFilter = false) {
        const result = [ ];
        
        const userPlayer = state.user && state.user.player ? state.user.player : null;
        const playerId = userPlayer.id;
        const userSkill = userPlayer.skillLevel;
        const userIsAdmin = userPlayer.isAdmin;
        const userIsVip = userPlayer.vip;

        let ices = state.data.ices;

        if(ices) {
            const gameFilter = getGameFilterState(state);
            if(gameFilter) {                    
                if(gameFilter.rinkGroup.length > 0) {
                    const rinks = rinkGroupToRinks(gameFilter.rinkGroup);
                    ices = ices.filter(ice => rinks.indexOf(ice.rink) !== -1);
                }

                if(gameFilter.lvl.length > 0) {
                    ices = ices.filter(ice => gameFilter.lvl.indexOf(ice.lvl) !== -1);
                }
            }

            ices.forEach(ice => {
                if( ice.weekly
                    && !ice.deleted
                    && (!isPlayerTooStrong(userSkill, ice.lvl) || userIsAdmin)
                    && (
                        !ice.vip
                        || (
                            ice.vip && (userIsVip || userIsAdmin)
                        )
                    )
                ) {
                    if(onlyFilter) {
                        result.push(
                            prepareIce(state.data.players, clone(ice))
                        );
                    } else {
                        result.push({
                            ice: prepareIce(state.data.players, clone(ice)),
                            gameDate: ice.startDt,
                            userFlags: {
                                going: ice.autoJoin[ playerId ]
                            },
                            gameFlags: {
                                isCancelled: false
                            }
                        });
                    }
                }
            });

            result.sort(
                (wi1, wi2) => Compare.ices(wi1.ice || wi1, wi2.ice || wi2)
            );

            return result;
        } else {
            return [];
        }
    }

    this.playerById = function(id, addGames = false) {
        let result = state.data.players ? state.data.players[ id ] : { id };
        if(result) {
            result = clone(result);
            if(addGames) {
                result.games = this.gamesForPlayer(id)
            }
        }
        return result;
    }

    this.players = function(state) {
        const byId = { };
        state.data.players.forEach(player => byId[ player.id ] = player);
        byId[ state.user.player.id ] = state.user.player;
        return byId;
    }

    this.playersByLetter = function(letter, showHidden = false) {
        return this.playerGrid(showHidden).players[ letter ].list;
    }

    function forEachRegisteredPlayer(func, includeDisabled = false, idFilter = null) {
        Object.keys(state.data.players).forEach(
            id => {
                if(!idFilter || idFilter(id)) {
                    const player = state.data.players[ id ];
                    if(player.isReg
                        && (
                            includeDisabled
                            || !player.isDisabled
                        )
                        && player.reg.didntRead !== true
                    ) {
                        func(player);
                    }
                }
            }
        )
    }

    this.playerList = function(playerIdFilter = null) {
        const result = [];

        forEachRegisteredPlayer(player => result.push(player), false, playerIdFilter);
        result.sort(Compare.players);

        return result;
    }

    this.playerListsByStatus = function(playerIdFilter = null) {
        const result = {
            verify: [ ],
            hasNoLevel: [ ],
            isDisabled: [ ]
        }

        forEachRegisteredPlayer(
            player => {
                if(player.isNonPlayer) {
                    return;
                }

                if(player.isDisabled) {
                    result.isDisabled.push(player);
                } else {
                    if(player.isReg) {
                        if(player.verify) {
                            result.verify.push(player);
                        } else {
                            if(player.hasNoLevel) {
                                result.hasNoLevel.push(player);
                            }
                        }
                    }
                }
            },
            true,
            playerIdFilter
        );

        result.verify.sort(Compare.players);
        result.hasNoLevel.sort(Compare.players);
        result.isDisabled.sort(Compare.players);

        return result;
    }

    this.playerEmails = function(params) {
        const result = [ ];
        
        forEachRegisteredPlayer(pl => {
            if(pl.info && pl.info.email && pl.reg) {
                const mail = pl.info.email.trim();
                if(mail.length > 0) {
                    if(
                        (!pl.isNonPlayer || params.nonPlayers === true)
                        && (pl.reg.ruleMailinglist || params.mailingList === false)
                        && (params.allSkillLevels === true ||
                           (
                                (pl.skillLevel === null && params.skillLevel.none === true)
                                || (pl.skillLevel === 'ľ' && params.skillLevel.l === true)
                                || (pl.skillLevel === 'ľs' && params.skillLevel.ls === true)
                                || (pl.skillLevel === 's' && params.skillLevel.s === true)
                            )
                        )
                    ) {
                        result.push(mail);
                    }
                }
            }
        });

        return result;
    }

    this.gameByKey = function(iceDate, iceRink, gameDate, playerId) {
        const now = state.time.now;
        const game = state.data.games[ iceDate.getTime().toString() + iceRink + gameDate.getTime() ];
        if(game) {
            prepareGame(game, state.user.player.id, state.data.players, state.user, now);
        }

        return game;
    }

    function filterGames(includeDeleted = false, includePastGames = false) {
        const now = state.time.now;
        const result = [ ];

        const userPlayer = state.user && state.user.player ? state.user.player : {};
        const userIsVip = userPlayer.vip;
        const userIsAdmin = userPlayer.isAdmin;

        let games = state.data.games;

        const gameFilter = getGameFilterState(state);
        if(gameFilter) {                    
            const src = games;
            let gameKeys = Object.keys(src || { });

            if(gameFilter.rinkGroup.length > 0) {
                const rinks = rinkGroupToRinks(gameFilter.rinkGroup);
                gameKeys = gameKeys.filter(gameKey => {
                    const game = src[ gameKey ];
                    return rinks.indexOf(game.ice.rink) !== -1;
                });
            }

            if(gameFilter.lvl.length > 0) {
                gameKeys = gameKeys.filter(gameKey => {
                    const game = src[ gameKey ];
                    return gameFilter.lvl.indexOf(game.ice.lvl) !== -1;
                });
            }

            games = gameKeys.map(gameKey => src[ gameKey ]);
        }

        for(let gameKey in games) {
            const game = games[ gameKey ];

            if((includeDeleted || !game.gameFlags.isDeleted)
               && (includePastGames || !game.gameFlags.isPast || isVisiblePastGame(game, now))
            ) {
                result.push(game);
            }
        }

        return result.filter(
            game => !game.ice.vip
                    || (
                        game.ice.vip
                        && (
                            userIsVip || userIsAdmin
                        )
                    )
        );
    }

    this.lockedGameByIce = function(iceDate, iceRink) {
        const now = state.time.now;

        const found = filterGames().find(
            g => g.ice.date.getTime() === iceDate.getTime()
                && g.ice.rink === iceRink
                && g.gameFlags.isLocked
        );

        const userId = state.user.player.id;
        return found
            ? prepareGame(clone(found), userId, state.data.players, state.user, now)
            : null;
    }

    this.futureGamesSplitByWeek = function() {
        const now = state.time.now;
        const result = [ ];
        const resultByWeek = { };
        const userId = state.user.player.id;
        const games = filterGames();

        games.forEach(
            game => {
                const weekNum = DateTime.isoWeekNum(game.gameDate);

                let weekGames = resultByWeek[ weekNum ];
                if(!weekGames) {
                    weekGames = {
                        year: DateTime.isoYear(game.gameDate),
                        week: weekNum,
                        games: [ ]
                    };
                    result.push(weekGames);
                    resultByWeek[ weekNum ] = weekGames;
                }

                weekGames.games.push(prepareGame(clone(game), userId, state.data.players, state.user, now));
            }
        );

        result.sort(Compare.weekGames);
        result.forEach(week => week.games.sort(Compare.games));
        
        return result;
    }

    this.nonPlayerGames = function(playerId) {
        const now = state.time.now;
        const games = filterGames();

        let result = [ ];
        games.forEach(
            game => {
                if((isFutureGame(game, now) || isVisiblePastGame(game, now))
                   && !game.gameFlags.isDeleted
                   && !game.gameFlags.isCancelled
                ) {
                    if(isGameHelper(game, playerId)) {
                        result.push(
                            prepareGame(clone(game), playerId, state.data.players, state.user, now)
                        );
                    }
                }
            }
        )

        result.sort(Compare.games);
        return result;
    }

    this.gamesForPlayer = function(playerId) {
        const result = {
            ices: [ ],
            games: [ ]
        };

        const games = filterGames();
        for(let gKey in games) {
            const game = games[ gKey ];
            const plFlags = game.playerFlags[ playerId ];
            if(plFlags) {
                if(plFlags.players || plFlags.backup || plFlags.warning) {
                    const g = clone(game);
                    g.userFlags = clone(game.playerFlags[ playerId ]);
                    result.games.push(g)
                }
            }
        }
        result.games.sort(Compare.games);

        const ices = state.data.ices;
        for(let ice of ices) {
            if(ice.autoJoin[ playerId ] === true) {
                result.ices.push(clone(ice))
            }
        }
        result.ices.sort(Compare.ices);

        return result;
    }
}

function getGameFilterState(state) {
    const player = state.user ? state.user.player : null;
    if(player) {
        return player.gameFilter;
    }
    return null;
}

function rinkGroupToRinks(rinkGroups) {
    let result = [ ];
    rinkGroups.forEach(rg => result = result.concat(AppConst.RinkFilterGroupsByKey[rg].rinks));
    return result;
}

function prepareGame(game, userId, players, user, now) {
    addUserFlags(game, userId, user);
    game.gameFlags.isFull = game.players.length >= AppConst.PlayerLimit;

    if(user && user.player) {
        const pl = user.player;
        if(pl.skillLevel) {
            game.gameFlags.userTooStrong = isPlayerTooStrong(pl.skillLevel, game.ice.lvl);
        }
    }

    if(isVisiblePastGame(game, now)) {    
        game.gameFlags.isVisiblePast = true;
    }

    game.gameKey = game.ice.date.getTime()+game.ice.rink+game.gameDate.getTime();

    if(players) {
        const helpersPresent = game.players.filter(playerId => game.helpers[ playerId ] === true);
        const adminsPresent = game.players.filter(playerId => {
            const pl = players[ playerId ];
            return pl && pl.isAdmin === true;
        });

        game.gameFlags.noHelpers = helpersPresent.length === 0
                                   && adminsPresent.length === 0
                                   && !game.dedicatedHelperId;
    }

    return game;
}

function addUserFlags(game, playerId, user) {
    game.userFlags = 
        game.playerFlags[ playerId ]
        || {
            playerId,
            going: false,
            warning: false,
            players: false,
            backups: false,
            tooStrong: false
        };

    game.userFlags.isHelper = isGameHelper(game, playerId);
    game.userFlags.isDedicatedHelper = game.dedicatedHelperId === playerId;
    game.userFlags.isNonPlayer = user.player.isNonPlayer;
    
    game.userFlags.playerPos =
        (game.userFlags.players ? game.players.indexOf(playerId) : game.players.length)
        + (game.userFlags.backups ? game.backups.indexOf(playerId) : 0);
}

function prepareIce(players, ice) {
    if(players) {
        const helpersPresent = ice.autoJoinPos
            ? ice.autoJoinPos.filter(playerId => ice.helpers[ playerId ] === true)
            : false;

        const adminsPresent = ice.autoJoinPos
            ? ice.autoJoinPos.filter(playerId => {
                const pl = players[ playerId ];
                return pl && pl.isAdmin === true;
            })
            : false;

        ice.noHelpers = helpersPresent.length === 0
                        && adminsPresent.length === 0
                        && !ice.dedicatedHelperId;
    }

    return ice;
}

function isGameHelper(game, playerId) {
    const inHelpers = game.helpers[ playerId ];
    const inPlayers = game.players.indexOf(playerId) !== -1;
    const isDedicatedHelper = game.dedicatedHelperId === playerId;
    
    return (inHelpers && inPlayers) || isDedicatedHelper;
}

function isFutureGame(game, now) {
    return now < game.gameDate;
}

function isVisiblePastGame(game, now) {
    const pastVisibleMillis = AppConst.PastGamesVisibleHours * MILLIS_PER_HOUR;
    const offsetGameDate = new Date(game.gameDate.getTime() + pastVisibleMillis);
    return now >= game.gameDate && now < offsetGameDate;
}
