import React from 'react';

import IceLevel from './level';
import IceDay from './day';
import IceDate from './date';
import IceDateReadonly from './dateReadonly';
import IceTime from './time';
import IceWeekly from './weekly';
import IceVip from './vip';
import IceRink from '../../rink';
import IceItem from '../../../iceItemComponent';
import Div from '../../../../div';
import * as DateTime from '../../../../../../utils/dateTime';
import * as Json from '../../../../../../utils/json';

const endDateSelect = 'adminIceEndDateSelect';
const vipCheckbox = 'adminIceVipcheckbox';

export default class Ice extends React.Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);

        this.state = { ice: null };
    }

    onChange(name, value) {
        const ice = this.state.ice || this.props.ice;
        const oldValue = ice[ name ];

        if(this.props.isNew) {
            if(name === 'hh') {
                ice.startDt.setHours(value);
                if(ice.endDt) {
                    ice.endDt.setHours(value);
                }
            }

            if(name === 'mm') {
                ice.startDt.setMinutes(value);
                if(ice.endDt) {
                    ice.endDt.setMinutes(value);
                }
            }

            if(name === 'day') {
                ice.startDt = DateTime.changeDayOfWeek(ice.startDt, this.props.now, value);
                ice.endDt = null;
            } else {
                ice[ name ] = value;
            }

            if(ice.endDt && ice.endDt < ice.startDt) {
                ice.endDt = new Date(ice.startDt.getTime());
            }

            if(name === 'vip') {
                ice.vip = value;
            }

            this.setState({ ice });
        } else {
            if(name === 'endDt') {
                this.confirmAndUpdate(value, oldValue);
            }
            if(name === 'vip') {
                this.confirmVipAndUpdate(value, oldValue);
            }
        }
    }

    confirmAndUpdate(newValue, oldValue) {
        const ice = this.state.ice || this.props.ice;
        
        setTimeout(
            () => {
                if(this.props.confirm.endDt(newValue)) {
                    this.props.update.endDt(ice, newValue);
                } else {
                    global.document.getElementById(endDateSelect).value = oldValue ? oldValue.getTime() : '';
                }
            },
            0
        );
    }

    confirmVipAndUpdate(newValue, oldValue) {
        const ice = this.state.ice || this.props.ice;
        
        setTimeout(
            () => {
                if(this.props.confirm.vip(ice, newValue)) {
                    this.props.update.vip(ice, newValue);
                } else {
                    global.document.getElementById(vipCheckbox).value = oldValue || false;
                }
            },
            0
        );
    }

    render() {
        const props = this.props;
        const state = this.state;
        
        const ice = state.ice || props.ice;
        props.iceHolder.ice = ice;

        const isPast = ice.endDt !== null && ice.endDt < props.now;

        const itemHeight = props.isNew ? 40 : 30;
        const dayAndTimeSize = props.isNew ? 180 : null;
        const dayAndTimeLabels = props.isNew ? 50 : 80;
        const datesRel = props.isNew ? null : 50;

        return <Div wRel='100'>
            <IceItem ice={ice} />

            <Div css='AdminView'>
                <Div h='8' />

                {props.isDev && ice.date ? Json.dateToString(ice.date) : null}

                <Div h={itemHeight} wRel='100'>
                    <IceRink name='rink' value={ice.rink} onChange={this.onChange} isNew={props.isNew} />
                </Div>

                <Div h={itemHeight} setup='r start start' wRel='100'>
                    <Div setup='r start center' wRel='100'>
                        {!props.isNew ? <Div fx='80' css='Bold'>Úroveň:</Div> : null}
                        <Div wRel='100'>
                            <IceLevel name='lvl' value={ice.lvl} onChange={this.onChange} isNew={props.isNew} />
                        </Div>
                    </Div>
                </Div>

                <Div setup='r between start' h={(props.isNew ? '40' : '20')} wRel='100'>
                    <Div setup='r start center' fx={dayAndTimeSize}>
                        <Div fx={dayAndTimeLabels} css='Bold'>Kedy:</Div>
                        <Div wRel='100'>
                            <IceDay name='day' value={ice.startDt} onChange={this.onChange} isNew={props.isNew} />
                        </Div>
                    </Div>

                    <Div setup='r between center' wRel={datesRel}>
                        <Div w='60' style={{textAlign: 'center'}} css='Bold'>od:</Div>
                        <Div setup='r start center' wRel='100'>
                            <IceDate now={props.now} name='startDt' value={ice.startDt} onChange={this.onChange} isNew={props.isNew} />
                        </Div>
                    </Div>
                </Div>

                <Div setup={'r between ' + (props.isNew ? 'start' : 'center')} h='40' wRel='100'>
                    <Div setup='r start center' fx={dayAndTimeSize}>
                        <Div fx={dayAndTimeLabels} css='Bold'>Čas:</Div>
                        <IceTime name='hh' name2='mm' value={ice.startDt} onChange={this.onChange} isNew={props.isNew} />
                    </Div>

                    <Div setup='r between center' wRel={datesRel}>
                        <Div w='60' style={{textAlign: 'center'}} css='Bold'>do: </Div>
                        <Div setup='r start center' wRel='100'>
                            <IceDate now={props.now} name='endDt' value={ice.endDt} valueStart={ice.startDt}
                                     onChange={this.onChange} isNew={props.isNew} isEndDate={true} isDeleted={ice.deleted} isPast={isPast}
                                     id={endDateSelect} />
                        </Div>
                    </Div>
                </Div>

                <Div setup='c center start' wRel='100' h='60'>
                    <IceWeekly name='weekly' value={ice.weekly} onChange={this.onChange} isNew={props.isNew} />
                </Div>
                <Div setup='c center start' wRel='100' h='60'>
                    <IceVip name='vip' value={ice.vip} onChange={this.onChange} id={vipCheckbox} />
                </Div>

                {ice.deleted
                    ? <Div setup='r start start' wRel='100'>
                        <Div w='120' class='fBold'>Vymazaný:</Div>
                        <Div setup='r start start' w='100'>
                            <IceDateReadonly date={ice.deletedDate} />
                        </Div>
                    </Div>
                    : null
                }
            </Div>
        </Div>
    }
}
