import React from 'react';

import Modal from './modal';
import Div from '../div';
import Button from '../button';
import Icon from '../icon';

export default class Info extends React.Component {
    constructor(props) {
        super(props);

        this.onOpen = this.onOpen.bind(this);
        this.onClose = this.onClose.bind(this);

        this.state = { isOpen: false };
    }

    onOpen() {
        this.setState({ isOpen: true });
    }

    onClose(event) {
        if(event) {
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
        }

        this.setState({ isOpen: false });
        if(this.props.onClose) {
            this.props.onClose();
        }
    }

    render() {
        const props = this.props;
        const css = props.size ? 'Icon' + props.size : 'IconMini';

        return <React.Fragment>
            { props.noImage
                ? null
                : <Icon image={props.image || 'infoDark'} css={css} onClick={this.onOpen} />
            }
            <Modal isOpen={this.state.isOpen} onClose={this.onClose} contentStyle={{ height: this.props.contentSize }}>
                {this.props.children}
                <Div setup='c center center' wRel='100' h='60'>
                    <Button w='80' h='40' value={false} text='Zavri' onClick={this.onClose} />
                </Div>
            </Modal>
        </React.Fragment>
    }
}
