import React from 'react';

import * as Common from './common';
import Email from '../../../../email';
import TelNumber from '../../../../telNumber';
import Div from '../../../../div';

export default ({ player, showRules }) => {
    function verifyInfo(prop) {
        if(player.verify && player.verifyProps[ prop ]) {
            return ' (?)';
        } else {
            return '';
        }
    }

    const info = player.info;
    const reg = player.reg;

    return <React.Fragment>
        { Common.text('Meno:', info.firstName + verifyInfo('firstName'),       null, 60) }
        { Common.text('Priezvisko:', info.lastName + verifyInfo('lastName'),   null, 60) }
        { Common.component('Tel:', <TelNumber tel={info.tel}/>, verifyInfo('tel'), 60) }
        { Common.component('Email:', <Email email={info.email}/>, verifyInfo('email'), 60) }
        { reg.aboutMe
            ? Common.row('O mne:', reg.aboutMe, false, 60, true)
            : null
        }
        <Div h='10' />
        { showRules
            ? <React.Fragment>
                { Common.text('Poučený - výstroj:',                 reg.ruleEquipment || false,     v => v === false) }
                { Common.text('Súhlasí s pravidlami odhlasovania:', reg.ruleGames || false,         v => v === false) }
                { Common.text('Pozná pravidlá:',                    reg.ruleAgreed || false,        v => v === false) }
                { Common.text('Súhlas - mailing list:',             reg.ruleMailinglist || false) }
                { reg.comment
                    ? Common.row('Komentár:', reg.comment, false, 80, true)
                    : null
                }
            </React.Fragment>
            : null
        }
    </React.Fragment>
}
