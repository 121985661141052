import { TYPE } from '../actions';
import { DataEvent } from '../../const';
import clone from '../../utils/clone';

export default function notify(state, action, args) {
    if(state === undefined) {
        return null;
    }

    switch(action.type) {
        case TYPE.Init:
            return null;

        case TYPE.ErrorView:
            return null;

        case TYPE.Received:
            switch(action.data.type) {
                case DataEvent.Game.Game:
                    switch(action.data.game.type) {
                        case DataEvent.Game.Games:
                            return clone(action.data.game.games);
                            
                        case DataEvent.Game.GamesDiff:
                            // cannot merge when not initialized
                            if(state === null) {
                                return null;
                            }
                            return mergeGames(state, action.data.game.gamesDiff);

                        default:
                            return clone(state);
                    }

                default:
                    return clone(state);
            }

        default:
            return clone(state);
    }
}

function mergeGames(state, diff) {
    if(diff && state) {
        for(let gameKey in diff) {
            const newGame = clone(diff[ gameKey ]);
            const oldGame = state[ gameKey ];
            
            if(oldGame) {
                if(oldGame.gameTs === null || newGame.gameTs > oldGame.gameTs) {
                    state[ gameKey ] = newGame;
                }
            } else {
                state[ gameKey ] = newGame;
            }
        }
        return clone(state);
    } else {
        return { };
    }
}
