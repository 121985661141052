import React from 'react';
import Modal from 'react-modal';

import './modal.css';

export default class ModalMessage extends React.Component{
    render() {
        const p = this.props;
        const appElement = global.document.getElementById('root');

        return <Modal
            isOpen={p.isOpen}
            className={p.fullScreen ? (p.fromTop ? 'modalBase modalFullTop' : 'modalBase modalFull') : 'modalBase modal'}
            overlayClassName='modalBase modalOverlay'
            ariaHideApp={true}
            appElement={appElement}
            onRequestClose={p.onClose}
            style={{ content: p.contentStyle }}>
            { this.props.children }
        </Modal>
    }
}
