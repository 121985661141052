export function players(p1, p2) {
    const info1 = p1.info;
    const info2 = p2.info;

    let last1 = info1 ? info1.lastName.toLowerCase() : '';
    let last2 = info2 ? info2.lastName.toLowerCase() : '';
    last1 = last1 ? last1.normalize('NFD').replace(/[\u0300-\u036f]/g, "") : null;
    last2 = last2 ? last2.normalize('NFD').replace(/[\u0300-\u036f]/g, "") : null;
    
    if(last1.charAt(0) === 'c' && last1.charAt(1) === 'h') {
        last1 = 'hzzzzzz' + last1.substr(1);
    }
    if(last2.charAt(0) === 'c' && last2.charAt(1) === 'h') {
        last2 = 'hzzzzzz' + last2.substr(1);
    }

    if(last1 !== last2) {
        return last1 < last2 ? -1 : 1;
    }

    const first1 = info1 ? info1.firstName : '';
    const first2 = info2 ? info2.firstName : '';
    return first1 < first2 ? -1 : 1;
}

export function ices(i1, i2) {
    const day1 = (i1.startDt.getDay() + 6) % 7;
    const day2 = (i2.startDt.getDay() + 6) % 7;
    if(day1 !== day2) {
        return day1 - day2;
    }

    const time1 = i1.startDt.getHours()*60 + i1.startDt.getMinutes();
    const time2 = i2.startDt.getHours()*60 + i2.startDt.getMinutes();
    return time1 - time2;
}

export function games(g1, g2) {
    return  g1.gameDate < g2.gameDate
                ? -1
                : (
                    g1.gameDate > g2.gameDate
                        ? 1 
                        : g1.ice.rink < g2.ice.rink ? -1 : 1
                )
}

export function weekGames(w1, w2) {
    if(w1.year !== w2.year) {
        return w1.year - w2.year;
    }

    return  w1.week < w2.week ? -1 : 1
}
