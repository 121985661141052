import React from 'react';

import Div from '../../div';
import Icon from '../../icon';

export default ({ game, showDetail }) => {
    return <Div setup='r start center'>
        <Div class='padRightSmall'>hráči: {game.players.length}</Div>
        <Div>
            <Icon css='IconSmall' image='playersDark' />
        </Div>
        <Div>
            <Icon css='IconSmall' image='infoDark' onClick={showDetail ? () => showDetail(game) : null}
                  id='cypress.content.gameItem.playerCount' />
        </Div>
    </Div>
}
