import React from 'react';

import Div from '../../div';
import Icon from '../../icon';

export default ({ inList, getPlayer, game, setHelper, removeHelper }) => {
    const isPast = game.gameFlags.isPast;

    // 'game' can contain ice
    const noHelpers = game.ice.noHelpers !== undefined ? game.ice.noHelpers : game.gameFlags.noHelpers;
    const warning = noHelpers ? '- šatniar' : null;
    const dedicated = game.dedicatedHelperId;
    
    if(game.gameFlags.isCancelled) {
        return null;
    }
    
    if(inList) {
        if(warning) {
            return <Div setup='r start center' class='fBold fMedium' style={{ color: 'blue' }}>
                <Div setup='r center center' class='padTopSmall'>{warning}</Div>
            </Div>
        } else {
            return null;
        }
    } else {
        const dedPlayer = game.dedicatedHelperId ? getPlayer(game.dedicatedHelperId) : null;
        const msg = dedicated ? dedPlayer.info.lastName : 'v súpiske';
        const msg2 = dedicated ? dedPlayer.info.firstName : null;

        return <Div setup='c start start' class='fBold fMedium' style={{ color: 'blue' }}>
            <Div setup='r start center'>
                { isPast
                    ? null
                    : (warning
                        ? <Icon css='IconSmall' image='infoDark' onClick={setHelper} />
                        : (
                            dedicated
                                ? <Icon css='IconSmall' image='cancelDark' onClick={removeHelper} />
                                : <Icon css='IconSmall' image='infoDark' onClick={setHelper} />
                        )
                    )
                }
                <Div setup='r center center' class='padTopSmall'>{warning || msg}</Div>
            </Div>
            { msg2
                ? <Div setup='r center center' class='padTopSmall'>{msg2}</Div>
                : null
            }
        </Div>
    }
}
