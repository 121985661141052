import React from 'react';
import { connect } from 'react-redux';

import GameItemContainer from './gameItemContainer';
import Players from './players';
import EditModePlayers from './editMode/players';
import EditModeFooter from './editMode/footer';
import MarkModePlayers from './markMode/players';
import MarkModeFooter from './markMode/footer';
import CancelCheckbox from './cancelCheckbox';
import NoticeCheckbox from './noticeCheckbox';
import Div from '../../../div';
import Button from '../../../button';
import * as Actions from '../../../../actions';
import * as Requests from '../../../../requests';
import { DataEvent, isPlayerTooStrong } from '../../../../../const';

const CANCELLED_REASON_MAXLENGTH = 160;
const NOTICE_MAX_LENGTH = 160;

class GameDetailAdminView extends React.Component {
    constructor(props) {
        super(props);
        
        this.toggleMode = this.toggleMode.bind(this);
        
        this.state = {
            mode: this.props.storedMode
        }
    }

    componentDidMount() {
        const game = this.props.getGame(this.props.params.gameKey);
        if(game.userFlags.isHelper) {
            this.setState({
                mode: 'mark'
            })
        } else {
            const context = this.props.viewContext;
            if(context && context.mode) {
                this.setState({
                    mode: context.mode
                })
            }
        }
    }

    toggleMode(mode) {
        const game = this.props.getGame(this.props.params.gameKey);

        if( mode === 'edit'
            || (mode === 'mark' && canMark(game))
            || mode === null
        ) {
            const newMode = mode !== this.state.mode ? mode : null;
            this.setState({ mode: newMode });
            if(this.props.isAdmin) {
                this.props.storeMode(newMode);
            }
        }
    }

    render() {
        const props = this.props;
        const state = this.state;

        const game = props.getGame(props.params.gameKey);
        const isPast = game.gameFlags.isPast;

        return <Div setup='c start start' wRel='100' id='cypress.content.gameDetail' hRel='100'>
            <GameItemContainer game={game} />
            
            <Div setup='c start start' class='fMedium' wRel='100'>
                { state.mode === null
                    ? <Players game={game} showPlayer={props.showPlayer} isAdmin={props.isAdmin} />
                    : (state.mode === 'edit'
                        ? <EditModePlayers game={game} getUnregId={getUnregId} addUnregPlayer={props.addUnregPlayer} showPlayer={props.showPlayer} />
                        : <MarkModePlayers game={game} />
                    )
                }

                { props.isAdmin && state.mode === null && !isPast
                    ? <CancelCheckbox isCancelled={game.gameFlags.isCancelled} onChange={() => props.cancelGame(game)} />
                    : null
                }

                { props.isAdmin && state.mode === null && !isPast && !game.gameFlags.isCancelled
                    ? <NoticeCheckbox isEnabled={game.gameFlags.isNoticeEnabled} noticeText={game.gameFlags.noticeText} onChange={() => props.toggleNotice(game)} />
                    : null
                }

                <Div css='ButtonSectionPadding' />
            </Div>
            
            { state.mode !== null
                ? (
                    state.mode === 'edit'
                        ? <EditModeFooter game={game} addPlayer={() => props.addPlayer(props.getPlayerById, game)} />
                        : <MarkModeFooter game={game} />
                )
                : null
            }

            <Div setup='r center center' class='fixedDown' wRel='100'>
                <Div css='ButtonSection' setup='r around center' class='container'>
                    { props.isNonPlayer
                        ? <Button text='Zavri' w='65' onClick={props.closeView} />
                        : <React.Fragment>
                            { props.isAdmin && canMark(game)
                                ? <Button text={'Šatňa'} onClick={() => this.toggleMode('mark')} w='65'
                                    controlled={true} on={state.mode === 'mark'} />
                                : null
                            }

                            { props.isAdmin && !isPast
                                ?
                                    <Button text='Uprav' w='65'
                                        onClick={() => this.toggleMode('edit')}
                                        controlled={true} on={state.mode === 'edit'} />

                                : null
                            }

                            { props.isAdmin
                                ? <Button text='@' w='65' onClick={() => props.showContacts(game)} />
                                : null
                            }

                            <Button text='Zavri' w='65' onClick={props.closeView} />
                        </React.Fragment>
                    }
                </Div>
            </Div>
        </Div>
    }
}

function canMark(game) {
    return !game.gameFlags.isPast || game.gameFlags.isVisiblePast;
}

const mapStateToProps = state => ({
    userId: state.user.player.id,
    isNonPlayer: state.user.player.isNonPlayer,
    getPlayerById: state.get.playerById,
    getGame: gameKey => state.get.gameByKey(gameKey.iceDate, gameKey.iceRink, gameKey.gameDate),
    storedMode: state.globalParams[ Actions.GLOBAL_PARAM.AdminGameMode ],
    isAdmin: state.user.player.isAdmin
});

const mapDispatchToProps = dispatch => ({
    addPlayer: (getPlayerById, game) => dispatch(
        Actions.pushViewKeepContext(
            Actions.VIEW.Players,
            {
                playerFilter: playerId => {
                    const player = getPlayerById(playerId);
                    
                    return game.players.indexOf(playerId) === -1
                           && game.backups.indexOf(playerId) === -1
                           && game.tooStrong.indexOf(playerId) === -1
                           && !player.isNonPlayer
                           && !isPlayerTooStrong(player.skillLevel, game.ice.lvl)
                },

                selectPlayer: player => {                
                    dispatch(
                        Actions.popView(null, Actions.VIEW.Players)
                    );

                    dispatch(
                        Requests.movePlayerToPlayers(game.ice.date, game.ice.rink, game.gameDate, player.id)
                    )
                }
            }
        )
    ),

    addUnregPlayer: (game, name) => dispatch(
        Requests.addUnregisteredPlayer(game.ice.date, game.ice.rink, game.gameDate, name)
    ),

    showPlayer: player => dispatch(
        Actions.pushView(
            Actions.VIEW.PlayerDetail,
            { playerId: player.id }
        )
    ),

    cancelGame: game => {
        const newValue = !game.gameFlags.isCancelled;
        const dateDesc = game.gameDate.getDate() + '.' + (game.gameDate.getMonth()+1);

        let ok = false;
        let cancelledReason = undefined;

        if(newValue === true) {
            cancelledReason = promptInput(
                `Naozaj zrušiť termín ${dateDesc}?`,
                `< dôvod zrušenia termínu (max. ${CANCELLED_REASON_MAXLENGTH} znakov)>`,
                CANCELLED_REASON_MAXLENGTH
            );
            ok = cancelledReason !== null;
        } else {
            ok = global.window.confirm(`Naozaj povoliť termín ${dateDesc}?`);
        }

        if(ok) {
            dispatch(
                Requests.cancelGame(game.ice.date, game.ice.rink, game.gameDate, newValue, cancelledReason)
            );
        }
    },

    toggleNotice: game => {
        const newValue = !game.gameFlags.isNoticeEnabled;
        
        let ok = false;
        let noticeText = undefined;

        if(newValue === true) {
            noticeText = promptInput(
                `Zadaj text poznámky:`,
                `< max. ${NOTICE_MAX_LENGTH} znakov>`,
                NOTICE_MAX_LENGTH
            );
            ok = noticeText !== null;
        } else {
            ok = global.window.confirm(`Naozaj odobrať poznámku "${game.gameFlags.noticeText}"?`);
        }

        if(ok) {
            dispatch(
                Requests.setGameNotice(game.ice.date, game.ice.rink, game.gameDate, newValue, noticeText)
            );
        }
    },

    showContacts: game => dispatch(
        Actions.pushView(
            Actions.VIEW.PlayerContacts,
            {
                gameKey: {
                    iceDate: game.ice.date,
                    iceRink: game.ice.rink,
                    gameDate: game.gameDate
                }
            }
        )
    ),

    enableUnloadEvent: enabled => dispatch(
        Actions.setGlobalParam(
            Actions.GLOBAL_PARAM.UnloadEventEnabled,
            enabled
        )
    ),
    
    storeMode: mode => dispatch(
        Actions.setGlobalParam(
            Actions.GLOBAL_PARAM.AdminGameMode,
            mode
        )
    ),

    closeView: () => dispatch(Actions.popView())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GameDetailAdminView)

function getUnregId(text) {
    return DataEvent.GameEvent.UnregisteredPlayer + text;
}

function promptInput(msg, defaultResult, maxLength) {
    let result = undefined;

    while(
        result === undefined
        || (
            result !== null
            && (
                result.length === 0
                || result.length > maxLength
            )
        )
    ) {
        result = global.window.prompt(msg, defaultResult);
        if(result) {
            if(typeof result === 'number') {
                result = ''+result;
            }
            result = result.trim();
        }
    }

    return result;
}
