import React from 'react';
import { connect } from 'react-redux';

import Header from './header';
import GridContainer from './gridContainer';
import ListContainer from './listContainer';
import ListByStatusContainer from './listByStatusContainer';
import Div from '../../../div';
import Button from '../../../button';
import * as Actions from '../../../../actions';

class PlayersView extends React.Component {
    state = {
        mode: 0
    }

    constructor(props) {
        super(props);
        this.setMode = this.setMode.bind(this);
    }

    componentDidMount() {
        this.setState({
            mode: this.props.globalParamsMode
        })
    }

    setMode(mode) {
        this.setState({
            mode 
        });

        this.props.setGlobalParam(mode);
    }

    render() {
        const state = this.state;
        const props = this.props;

        const paramsSelectPlayer = props.params ? props.params.selectPlayer : null;
        const paramsPlayerFilter = props.params ? props.params.playerFilter : null;
        const selectPlayer = paramsSelectPlayer || props.selectPlayer;
        const gridAlwaysShowList = paramsSelectPlayer !== null;
        
        return <Div hRel='100' wRel='100' setup='c start start' id='cypress.content.adminPlayers'>
            <Header mode={this.state.mode} setMode={this.setMode} 
                    players={props.players(paramsPlayerFilter)}
                    playersByStatus={props.playersByStatus(paramsPlayerFilter)} />
            
            { state.mode === 0
                ? <ListContainer selectPlayer={selectPlayer} playerFilter={paramsPlayerFilter} />
                : null
            }
            { state.mode === 1
                ? <ListByStatusContainer selectPlayer={selectPlayer} playerFilter={paramsPlayerFilter} />
                : null
            }
            { state.mode === 2
                ? <GridContainer showPlayerList={props.showGridPlayerList.bind(null, selectPlayer, gridAlwaysShowList)}
                                 playerFilter={paramsPlayerFilter}/>
                : null
            }
            
            <Div css='ButtonSectionPadding' />
            <Div setup='r center center' class='fixedDown' wRel='100'>
                <Div css='ButtonSection' setup='r around center' class='container'>
                    <Button text='Emaily' onClick={this.props.openEmails} />
                    <Button text='Zavri' onClick={this.props.closeView} />
                </Div>
            </Div>
        </Div>
    }
}

const mapStateToProps = state => ({
    players: playerFilter => state.get.playerList(playerFilter),
    playersByStatus: playerFilter => state.get.playerListsByStatus(playerFilter),
    globalParamsMode: state.globalParams[ Actions.GLOBAL_PARAM.AdminPlayersMode ]
});

const mapDispatchToProps = dispatch => ({
    setGlobalParam: mode => dispatch(
        Actions.setGlobalParam(Actions.GLOBAL_PARAM.AdminPlayersMode, mode)
    ),

    showGridPlayerList: (selectPlayer, alwaysList, letter, list) => 
        (list.length === 1 && alwaysList === false)
            ? dispatch(
                Actions.pushView(
                    Actions.VIEW.PlayerDetail,
                    { playerId: list[0].id }
                )
            )
            : dispatch(
                Actions.pushView(
                    Actions.VIEW.PlayerList,
                    {
                        letter,
                        selectPlayer
                    }
                )
        ),

    selectPlayer: player => 
        dispatch(
            Actions.pushView(
                Actions.VIEW.PlayerDetail,
                { playerId: player.id }
            )
        ),

    openEmails: () => dispatch(Actions.pushView(Actions.VIEW.PlayerEmails)),

    closeView: () => dispatch(Actions.popView())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayersView)
