import { connect } from 'react-redux';
import React from 'react';

import PlayerItem from '../playerItemComponent';

const ListContainer = ({ players, playerFilter, selectPlayer }) =>
    players(playerFilter).map(
        (player, n) => <PlayerItem key={n} player={player} selectPlayer={selectPlayer} inList={true} />
    );

const mapStateToProps = state => ({
    players: playerFilter => state.get.playerList(playerFilter)
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListContainer)
