import React from 'react';
import { connect } from 'react-redux';

import Div from './div';
import Icon from './icon';
import * as Actions from '../actions';
import { AppConst } from '../../const';

const levels = { };
AppConst.SkillLevel.forEach(lvl => levels[ lvl.key ] = lvl);

function getLevelDesc(key) {
    const lvl = levels[ key ];
    return lvl ? lvl.label : 'neposúdená';
}

function skillStars(key, max) {
    const lvl = levels[ key ];
    if(lvl) {
        return '★'.repeat(lvl.value) + '☆'.repeat(max - lvl.value);
    } else {
        return '?';
    }
}

const SkillLevel = ({ openSkillInfoView, showInfoView = true, level, image, showStars }) => {
    return <Div setup='r start center' css='UserDetail'>
                <Div class={showInfoView ? 'padRightSmall' : null}>
                    úroveň: {
                    showStars
                        ? skillStars(level, 5)
                        : getLevelDesc(level)
                }
                </Div>
                { showInfoView
                    ? <Icon image={image || 'infoDark'} css='IconMini' onClick={openSkillInfoView} />
                    : null
                }                    
            </Div>
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
    openSkillInfoView: () => dispatch(Actions.pushView(Actions.VIEW.SkillLevelInfo))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SkillLevel)
