import React from 'react';

import Div from '../../div';
import Icon from '../../icon';

export default ({ inList, ice, helperActions }) => {
    const noHelpers = ice.noHelpers;
    const warning = noHelpers ? '- šatniar' : null;
    const dedicated = ice.dedicatedHelperId;

    if(inList) {
        if(warning) {
            return <Div setup='r start center' class='fBold fMedium' style={{ color: 'blue' }}>
                <Div setup='r center center' class='padTopSmall'>{warning}</Div>
            </Div>
        } else {
            return null;
        }
    } else {
        const dedPlayer = ice.dedicatedHelperId ? helperActions.getPlayer(ice.dedicatedHelperId) : null;
        const msg = dedicated ? dedPlayer.info.lastName : 'v zozname';
        const msg2 = dedicated ? dedPlayer.info.firstName : null;

        return <Div setup='r start center' class='fBold fMedium' style={{ color: 'blue' }}>
            { warning
                ? <Icon css='IconSmall' image='infoDark' onClick={() => helperActions.setHelper(ice)} />
                : (
                    dedicated
                        ? <Icon css='IconSmall' image='cancelDark' onClick={() => helperActions.removeHelper(ice)} />
                        : <Icon css='IconSmall' image='infoDark' onClick={() => helperActions.setHelper(ice)} />
                )
            }
            <Div setup='r center center' class='padTopSmall'>{warning || msg}</Div>
            { msg2
                ? <Div setup='r center center' class='padTopSmall padLeftSmall'>{msg2}</Div>
                : null
            }
        </Div>
    }
}
