import React from 'react';
import AvatarEditor from 'react-avatar-editor'

import Div from '../div';
import Button from '../button';
import { UserImageConst } from '../../../const';

const SIZE = 280;
const BORDER = 0;

const SCALE_ZOOM_IN = 1.333333;
const SCALE_ZOOM_OUT = 0.75;

export default class UserProfileImageEditor extends React.Component {
	constructor(props) {
		super(props);

		this.editor = null;
		this.reset = this.reset.bind(this);
		this.setFile = this.setFile.bind(this);
		this.setScale = this.setScale.bind(this);
		this.rotate = this.rotate.bind(this);

		this.state = {
			edit: false,
			input: null,
			scale: 1,
			rotate: 0
		}
	}

	getImage() {
		if(this.state.edit) {
			const canvas = this.editor.getImage();
			const base64 = canvas.toDataURL(UserImageConst.DATA_MIME).substr(UserImageConst.DATA_BASE64_START);
			
			if(base64.length > UserImageConst.IMAGE_MAX_BYTES) {
				global.alert('Obrázok je príliš veľký');
				return false;
			} else {
				return base64;
			}
		} else {
			return null;
		}
	}

	reset() {
		if(this.refs.inputFile) {
			this.refs.inputFile.value = '';
		}

		this.setState({
			edit: false,
			input: null,
			scale: 1,
			rotate: 0
		});
	}

	setEditorRef = editor => this.editor = editor;

	setFile(event) {
		const reader = new FileReader();
		
		reader.onloadend = () => {
			this.setState({
				edit: true,
				input: reader.result,
				scale: 1,
				rotate: 0
			});
		};

		reader.onerror = () => global.alert('Chyba pri citani suboru!');

		const file = event.target.files[0];
		reader.readAsDataURL(file);
	}

	setScale(ratio) {
		const s = this.state;

		this.setState({
			input: s.input,
			scale: s.scale * ratio,
			rotate: s.rotate
		});
	}

	rotate(right) {
		const s = this.state;

		this.setState({
			input: s.input,
			scale: s.scale,
			rotate: (s.rotate + (right ? 90 : 270)) % 360
		});
	}

	render() {
		const props = this.props;
		const state = this.state;

		const imageInput = state.input || props.info.imageUrlBig;

		return <Div setup='c center center'>
			<Div setup='r start center' h='40'>
				<input ref='inputFile' id="file" type="file" accept="image/*" onChange={this.setFile} />
				{ state.input
					? <Button text='x' w={30} h={30} controlled={true} on={false} onClick={this.reset} />
					: null
				}
			</Div>
			{ state.edit
				? <Div setup='r between center' h='70' wRel='100' class='padLeft padRight'>
					<Button text='do ľava' w='55' controlled={true} on={false} onClick={() => this.rotate(false)} />
					<Button text='do prava' w='65' controlled={true} on={false} onClick={() => this.rotate(true)} />
					<Button text='-' w='50' controlled={true} on={false} onClick={() => this.setScale(SCALE_ZOOM_OUT)} />
					<Button text='+' w='50' controlled={true} on={false} onClick={() => this.setScale(SCALE_ZOOM_IN)} />
				</Div>
				: null
			}
			{ state.edit
				? <AvatarEditor image={imageInput}
					ref={this.setEditorRef}
			        width={SIZE} height={SIZE}
			        border={BORDER} color={[255, 255, 255, 0.8]}
			        scale={state.scale} rotate={state.rotate}
			        style={{ border: 'dotted 1px gray', marginBottom: '10px' }} />
			    : <img src={imageInput} style={{ width: SIZE, height: SIZE }} alt='profilová fotka' />
			}
		</Div>
	}
}
