import * as Actions from '../actions';
import clone from '../../utils/clone';

export default (state, action) => {
    if(state === undefined) {
        const result = {
            [ Actions.GLOBAL_PARAM.AdminPlayersMode ]: 0,
            [ Actions.GLOBAL_PARAM.UnloadEventEnabled ]: true,
            [ Actions.GLOBAL_PARAM.AdminGameMode ]: null,
            [ Actions.GLOBAL_PARAM.GameFilterExpanded ]: false
        };
        return result;
    }

    if(action.type === Actions.TYPE.SetGlobalParam) {
        const result = clone(state);
        result[ action.name ] = action.value;
        return result;
    }

    return clone(state);
}
