import React from 'react';

import Div from '../../../div';

export default ({ players, showPlayerList }) =>
    <React.Fragment>
        <Div h='10' />
        <Div setup='c around center' wRel='100'>
            { players.grid.map(
                (row, n) => <Div key={n} fx='60' wRel='100' setup='r around center'>
                    { row.map(
                        (letter, n) => letterButton(players, letter, n, showPlayerList)
                    ) }
                </Div>
            ) }
        </Div>
    </React.Fragment>

function letterButton(players, letter, n, showPlayerList) {
    const byLetter = players.players[ letter ];
    const list = byLetter.list;

    return <Div key={n} fx='50' h='50' setup='c center center'
                css={getCss(list)}
                class={getClass(list)}
                onClick={() => showList(letter, list, showPlayerList)}>
        <Div>{letter.toUpperCase()}</Div>
        { flags(list) }
    </Div>
}

function getCss(list) {
    const count = list.length;
    return count > 0
       ? 'AdminPlayerLetter'
       : 'AdminPlayerLetterEmpty';
}

function getClass(list) {
    const count = list.length;
    return count > 0 ? 'clickable' : undefined;
}

function showList(letter, list, show) {
    if(list.length > 0) {
        show(letter, list);
    }
}

function flags(list) {
    if(list.length > 0) {
        return <Div setup='r around center' class='fSmall' w='40'>
            <Div>{list.length}</Div>
        </Div>
    } else {
        return null;
    }
}
