import React from 'react';

import Div from '../../../../div';
import Button from '../../../../button';

export default ({ game, addPlayer }) => {
    const h = 60;

    return <React.Fragment>
        <Div h={h} />

        <Div setup='r center center' wRel='100' h={h}
             style={{ position: 'fixed', bottom: '60px', left:'0px' }}>

            <Div setup='r around center' class='container bgWhite' h={h}
                 style={{ borderTop: '1px solid gray' }}>
                <Button controlled={true} on={false} w='80'
                        text='+ hráč' onClick={addPlayer} />
                <Div>{
                    game.gameFlags.isLocked
                        ? `Súpiska: ${game.players.length}, náhradníci: ${game.backups.length+game.tooStrong.length}`
                        : `V poradí: ${game.players.length}`
                    }
                </Div>
            </Div>
        </Div>
    </React.Fragment>
}
