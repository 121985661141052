import React from 'react';
import { connect } from 'react-redux';

import Header from './header';
import IceListContainer from './iceListContainer';
import Div from '../../../div';
import Button from '../../../button';
import * as Actions from '../../../../actions';
import { AppConst } from '../../../../../const';

const IceListView = ({ now, selectIce, createIce, closeView }) =>
    <Div hRel='100' wRel='100' setup='c start start'>
        <Header />

        <IceListContainer selectIce={selectIce} />

        <Div css='ButtonSectionPadding' />
        <Div setup='r center center' class='fixedDown' wRel='100'>
            <Div css='ButtonSection' setup='r around center' class='container'>
                <Button text='Nový ľad' onClick={() => createIce(now)} />
                <Button text='Zavri' onClick={closeView} />
            </Div>
        </Div>
    </Div>

const mapStateToProps = state => ({
    now: state.time.now
});

const mapDispatchToProps = dispatch => ({
    selectIce: ice => dispatch(
        Actions.pushView(
            Actions.VIEW.IceDetail,
            {
                iceKey: {
                    date: ice.date,
                    rink: ice.rink
                }
            }
        )
    ),

    createIce: now => dispatch(
        Actions.pushView(
            Actions.VIEW.IceDetail,
            {
                newIce: newIce(now)
            }
        )
    ),

    closeView: () => dispatch(Actions.popView()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IceListView)

function newIce(now) {
    now = new Date(now.getTime());
    now.setMinutes(0);
    now.setHours(now.getHours() + 3);

    return {
        lvl: AppConst.SkillLevel[0].key,
        rink: AppConst.Rinks[0].key,
        startDt: new Date(now.getTime() - (now.getTime()%60000)),
        weekly: true
    }
}
