import React from 'react';

import Div from '../../div';

export default ({ info, split }) => (
    split 
        ? <Div setup='c start start'>
            <Div>{info.firstName}</Div>
            <Div>{info.lastName}</Div>
        </Div>
        : <Div>{info.firstName + ' ' + info.lastName}</Div>
)
