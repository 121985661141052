import React from 'react';
import { connect } from 'react-redux';

import GameItemContainer from './gameItemContainer';
import Players from './players';
import Div from '../../../div';
import Button from '../../../button';
import * as Actions from '../../../../actions';

const GameDetailView = ({ params, isNonPlayer, getGame, switchToGameDetailAdmin, closeView }) => {
    const game = getGame(params.gameKey);

    return <Div setup='c start start' wRel='100' css='View' id='cypress.content.gameDetail'>
        <GameItemContainer game={game} />

        <Div setup='c start start' class='fBig' wRel='100'>
            <Players game={game} />
            {
                isNonPlayer
                    ? null
                    : <Div setup='c start start' class='padLeftSmall padRightSmall fBig' wRel='100'>
                        <Div css='ButtonSectionPadding' />
                        <Div setup='r center center' class='fixedDown' wRel='100'>
                            <Div css='ButtonSection' setup='r around center' class='container'>
                                <Button text='Zavri' onClick={closeView} />
                            </Div>
                        </Div>
                    </Div>
            }
        </Div>
    </Div>
}

const mapStateToProps = state => ({
    isNonPlayer: state.user.player.isNonPlayer,
    getGame: gameKey => state.get.gameByKey(gameKey.iceDate, gameKey.iceRink, gameKey.gameDate),
});

const mapDispatchToProps = dispatch => ({
    closeView: () => dispatch(Actions.popView())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GameDetailView)
