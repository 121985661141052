export default function WindowEvents(handlers) {
    function onFocus() {
        if(handlers.onFocus) {
            handlers.onFocus();
        }
    }

    function onBlur() {
        if(handlers.onBlur) {
            handlers.onBlur();
        }
    }

    function onOnline() {
        if(handlers.onOnline) {
            handlers.onOnline();
        }
    }

    function onOffline() {
        if(handlers.onOffline) {
            handlers.onOffline();
        }
    }

    function onBeforeUnload(event) {
        if(handlers.onBeforeUnload) {
            return handlers.onBeforeUnload(event);
        }
    }

    function onKeyDown(event) {
        // escape
        if(event.keyCode === 27) {
            if(handlers.onEscapePressed) {
                return handlers.onEscapePressed();
            }
        }
    }

    function onError(event) {
        if(handlers.onError) {
            return handlers.onError(event);
        }
    }

    this.register = function() {
        window.addEventListener('focus', onFocus);
        window.addEventListener('blur', onBlur);
        window.addEventListener('online', onOnline);
        window.addEventListener('offline', onOffline);
        window.addEventListener('keydown', onKeyDown);
        window.addEventListener('error', onError);
        window.onbeforeunload = onBeforeUnload;
    }

    this.unregister = function() {
        window.removeEventListener('focus', onFocus);
        window.removeEventListener('blur', onBlur);
        window.removeEventListener('online', onOnline);
        window.removeEventListener('offline', onOffline);
        window.removeEventListener('keydown', onKeyDown);
        window.removeEventListener('error', onError);
        window.onbeforeunload = null;
    }
}
