import React from 'react';

import Div from '../../../../div';

export default ({ name, value, onChange, isNew }) =>
    <label style={{ width:'100%' }}>
        <Div setup='r between center' wRel='100'>
            <Div>Zobrazovať v pravidelnom prihlasovaní:</Div>
            <Div fx='50' style={{textAlign: 'right' }}>
                <input type='checkbox' style={{height: '35px', width: '35px', margin: '0px'}}
                       checked={value ? 'checked' : ''} onChange={() => onChange(name, !value)}
                       disabled={isNew ? undefined : true} />
            </Div>
        </Div>
    </label>