import React from 'react';
import { connect } from 'react-redux';

import Div from '../div';
import Icon from '../icon';

import * as Actions from '../../actions';

const Banner = ({ openPopup }) => {
    return <React.Fragment>
        <Div class='bgBannerUkraine padRight' h='60'>
            <Div setup='r between center' wRel='100' hRel='100' class='fBig margLeft margRight margLeft'>
                <Div setup='r left center'>
                    <Icon css='IconMedium' image='flagUkraine' />
                    &nbsp; Sláva Ukrajine!
                </Div>
                <Icon css='IconMedium' image='infoDark' class='margRight' onClick={openPopup} />
            </Div>
        </Div>
    </React.Fragment>
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => ({
    openPopup: () => dispatch(Actions.pushView(Actions.VIEW.ModalUkraine)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Banner)
