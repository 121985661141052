import React from 'react';

import Div from '../../div';
import * as GameStatus from '../../gameStatus';

export default ({ game, style, isNotice }) => {
    const gameStatus = GameStatus.gameStatus(game.userFlags, game.gameFlags);
    if(gameStatus.msg !== null) {
        const fontSize = gameStatus.fg === 'fgWarn' ? 'fSmaller' : 'fSmall';

        return <Div setup='r end center' w='220' h={isNotice ? 65 : 25} class='fSmall padRightSmall'
                    style={style}>

            <Div w='220' class={`fBold ${gameStatus.fg} ${fontSize}`} style={{ textAlign: 'right' }}>
                { gameStatus.msg }
            </Div>
        </Div>
    } else {
        return null;
    }
}
