import { put, select } from 'redux-saga/effects'

import * as Requests from '../../requests';

export function* loadContentData() {
    const data = yield select(state => state.data);
    const user = yield select(state => state.user);
    
    if(!data.ices) {
        yield put(Requests.ices());
    }
    if(!data.games) {
        yield put(Requests.games());
    }

    if(user && user.player && user.player.isAdmin === true) {
        if(!data.players) {
            yield put(Requests.players());
        }
    }
}

