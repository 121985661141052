import React from 'react';

import Div from '../../../div';
import Button from '../../../button';

export default ({ mode, players, playersByStatus, setMode }) => {
    const verify = playersByStatus.verify.length;
    const noLevel = playersByStatus.hasNoLevel.length;

    const todo = [
        verify.toString(),
        ' / ' + noLevel.toString(),
        ' / ' + playersByStatus.isDisabled.length.toString()
    ];

    return <Div setup='r around center' wRel='100' css='ViewHeader'>
        <Button text={['Všetci', players.length.toString() ]}
                w='70' h='60' onClick={() => setMode(0)} controlled={true} on={mode===0} />

        <Button text={[ 'Todo:', 'nový / bez skillu / ban', todo ]}
                w='200' h='60' onClick={() => setMode(1)} controlled={true} on={mode===1} />
    </Div>
}
