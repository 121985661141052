import React from 'react';

import FormComponentRegistraion from './formComponentRegistration';
import Div from '../../div';
import Form from '../../form';
import * as Validation from '../../formValidation';
import * as Format from '../../../../utils/format';

const validation = {
    info: {
        firstName:          { required: true },
        lastName:           { required: true },
        tel:                { required: true, validate: Validation.telNumber },
        email:              { required: true }
    },
    reg: {
        skillBraking:       { required: true },
        skillTurning:       { required: true },
        skillTurningBack:   { required: true },
    }
}

function createData(info) {
    return {
        info: {
            firstName: info.firstName,
            lastName: info.lastName,
            email: info.email,
            tel: info.tel ? Format.telNumber(info.tel) : '+421'
        },
        reg: {
            ruleEquipment: false,
            ruleGames: false,
            didntRead: false,
            ruleAgreed: false,
            ruleMailinglist: false,
            emailNotifyDummy: true
        }
    }
}

export default class RegistrationForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: createData(props.player.info || { })
        }
    }

    render() {
        const onSubmit = this.props.onSubmit;
        const data = this.state.data;

        return <Div css='Form'>
            <Form data={data} validation={validation} id='cypress.reg'
                  onSubmit={onSubmit} submitText='Uložiť' noSubmitDelay={true}
                  invalidText='vyplň chýbajúce' submitWidth='100' invalidWidth='160'>
                <FormComponentRegistraion />
            </Form>
        </Div>
    }
}
