import React from 'react';
import { connect } from 'react-redux';

import GameItem from '../gameItemComponent';
import * as GameStatus from '../../gameStatus';
import * as Requests from '../../../requests';

const GameListContainer = ({ games, isAdmin, showGameDetail, confirm, toggle }) => 
    games.map((game, n) => 
        <GameItem key={game.gameKey} game={game} confirm={confirm} toggle={toggle}
                  isAdmin={isAdmin} showDetail={showGameDetail} />
    )

const mapStateToProps = state => ({
    confirm: game => GameStatus.confirmGameToggle(game, state.user.player)
});

const mapDispatchToProps = dispatch => ({
    toggle: game => dispatch(
        Requests.toggleGame(game.ice.date, game.ice.rink, game.gameDate, !game.userFlags.going)
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GameListContainer)
