import React from 'react';

import Div from '../../div';
import ProfileImage from '../../profileImage';
import SkillLevel from '../../skillLevel';
import * as GameStatus from '../../gameStatus';

export default ({ player, selectPlayer, inList }) => {
    const status = GameStatus.playerStatus(player);
    
    let symbol = status ? status.symbol : null;
    const symbolWidth = symbol ? (symbol.length > 1 ? (symbol.length > 2 ? 70 : 40) : 25) : 0;

    return <Div setup='r start center' wRel='100' id='cypress.content.playerItem'
        css={inList ? 'AdminItemListItemSmall' : 'AdminItemHeader'}
        class={`${inList ? 'clickable' : ''} ${status ? status.class : ''}`} 
        onClick={selectPlayer ? () => selectPlayer(player) : null}>

        <Div fx='50' h='50'>
            <ProfileImage css='ImgProfileBig' info={player.info} />
        </Div>

        <Div setup='c start start' wRel='100'
             class={'padLeftSmall ' + (player.isHidden ? 'fItalic' : 'null')}>
            <Div>{player.info ? player.info.lastName + ' ' + player.info.firstName : '(prazdne meno)' }</Div>
            <SkillLevel level={player.skillLevel} showInfoView={false} showStars={inList} />
        </Div>
        
        { status !== null
            ? <Div fx={symbolWidth} setup='r end center' class={`fMega ${status.class}`}>
                { symbol }
            </Div>
            : <Div w='8' />
        }
    </Div>
}
