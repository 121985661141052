// polyfills
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/fn/array/find';
import 'core-js/fn/array/includes';
import 'core-js/fn/number/is-nan';

import React from 'react';
import { render } from 'react-dom';

import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga'

import { GoogleOAuthProvider } from '@react-oauth/google';

/* middleware */
import sagas from './react/middleware/sagas';
import logger from './react/middleware/logger';

/* reducers */
import treeReducer from './utils/treeReducer';
import view from './react/reducers/view';
import session from './react/reducers/session';
import player from './react/reducers/player';
import version from './react/reducers/version';
import time from './react/reducers/time';
import ices from './react/reducers/ices';
import players from './react/reducers/players';
import games from './react/reducers/games';
import notify from './react/reducers/notify';
import lastAction from './react/reducers/lastAction';
import globalParams from './react/reducers/globalParams';

import getters from './react/reducers/getters';
import { ThirdParty } from './const';

import * as Actions from './react/actions';

/* main component */
import App from './react/components/app';

import MessengerWarning from './react/components/messengerWarning';

import './index.css';

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
    treeReducer({
        view,
        user: {
            session,
            player:     { _: player,    _args: 'session' }
        },
        version,
        time,
        data: {
            ices,
            players,
            games
        },
        notify,
        lastAction,
        globalParams,
        get: { _: getters, _args: [ 'view', 'user', 'data', 'time', 'globalParams' ] }
    }),
    applyMiddleware(
        logger,
        sagaMiddleware
    )
);

sagaMiddleware.run(sagas);

store.dispatch(Actions.init());

// /Android|iPhone|iPad|iPod/i.test(navigator.userAgent)
const fbEmbeddedBrowserUserAgentRegex = /\[.*(FBAN|FBAV).*\]/;
const isInMessenger = fbEmbeddedBrowserUserAgentRegex.test(navigator.userAgent);

render(
    isInMessenger
        ? <MessengerWarning />
        : <GoogleOAuthProvider clientId={ThirdParty.Google.AppId}>
            <Provider store={store}>
                <App />
            </Provider>
        </GoogleOAuthProvider>,
    document.getElementById('root')
);

if(global.window.Cypress) {
    global.window.__store__ = store;
    console.log('--- Cypress is set ---');
}
