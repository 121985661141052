import React from 'react';
import { connect } from 'react-redux';

import PlayerItem from './playerItem';
import Div from '../../../div';
import Button from '../../../button';
import * as Actions from '../../../../actions';
import * as Requests from '../../../../requests';
import { isPlayerTooStrong } from '../../../../../const';

const WeeklyPlayersContainer = ({ isAdmin, iceKey, getIce, getPlayer, onRemove, addPlayer, showPlayer }) => {
    const ice = getIce(iceKey);
    return <React.Fragment>
        { ice.autoJoinPos.map(
            (playerId, n) => {
                if(isAdmin) {
                    return  <PlayerItem key={n} ice={ice} player={getPlayer(playerId)}
                                        onRemove={player => onRemove(ice, player)} showPlayer={showPlayer} />
                } else {
                    const playerInfo = ice.playerInfo[ playerId ];
                    return  <PlayerItem key={n} n={n} ice={ice} playerInfo={playerInfo} />
                }
            }
        )}

        {
            isAdmin
            ? <Div setup='r around center' wRel='100'>
                <Div setup='r center center' h='60'>
                    <Button text='+ hráč' controller={true} on={false} onClick={() => addPlayer(ice, getPlayer) } />
                </Div>
                <Div w='130'>{`Počet hráčov: ${ice.autoJoinPos.length}`}</Div>
            </Div>
            : null }

    </React.Fragment>
}

const mapStateToProps = state => ({
    isAdmin: state.user.player.isAdmin,
    getIce: iceKey => state.get.iceByKey(iceKey.date, iceKey.rink),
    getPlayer: id => state.get.playerById(id)
});

const mapDispatchToProps = dispatch => ({
    onRemove: (ice, player) => {
        const name = player.info.lastName + ' ' + player.info.firstName;
        if(global.confirm(`Vyhodiť hráča ${name} z pravidelných?`)) {
            dispatch(
                Requests.editWeeklyPlayers(
                    ice.date,
                    ice.rink,
                    player.id,
                    false
                )
            )
        }
    },

    addPlayer: (ice, getPlayer) => dispatch(
        Actions.pushView(
            Actions.VIEW.Players,
            {
                playerFilter: playerId => {
                    const player = getPlayer(playerId);

                    return ice.autoJoinPos.indexOf(playerId) === -1
                           && !isPlayerTooStrong(player.skillLevel, ice.lvl);
                },

                selectPlayer: player => {
                    dispatch(
                        Actions.popView(null, Actions.VIEW.Players)
                    );

                    dispatch(
                        Requests.editWeeklyPlayers(
                            ice.date,
                            ice.rink,
                            player.id,
                            true
                        )
                    )
                }
            },
            true
        )
    ),

    showPlayer: player => dispatch(
        Actions.pushView(
            Actions.VIEW.PlayerDetail,
            { playerId: player.id },
            true
        )
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WeeklyPlayersContainer)
