import React from 'react';

import PlayerListContainer from './playerListContainer';
import Div from '../../../div';

export default ({ game, showPlayer, isAdmin }) => {
    const cancelledCount = game.cancelledOk.length + game.cancelledWarn.length;

    return <React.Fragment>
        <Div setup='c start start' wRel='100' id='cypressGamePlayers'>
            <Div setup='r center center' h='50' wRel='100' class='fgDark fBigger bgLighter'>
                {
                    (game.gameFlags.isLocked ? 'Súpiska: ' : 'Poradie: ') +
                    (game.players.length)
                }
            </Div>
            <Div wRel='100' class='padTopSmall padRightSmall'>
                <PlayerListContainer game={game}
                                     playerList={game.players}
                                     showPlayer={showPlayer}
                                     showPos={true} />
            </Div>
        </Div>

        { isAdmin && game.gameFlags.isLocked
            ? <Div setup='c start start' wRel='100' id='cypressGameBackups'>
                <Div setup='r center center' h='50' wRel='100' class='fgDark fBigger bgLighter' id='cypressGameBackups'>
                    <Div>Náhradníci: {game.backups.length}</Div>
                </Div>
                <Div wRel='100' class='padTopSmall padRightSmall'>
                    <PlayerListContainer game={game}
                                         playerList={game.backups}
                                         showPlayer={showPlayer}
                                         showPos={true} basePos={game.players.length} />
                </Div>
            </Div>
            : null
        }

        { isAdmin && game.tooStrong.length > 0
            ? <Div setup='c start start' wRel='100' id='cypressGameTooStrong'>
                <Div setup='r center center' h='50' wRel='100' class='fgDark fBigger bgLighter' id='cypressGameTooStrong'>
                    <Div>Silnejší náhradníci: {game.tooStrong.length}</Div>
                </Div>
                <Div wRel='100' class='padTopSmall padRightSmall'>
                    <PlayerListContainer game={game}
                                         playerList={game.tooStrong}
                                         showPlayer={showPlayer}
                                         showPos={true} basePos={game.players.length + game.backups.length} />
                </Div>
            </Div>
            : null
        }

        { game.gameFlags.isLocked && cancelledCount > 0
            ? <Div setup='c start start' wRel='100' id='cypressGameCancelled'>
                <Div setup='r center center' h='50' wRel='100' class='fgDark fBigger bgLighter'>
                    <Div>Odhlásení: {cancelledCount}</Div>
                </Div>
                <Div wRel='100' class='padTopSmall padRightSmall'>
                    <PlayerListContainer game={game} playerList={game.cancelledOk} showPlayer={showPlayer} />
                    <PlayerListContainer game={game} playerList={game.cancelledWarn} showPlayer={showPlayer} />
                </Div>
            </Div>
            : null
        }
    </React.Fragment>
}
