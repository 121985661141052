import React from 'react';

import { AppConst } from '../../../../../../const';

const levels = { };
AppConst.SkillLevel.forEach(l => levels[ l.key ] = l);

export default ({ name, value, onChange, isNew }) =>
    isNew
        ? <select style={{width: '100%', height: '30px'}} className='fMedium'
                  onChange={event => onChange(name, event.target.value)}>
            { AppConst.SkillLevel.map(
                level => <option key={level.key} value={level.key}>{level.label} úroveň</option>
            )}
        </select>
        : levels[ value ].label
