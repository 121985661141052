import React from 'react';

import Div from '../../../../div';

export function component(label, component, value, size) {
    return <Div setup={'r between center'} wRel='100' h={'25'}>
        <Div fx={size} class='Bold'>{label}</Div>
        <Div class='padRight' style={{textAlign: 'right'}}>
            {component}
            {value}
        </Div>
    </Div>
}

export function text(label, value, testBad = null, size) {
    const isBad = testBad ? testBad(value) : false;

    if(typeof value === 'boolean') {
        value = value ? 'áno' : 'nie';
    }

    return row(label, value, isBad, size);
}

export function row(label, value, isBad, size, big = false) {
    return <Div h={big ? undefined : '25'}
                setup={'r between ' + (big ? 'start' : 'center')} wRel='100'
                style={{ color: isBad ? 'red' : undefined, paddingTop: big ? '3px' : undefined }}>
        <Div fx={size} class='Bold'>{label}</Div>
        <Div class='padRight' style={{textAlign: (big ? 'left' : 'right')}}>{value}</Div>
    </Div>
}
