import { delay } from 'redux-saga';
import { call, put, take, select } from 'redux-saga/effects'
import dbg from 'debug';

import { log } from '../logger';
import * as Cookie from '../cookie';
import * as Actions from '../../actions';
import * as Requests from '../../requests';
import * as Selectors from '../../selectors';
import { DataEvent, AppConst } from '../../../const';

const debug = dbg('redux');

export function* handleInit() {
    const session = Cookie.readSession();

    if(session !== null) {
        log(debug, '<< User - session found >>');
        yield put(Actions.session(session));
        yield put(Requests.player());
    } else {
        log(debug, '<< User - login >>');
        yield put(Actions.loginView());
    }
}

export function* handleLoginToken(action) {
    log(debug, '<< User - sending token >>');
    yield put(Requests.session(action.token));

    const receivedAction = yield take(matchSessionReceived);
    log(debug, '<< User - session received >>');

    const session = {
        userId: receivedAction.data.event.userId,
        sessionId: receivedAction.data.event.sessionId
    };
    Cookie.writeSession(session);
    yield put(Actions.session(session));
    yield put(Requests.player());
}

export function* handleLogout() {
    log(debug, '<< User - logout >>');
    yield put(Requests.logout());
    Cookie.clearSession();
    yield put(Actions.init());
}

export function matchSessionErrorReceived(action) {
    return  action.type === Actions.TYPE.Received
            && action.data.type === DataEvent.Session.Session
            && (
                action.data.event.type === DataEvent.Session.TokenInvalid
                || action.data.event.type === DataEvent.Session.NoSession
            );
}

export function* handleSessionError() {
    log(debug, '<< User - session error >>');
    Cookie.clearSession();
    yield put(Actions.init());
}

export function matchPlayerReceived(action) {
    return  action.type === Actions.TYPE.Received
            && Selectors.isDataPlayer(action.data);
}

export function* handleUserPlayer(action) {
    const currentUser = yield select(state => state.user);
    const currentPlayer = currentUser ? currentUser.player : null;
    const userId = currentPlayer ? currentPlayer.id : null;

    if(userId) {
        if(Selectors.isDataCurrentPlayer(action.data, userId)) {
            log(debug, '<< User - player received >>');

            if(action.data.player.init === true) {
                yield put(Actions.init());
                return;
            }

            const player = Selectors.dataPlayer(action.data);
            const isNotReg = !player.isReg;
            if(isNotReg) {
                yield put(Actions.registrationView());
                return;
            }
            const isRegButDidntRead = player.isReg && player.reg.didntRead === true;
            if(isRegButDidntRead) {
                yield put(Actions.emptyView());
                yield call(delay, AppConst.UnsuccessfulRegistrationDelay);
                yield put(Actions.registrationView());
                return;
            }

            // registered
            if(player.isDisabled && !player.isAdmin) {
                yield put(Actions.errorView('Nastala chyba'));
            } else {
                const isContentView = yield select(state => state.get.view.isBaseContent());
                if(!isContentView) {
                    yield put(Actions.contentView());
                }
            }
        }
    }
}

export function* handleEnableUser(action) {
    const currentUser = yield select(state => state.user);
    const currentPlayer = currentUser ? currentUser.player : null;
    const userId = currentPlayer ? currentPlayer.id : null;

    if(userId) {
        if(Selectors.isDataCurrentPlayer(action.data, userId)) {
            const dataPlayer = Selectors.dataPlayer(action.data);
            if(dataPlayer && dataPlayer.isDisabled === false && currentPlayer && currentPlayer.isDisabled === true) {
                yield put(Actions.init());
            }
        }
    }
}

function matchSessionReceived(action) {
    return  action.type === Actions.TYPE.Received
            && action.data.type === DataEvent.Session.Session
            && action.data.event.type === DataEvent.Session.Session
}
