import React from 'react';
import { connect } from 'react-redux';

import GameItem from '../../gameItemComponent';
import * as GameStatus from '../../../gameStatus';

import * as Requests from '../../../../requests';
import * as Actions from '../../../../actions';
import { isPlayerStrong } from '../../../../../const';


const IceListContainer = ({ now, isAdmin, ices, confirm, toggle, isTooStrong, showAutoJoin }) => 
    ices.map((game, n) => {
            if(isTooStrong(game)) {
                game.userFlags.tooStrong = true;
            }
            return <GameItem isAdmin={isAdmin} now={now} key={n} game={game} confirm={confirm}
                             toggle={toggle} isIce={true} showAutoJoin={showAutoJoin} />
        }
    )

const mapStateToProps = state => ({
    now: state.time.now,
    isAdmin: state.user.player.isAdmin,
    isTooStrong: game => isPlayerStrong(state.user.player ? state.user.player.skillLevel : null, game.ice.lvl),
    confirm: game => {
        const isTooStrong = isPlayerStrong(state.user.player ? state.user.player.skillLevel : null, game.ice.lvl);
        return GameStatus.confirmWeeklyGameToggle(game, isTooStrong, state.get.lockedGameByIce);
    }
});

const mapDispatchToProps = dispatch => ({
    toggle: game => dispatch(
        Requests.toggleIceAuto(game.ice.date, game.ice.rink, !game.userFlags.going)
    ),

    showAutoJoin: ice => dispatch(
        Actions.pushView(
            Actions.VIEW.IceWeeklyPlayers,
            {
                iceKey: {
                    date: ice.date,
                    rink: ice.rink
                }
            }
        )
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IceListContainer)
