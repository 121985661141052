import { TYPE } from '../actions';
import clone from '../../utils/clone';

export default function session(state, action) {
    if(state === undefined) {
        return null;
    }

    switch(action.type) {
        case TYPE.Init:
            return null;

        case TYPE.Session:
            return clone(action.session);

        default:
            return clone(state);
    }
}
