import React from 'react';
import { connect } from 'react-redux';

import IceItem from '../../iceItemComponent';
import Div from '../../../div';

const IceListContainer = ({ ices, selectIce, showAutomatic }) =>
    <Div hRel='100' wRel='100' setup='c start start'>
        { ices.map(
            (ice, n) => <IceItem key={n} ice={ice} inList={true} selectIce={() => selectIce(ice)} />
        )}
    </Div>

const mapStateToProps = state => ({
    ices: state.get.ices()
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IceListContainer)
