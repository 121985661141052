import React from 'react';

import Div from "./div";
import TelNumber from "./telNumber";

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        this.props.onError(error, info);
    }

    render() {
        if (this.state.hasError) {
            return <Div fx='100' setup='c center center'>
                <Div>Nejde ti registrácia? Sorry za problémy.</Div>
                <Div>Zavolaj mi na <TelNumber tel='421904560820' /></Div>
            </Div>
        }
        return this.props.children;
    }
}
