import dbg from 'debug';

const logObject = dbg('treeReducer_Object');
const logProp = dbg('treeReducer_Property');
const logArg = dbg('treeReducer_Argument');

const ARG_KEY = '_args';
const VALUE_KEY = '_';

export default function treeReducer(tree) {
    return (state, action) => reduceObject(tree, state, action);
}

function reduceObject(obj, state, action, parentParam = { }) {
    function replaceResult(value) {
        resultState = value;
        end = true;
    }

    logObject(obj);
    logObject('state:', state);
    logObject('param:', parentParam);

    let resultState = { };
    let end = false;

    const keys = Object.keys(obj);

    for(let i = 0; i < keys.length; i++) {
        const key = keys[ i ];

        if(key !== ARG_KEY && resultState[ key ] === undefined) {
            reduceProperty(obj, key, resultState, parentParam, state, action, replaceResult);
            if(end) {
                break;
            }
        }
    }

    return resultState;
}

function reduceProperty(obj, key, resultState, parentParam, state, action, replaceResult) {
    logProp(key);

    const value = obj[ key ];
    const args = toArray(value[ ARG_KEY ]);
    const localParam = { };

    if(args) {
        args.forEach(
            arg => {
                if(obj[ arg ]) {
                    if(resultState[ arg ] === undefined) {
                        logArg('reduce:', arg);
                        reduceProperty(obj, arg, resultState, parentParam, state, action, replaceResult);
                    }
                    localParam[ arg ] = resultState[ arg ];                    
                } else {
                    localParam[ arg ] = parentParam[ arg ];
                }

                logArg(arg+':', localParam[ arg ]);
            }
        )
    }

    const isValueProp = key === VALUE_KEY;
    let result = undefined;

    let propState = 
        state !== undefined && state !== null
            ? (isValueProp
                ? state
                : state[ key ]
            )
            : state;

    if(typeof value === 'function') {
        logProp(key + ': function', propState, parentParam);
        result = value.call(null, propState, action, parentParam);
    } else {
        logProp(key + ': object', propState, localParam);
        result = reduceObject(value, propState, action, localParam);
    }
    logProp(key + ':', result);

    if(key === VALUE_KEY) {
        replaceResult(result);
    } else {
        resultState[ key ] = result;
    }
}

function toArray(value) {
    if(value !== undefined) {
        return Array.isArray(value) ? value : [ value ];
    } else {
        return value;
    }
}
