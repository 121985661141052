import React from 'react';

import Div from '../div';
import Icon from '../icon';
import { OpenMenuEvent, SwipeRightEvent } from '../../events';
import { Links, DataEvent } from '../../../const';

export default class Menu extends React.Component {
    state = {
        visible: false
    }

    constructor(props) {
        super(props);
        this.openMenu = this.openMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.showView = this.showView.bind(this);
        this.onSwipeRight = this.onSwipeRight.bind(this);
    }

    openMenu() {
        this.setState({ visible: true });
    }

    closeMenu() {
        this.setState({ visible: false });
    }

    showView(view) {
        this.closeMenu();
        this.props.showView(view);
    }

    onSwipeRight() {
        if(this.state.visible) {
            this.closeMenu();
        } else {
            if(this.props.canNavigateBack()) {
                this.props.navigateBack();
            }
        }
    }

    componentDidMount() {
        OpenMenuEvent.register(this.openMenu);
        SwipeRightEvent.register(this.onSwipeRight);
    }

    componentWillUnmount() {
        OpenMenuEvent.unregister(this.openMenu);
        SwipeRightEvent.unregister(this.onSwipeRight);
    }

    render() {
        const isAdmin = this.props.isAdmin;
        const views = this.props.views;
        const state = this.state;
        
        return <React.Fragment>
            { state.visible
                ? <Div css='MenuOverlay' onClick={this.closeMenu} hView='100' />
                : null }

            <Div setup='c start start' class='unselectable' css='Menu' style={style(state.visible)} hView='100'
                 onClick={this.closeMenu} id='cypress.menu'>
                
                {this.renderClose()}

                {this.renderHome('cypress.menu.home')}

                {this.renderView('Profil', views.playerProfile, 'cypress.menu.profile')}

                { isAdmin
                    ? this.renderView('Ľady', views.adminIces, 'cypress.menu.adminIces', 'ice')
                    : null }

                { isAdmin
                    ? this.renderView('Hráči', views.adminPlayers, 'cypress.menu.adminPlayers', 'players')
                    : null }

                { isAdmin
                    ? this.renderView('Mailing list', views.adminMailingList, 'cypress.menu.mailingList')
                    : null }

                { !isAdmin
                    ? this.renderLink(Links.CharvatInfo, 'Všeobecné info')
                    : null }

                { !isAdmin
                    ? this.renderLink(Links.CharvatRules, 'Pravidlá hokeja')
                    : null }

                { !isAdmin
                    ? this.renderView('Kontakt ', views.charvatContact, 'cypress.menu.contact')
                    : null }


                {this.props.isDev && views.log
                    ? this.renderView('<log>', views.log)
                    : null }

                {this.renderLogout('cypress.menu.logout')}
                
                { isAdmin
                    ? this.renderVersion(DataEvent.CurrentVersion)
                    : null }
            </Div>
        </React.Fragment>
    }

    renderClose(id = null) {
        return <Div fx='60' wRel='100' setup='r between center' class='mainMenuItem clickable' id={id}
                    onClick={this.closeMenu}>
            <Div w='160'><u>MENU:</u></Div>
            <Icon css='IconMedium' image='cancelLight' />
        </Div>
    }

    renderHome(id) {
        return <Div fx='60' setup='c center start' class='mainMenuItem clickable' id={id}
                    onClick={this.props.navigateToBaseView}>
            <Div>Domov</Div>
        </Div>
    }

    renderLink(url, label) {
        return <Div fx='60' setup='c center start' class='mainMenuItem clickable'>
            <Div>
                <a target='_blank' href={url}>{label}</a>
            </Div>
        </Div>
    }

    renderView(label, value, id, image = null) {
        return <Div fx='60' setup='r between center' wRel='100' class='mainMenuItem clickable'
                    onClick={() => this.showView(value)} id={id}>
            <Div>{label}</Div>

            { image
                ? <Icon css='IconMedium' image={image} class='margRightSmall' />
                : null
            }
        </Div>
    }

    renderVersion(version) {
        return <Div wRel='100' hRel='100' setup='r end end' class='fSmaller'>Verzia: {version}</Div>
    }

    renderLogout(id) {
        return <Div setup='r between center' wRel='100' h='100' id={id}
                    onClick={this.props.onLogout} class='mainMenuItem clickable'>
                <Div w='160'>Odhlásiť</Div>
                <Icon css='IconBig' image='logout' />
            </Div>
    }
}

function style(visible) {
    return {
        position: 'fixed',
        top: 0,
        right: 0,

        width: visible ? '100%' : '0px',
        transition: 'width 0.3s',

        overflowY: 'hidden',

        backgroundColor: '#444',
        color: true ? 'white' : '#444',
        borderLeft: visible ? '1px solid #aaa' : null,

        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: visible ? '10px' : null,
        paddingRight: visible ? '10px' : null,

        fontSize: '20px'
    };
}
