import React from 'react';

import Div from '../../../../div';
import padStart from '../../../../../../utils/padStart';

const opt = n => ({ key: n, label: padStart(n.toString(), 2, '0') });

const hours = [ ];
for(let i=0; i < 24; i++) hours.push(opt(i));

let minutes = [ ];
for(let i=0; i < 60; i++) minutes.push(opt(i));

export default ({ name, name2, value, onChange, isNew }) =>
    isNew
        ? <React.Fragment>
                <Div w='63'>
                    <select style={{width: '100%', height: '30px'}} className='fMedium'
                            onChange={event => onChange(name, parseInt(event.target.value, 10))}
                            value={value ? opt(value.getHours()).key : ''}>
                        { hours.map(
                            h => <option key={h.key} value={h.key}>{h.label}</option>
                        )}
                    </select>
                </Div>
                :
                <Div w='62'>
                    <select style={{width: '100%', height: '30px'}} className='fMedium'
                            onChange={event => onChange(name2, parseInt(event.target.value, 10))}
                            value={value ? opt(value.getMinutes()).key : ''}>
                        { minutes.map(
                            m => <option key={m.key} value={m.key}>{m.label}</option>
                        )}
                    </select>
                </Div>
        </React.Fragment>
        : opt(value.getHours()).label + ':' + opt(value.getMinutes()).label;
