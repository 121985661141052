import React from 'react';
import { connect } from 'react-redux';

import Header from './header';
import ListContainer from './listContainer';
import Div from '../../../div';
import Button from '../../../button';
import * as Actions from '../../../../actions';

const PlayerListView = ({ params, showHidden, closeView }) =>
    <Div hRel='100' wRel='100' setup='c start start'>
        <Header letter={params.letter} />

        <ListContainer showHidden={showHidden}
                       letter={params.letter}
                       selectPlayer={params.selectPlayer} />

        <Div css='ButtonSectionPadding' />
        <Div setup='r center center' class='fixedDown' wRel='100'>
            <Div css='ButtonSection' setup='r around center' class='container'>
                <Button text='Zavri' onClick={closeView} />
            </Div>
        </Div>
    </Div>

const mapStateToProps = state => ({
    showHidden: state.globalParams[ Actions.GLOBAL_PARAM.ShowDisabledPlayers ]
});

const mapDispatchToProps = dispatch => ({
    closeView: () => dispatch(Actions.popView())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerListView)
