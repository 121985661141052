import { connect } from 'react-redux';
import React from 'react';

import Grid from './grid';

const PlayerGridContainer = ({ getPlayerGrid, playerFilter, showPlayerList }) =>
    <Grid players={getPlayerGrid(playerFilter)} showPlayerList={showPlayerList} />

const mapStateToProps = state => ({
    getPlayerGrid: playerFilter => state.get.playerGrid(playerFilter)
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerGridContainer)
