import dbg from 'debug';

import * as Actions from '../actions';
import padStart from '../../utils/padStart';

const debug = dbg('redux');

let level = 0;

export default function logger(store) {
    return next => action => {
        const dataType = 
            (action.type === Actions.TYPE.Send || action.type === Actions.TYPE.Received)
                ? action.data.type
                : null;

        const type = action.type + (dataType ? ' - ' + dataType : '');
        const isView = type === 'Connection' || type === 'PopView' || type === 'PushView' || type === 'BaseView';

        if(!isView) {
            log(debug, '%s(%O)', type, action);
        }

        level++;
        let result = next(action);
        level--;
        
        if(!isView) {
            log(debug, '{ %O }', store.getState());
        }

        return result;
    }
}

export function log(...args) {
    const padding = padStart('', level*4, ' ');
    const logger = args[0];
    const newArgs = [ padding + args[1] ].concat(args.slice(2));
    logger.apply(logger, newArgs);
}
