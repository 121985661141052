import React from 'react';

import Div from './div';

export default class Collapsible extends React.Component {
    constructor(props) {
        super(props);
        
        this.toggle = this.toggle.bind(this);
        this.state = this.createState(props.expanded);
    }

    createState(expanded) {
        return {
            expanded,
            height: expanded ? this.props.maxHeight : this.props.minHeight
        }
    }

    toggle() {
        const expanded = !this.state.expanded;
        this.setState(
            this.createState(expanded)
        );

        if(this.props.setExpanded) {
            this.props.setExpanded(expanded);
        }
    }

    render() {
        const p = this.props;
        const s = this.state;

        return <Div style={{ transition: `height ${p.time || '0.35s'}` }}
                    h={s.height}
                    w={p.w} wRel={p.wRel} wView={p.wView}>
                { p.renderHeader(this.toggle) }
                { p.children }
        </Div>
    }
}
