import React from 'react';

import Div from '../../div';
import * as DateTime from '../../../../utils/dateTime';

export default ({ from, to, clss, vip }) => <React.Fragment>
        { vip ? <Div w='40' style={{fontWeight: 'bold', color: 'red'}}>VIP</Div> : null }
        <Div w='40' style={{fontWeight: 'bold'}}>{ day(from) }</Div>
        <Div class={clss}>{ description(from, to) }</Div>
    </React.Fragment>

function day(from) {
    return DateTime.titleDayOfWeek(from.getDay(), false);
}

function description(from, to) {
    const now = new Date();
    from = from < now ? null : from;

    return from && to
                ? dateInterval(from, to)
                : dateFrom(from, to) + dateTo(from, to)
}

function dateInterval(from, to) {
    if(from.getFullYear() === to.getFullYear() && from.getMonth() === to.getMonth()) {
        if(from.getDate() === to.getDate()) {
            return 'len ' + from.getDate() + '.' + (from.getMonth()+1);
        } else {
            return from.getDate() + '-' + to.getDate() + '.' + (to.getMonth()+1);
        }
    } else {
        return dateFrom(from, to) + '-' + dateTo(from, to);
    }
}

function dateFrom(from, to) {
    return from ? (to ? date(from) : 'od ' + date(from)) : '';
}

function dateTo(from, to) {
    return to ? (from ? date(to) : 'do ' + date(to)) : '';
}

function date(d) {
    return d ? (d.getDate()+'.'+(d.getMonth()+1)) : '';
}
