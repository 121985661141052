import React from 'react';

import * as Common from './common';
import * as Format from '../../../../../../utils/format';

export default ({ player }) => <React.Fragment>
    { player.verify
        ? Common.text('dátum registrácie:', player.reg.date ? Format.date(player.reg.date) : '')
        : null
    }

    { player.verify
        ? Common.text('vyplnil za:', Format.timeElapsed(player.reg.elapsed))
        : null
    }

    { player.verify || player.hadDidntRead === true
        ? <React.Fragment>
            { Common.text('zaškrtol chuja:', player.hadDidntRead || false, v => v === true, 200) }
        </React.Fragment>
        : null
    }

    { player.loginProfile
        ? Common.text('Účet:', player.loginProfile.email)
        : null
    }

    { player.verify || player.hadDidntRead
        ? <hr/>
        : null
    }
</React.Fragment>
