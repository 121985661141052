import React from 'react';

import { AppConst } from '../../../../../../const';

const levels = { };
AppConst.SkillLevel.forEach(l => levels[ l.key ] = l);

export default ({ value, confirm, update }) => {
    return  <input type='checkbox' className='fMedium' style={{width: '180'}} 
                   checked={value ? 'on' : ''} id='adminNonPlayer'
                   onChange={event => confirmChange(event, confirm, update, value)}>
    </input>
}

function confirmChange(event, confirm, update, value) {
    const newValue = !value;

    setTimeout(
        () => {
            if(confirm(newValue)) {
                update(newValue);
            }
        },
        0
    );
}
