import React from 'react';

const ruleButton = {
    textAlign: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    border: '1px solid transparent',
    color: 'white',
    fontSize: 20,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    backgroundColor: "#2697a6",
    borderRadius: '4px',
    width: '300px'
};

const ruleUrl = 'https://docs.google.com/document/d/1TKgea_rWF9rK8YhceTAcCWvEe-i4hTapPt2PRRGIje0/edit?fbclid=IwAR1tNuWRjEpQ6KlPlUW0mgC9avY44rWjvSBfi5GeOG9MxdKLfAnuz2-EPY0';

export default () => 
    <a href={ruleUrl} style={{textDecoration: 'none'}}>
        <div style={ruleButton}>Pravidlá</div>
    </a>
