import * as Format from '../../utils/format';

const telNumberRegex = new RegExp(/^\d{11,12}$/);

export function telNumber(value) {
    let parsed = Format.parseTelNumber(value);
    if(parsed === null || parsed === '') {
        return true;
    } else {
        return telNumberRegex.test(parsed);
    }
}

export function hasChanged(input, formResult, propName) {
    if(formResult[ propName ] === input[ propName ]) {
        delete formResult[ propName ];
        return 0;
    } else {
        const value = formResult[ propName ];
        if(value && typeof value === 'string') {
            formResult[ propName ] = value.trim();
        }
        return 1;
    }
}
