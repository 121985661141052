import React from 'react';
import { connect } from 'react-redux';

import GameFilterHeader from './gameFilter/gameFilterHeader';
import GameFilterBody from './gameFilter/gameFilterBody';
import WeeklyIcesContainer from './weeklyIcesContainer';
import GamesContainer from './gamesContainer';
import Div from '../../div';
import Collapsible from '../../collapsible';

import * as Actions from '../../../actions';
import * as Requests from '../../../requests';

const ContentView = ({
    isAdmin, showGameDetail, futureGamesByWeek, now,
    filterExpanded, setFilterExpanded, filterState, setFilterState, clearFilter,
    showSkillInfo
}) =>
    <Div setup='c start start' wRel='100' css='View' id='cypress.content'>
        <Collapsible wRel='100'
            expanded={filterExpanded} minHeight={60} maxHeight={260} setExpanded={setFilterExpanded}
            renderHeader={
                toggle => <Div h='60' wRel='100' onClick={toggle}>
                    <GameFilterHeader
                        expanded={filterExpanded} state={filterState}
                        toggleExpanded={toggle} clearFilter={clearFilter}
                    />
                </Div>
            }>
            <GameFilterBody
                state={filterState}
                updateState={setFilterState}  showSkillInfo={showSkillInfo}
            />
        </Collapsible>

        <WeeklyIcesContainer />
        <GamesContainer futureGamesByWeek={futureGamesByWeek} isAdmin={isAdmin} now={now}
                        showGameDetail={showGameDetail} />
        <Div wRel='100' fx='200' style={{ background: 'white', color: 'white' }}>.</Div>
    </Div>

const mapStateToProps = state => ({
    now: state.time.now,
    isAdmin: state.user.player.isAdmin,
    filterExpanded: state.globalParams[ Actions.GLOBAL_PARAM.GameFilterExpanded ],
    filterState: state.user && state.user.player ? state.user.player.gameFilter : null
});

const mapDispatchToProps = dispatch => ({
    showGameDetail: (isAdmin, game) => dispatch(
        Actions.pushView(
            isAdmin || (game.userFlags.isHelper && game.gameFlags.isToday)
                ? Actions.VIEW.GameDetailAdmin
                : Actions.VIEW.GameDetail, 
            {
                gameKey: {
                    iceDate: game.ice.date,
                    iceRink: game.ice.rink,
                    gameDate: game.gameDate
                }
            }
        )
    ),

    setFilterExpanded: expanded => dispatch(Actions.setGlobalParam(Actions.GLOBAL_PARAM.GameFilterExpanded, expanded)),

    setFilterState: state => dispatch(Requests.updatePlayerFilter(state)),
    
    clearFilter: () => dispatch(Requests.clearPlayerFilter()),

    showSkillInfo: () => dispatch(Actions.pushView(Actions.VIEW.SkillLevelInfo))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentView)
