export default function Delay(millis) {
    let handle = null;

    this.start = function(action) {
        this.cancel();
        handle = setTimeout(action, millis);
    }

    this.cancel = function() {
        if(handle !== null) {
            clearTimeout(handle);
            handle = null;
        }        
    }

}
