import React from 'react';

import Div from './div';
import Icon from './icon';
import ModalConfirm from './modal/modalConfirm';
import * as GamesStatus from './gameStatus';

export default class SwitchText extends React.Component {
    constructor(props) {
        super(props);
        this.click = this.click.bind(this);
        this.confirmed = this.confirmed.bind(this);
    }

    click(event) {
        event.stopPropagation();

        const p = this.props;
        const confirm = getConfirmArray(p.confirm);

        if(confirm.length > 0) {
            this.refs.modalConfirm0.open();
        } else {
            this.confirmed();
        }
    }

    confirmed(next = 0) {
        const p = this.props;
        const confirm = getConfirmArray(p.confirm);
        
        if(next < confirm.length) {
            this.refs['modalConfirm'+next].open();
        } else {
            if(p.delay) {
                setTimeout(p.onClick, p.delay);
            } else {
                p.onClick();
            }
        }
    }

    render() {
        const p = this.props;

        const width = p.width || 55;        
        const style = {
            width: width+'px',
            height: (p.height || 40)+'px',
            borderRadius: (p.radius || 20)+'px'
        }

        const toggleWidth = p.toggleWidth || 30;
        const toggleShiftBase = 5;
        const toggleShift = 23 + (width - 64);

        const toggleStyle = {
            width: toggleWidth+'px',
            height: (p.toggleHeight || 30)+'px',
            borderRadius: (p.toggleRadius || 15)+'px',
            marginLeft: (p.on ? toggleShiftBase + toggleShift : toggleShiftBase)+'px',
            transition: 'margin-left 0.5s'
        }

        const status = GamesStatus.switchStatus(p.on, p.maybe, p.warning);
        const icon = status.icon
            ? <Icon image={status.icon} class='iconSmall' />
            : null;

        return <React.Fragment>
            <Div setup='r between center' class={'unselectable ' + status.class} style={style} id='cypress.switch'
                    onClick={event => this.click(event)}>
                <Div setup='r center center' style={toggleStyle} class='bgWhite'>
                    { status.text
                        ? <Div class={'unselectable fBigger fBold ' + status.textClass} style={{ paddingTop: '1px' }}>
                            { status.text }
                        </Div>
                        : null
                    }
                    { icon }
                </Div>
            </Div>
            { getConfirmArray(p.confirm).map(
                (cfg, n) => {
                    const text = cfg.text || cfg;
                    return <ModalConfirm key={n} ref={'modalConfirm'+n} vertical={cfg.vertical} h={cfg.h} smallFont={cfg.smallFont}
                                text={text.info} onOk={this.confirmed.bind(this, n+1)}
                                textOk={text.ok} wOk={cfg.wOk} hOk={cfg.hOk}
                                textCancel={text.cancel} wCancel={cfg.wCancel} hCancel={cfg.hCancel} />
                }
            ) }
        </React.Fragment>
    }
}

function getConfirmArray(confirm) {
    return confirm ? (Array.isArray(confirm) ? confirm : [ confirm ]) : [ ];
}
