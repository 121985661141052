import React from 'react';

import './icon.css';
import * as Css from './css';

export default class Icon extends React.Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    onClick(event) {
        if(this.props.onClick) {
            if(event) {
                event.stopPropagation();
                event.nativeEvent.stopImmediatePropagation();
            }
            this.props.onClick(event);
        }
    }

    render() {
        const p = this.props;
        const isClickable = p.onClick !== null && p.onClick !== undefined;
        
        let className = 'image_' + p.image;
        if(p.class || p.css) {
            className += ' ' + (p.class ? p.class+' ' : '') + (p.css ? Css[ p.css ] : '');
        }

        return <div className={'unselectable ' + (isClickable ? 'clickable ' : '') + className} id={p.id}
                    onClick={this.onClick} />
    }
}
