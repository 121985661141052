import React from 'react';

import Div from '../../../div';
import Icon from '../../../icon';
import { AppConst } from '../../../../../const';

const style = {
    borderBottom: '1px solid gray'
}

export default ({ expanded, toggleExpanded, clearFilter, state }) => {
    const allRinkGroups = state.rinkGroup.length === 0;
    const allLevels = state.lvl.length === 0;
    const filterActive = !allRinkGroups || !allLevels;

    return <Div setup='r start center' wRel='100' hRel='100' style={style} class={`padLeftSmall ${filterActive ? 'bgFilterActive' : ''}`}>
        { filterActive
            ? <React.Fragment>
                <Div fx='40'>
                    <Icon css='IconNormal' image='cancelDark' onClick={clearFilter} />
                </Div>
                <Div fx='220' setup='c start start' wRel='100'>
                    <Div class='fBold fgFilterActive'>Filtrovanie ľadov aktívne:</Div>
                    <Div wRel='100'>
                        <span style={{ fontWeight: filterActive ? 'normal' : 'bold' }}>
                            iba:&nbsp;
                            <span className='fgDarkBlue'>
                                {rinkInfoArr(state.rinkGroup).concat(lvlInfoArr(state.lvl)).join(', ')}
                            </span>
                        </span>
                    </Div>
                </Div>
            </React.Fragment>
            : <Div fx='200' class='padLeft'>
                <Div class='fMedium'>Filter neaktívny</Div>
                <Div class='fSmall'>zobrazujú sa všetky ľady</Div>
            </Div>
        }
        <Div wRel='100' setup='r end center' style={{ marginRight: 3 }}>
            { expanded
                ? <Icon css='IconBig' image='simpleArrowUp' onClick={toggleExpanded} />
                : <Icon css='IconBig' image='simpleArrowDown' onClick={toggleExpanded} />
            }
        </Div>
    </Div>
}

function rinkInfoArr(rinkGroupKeys) {
    return rinkGroupKeys.map(rg => AppConst.RinkFilterGroupsByKey[rg].label);
}

function lvlInfoArr(levels) {
    const levelDef = AppConst.SkillLevelByKey;
    return levels.map(lvlKey => levelDef[lvlKey].label);
}

