import React from 'react';
import { connect } from 'react-redux';

import Div from '../div';
import Button from '../button';
import * as Actions from '../../actions';
import { DataEvent } from '../../../const';
import * as Format from '../../../utils/format';

const UNREG = DataEvent.GameEvent.UnregisteredPlayer;
const NAMES = 'playerContactsNames';
const EMAILS0 = 'playerContactsEmails0';
const EMAILS1 = 'playerContactsEmails1';
const EMAILS2 = 'playerContactsEmails2';
const TELNUMS = 'playerContactsTelNums';
const CALLTELNUMS = 'playerContactsCallTelNums';

const PlayerContactsView = ({ params, getGame, getIce, getPlayer, closeView }) => {
    const playerIds = getPlayerIds(params.iceKey, params.gameKey, getIce, getGame);

    const names = playerNames(playerIds, getPlayer);
    const emails = playerEmails(playerIds, getPlayer);
    const telNums = playerTelNums(playerIds, getPlayer);
    const callTelNums = playerCallTelNums(playerIds, getPlayer);

    return <Div setup='c start start' wRel='100' css='View'>
        <textarea id={EMAILS0} type='textarea' value={emails.join('\n')}
                  style={{ width: '96%', height: '50px' }} 
                  rows={emails.length} onChange={() => {}} />
        <textarea id={EMAILS1} type='textarea' value={emails.join(',')}
                  style={{ width: '96%', height: '50px' }} 
                  rows={emails.length} onChange={() => {}} />
        <textarea id={EMAILS2} type='textarea' value={emails.join(';')}
                  style={{ width: '96%', height: '50px' }} 
                  rows={emails.length} onChange={() => {}} />

        <Div setup='r around center' wRel='100' h={80}>
            <Button text='Kopírovať emaily' w='160' on={false} controlled={true}
                    onClick={() => copyToClipboard(EMAILS0)} />
            <Button text='..s ","' w='60' on={false} controlled={true}
                    onClick={() => copyToClipboard(EMAILS1)} />
            <Button text='..s ";"' w='60' on={false} controlled={true}
                    onClick={() => copyToClipboard(EMAILS2)} />
        </Div>

        <textarea id={NAMES} type='textarea' value={names.join('\n')}
                  style={{ width: '96%', height: '100px' }} 
                  rows={names.length+1} onChange={() => {}} />

        <Div setup='r center center' wRel='100' h={80}>
            <Button text='Kopírovať súpisku' w='200' on={false} controlled={true}
                    onClick={() => copyToClipboard(NAMES)} />
        </Div>

        <textarea id={TELNUMS} type='textarea' value={telNums.join('\n')}
                  style={{ width: '96%', height: '50px' }} 
                  rows={telNums.length+1} onChange={() => {}} />
        <textarea id={CALLTELNUMS} type='textarea' value={callTelNums.join('\n')}
                  style={{ width: '96%', height: '50px' }} 
                  rows={callTelNums.length+1} onChange={() => {}} />

        <Div setup='r center center' wRel='100' h={80}>
            <Button text='Kopírovať s medzerou' w='160' on={false} controlled={true}
                    onClick={() => copyToClipboard(TELNUMS)} />
            <Button text='... bez medzery' w='160' on={false} controlled={true}
                    onClick={() => copyToClipboard(CALLTELNUMS)} />
        </Div>

        <Div setup='c start start' class='padLeftSmall padRightSmall fBig' wRel='100'>
            <Div css='ButtonSectionPadding' />
            <Div setup='r center center' class='fixedDown' wRel='100'>
                <Div css='ButtonSection' setup='r around center' class='container'>
                    <Button text='Zavri' onClick={closeView} />
                </Div>
            </Div>
        </Div>
    </Div>
}

const mapStateToProps = state => ({
    getGame: gameKey => state.get.gameByKey(gameKey.iceDate, gameKey.iceRink, gameKey.gameDate),
    getIce: iceKey => state.get.iceByKey(iceKey.date, iceKey.rink),
    getPlayer: playerId => state.get.playerById(playerId)
});

const mapDispatchToProps = dispatch => ({
    closeView: () => dispatch(Actions.popView())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerContactsView)

function copyToClipboard(elementId, selectId = null) {
    const elem = global.document.getElementById(elementId);
    if(elem) {
        if(elem.focus) {
            elem.focus();
        }
        if(elem.select) {
            elem.select();
        }

        global.document.execCommand('copy');
    }
}

function getPlayerIds(iceKey, gameKey, getIce, getGame) {
    if(iceKey) {
        const ice = getIce(iceKey);
        return ice ? Object.keys(ice.autoJoin) : [ ];
    } else {
        const game = getGame(gameKey);
        return game ? game.players : [ ];
    }
}

function playerNames(playerIds, getPlayer) {
    return playerIds
                .map(
                    (playerId, n) => {
                        const isUnreg = playerId.indexOf(UNREG) === 0;
                        const player = isUnreg ? null : getPlayer(playerId);
                        
                        return (n+1).toString() + '. ' + (
                            isUnreg
                                ? playerId.substr(UNREG.length)
                                : player.info.lastName + ' ' + player.info.firstName
                        );
                    }
                )
}

function playerEmails(playerIds, getPlayer) {
    return playerIds
               .map(
                    (playerId, n) => {
                        const isUnreg = playerId.indexOf(UNREG) === 0;
                        const player = isUnreg ? null : getPlayer(playerId);
                        return isUnreg ? null : player.info.email;
                    }
                )
                .filter(email => email !== null);
}

function playerTelNums(playerIds, getPlayer) {
    return playerIds
               .map(
                    (playerId, n) => {
                        const isUnreg = playerId.indexOf(UNREG) === 0;
                        const player = isUnreg ? null : getPlayer(playerId);
                        return isUnreg ? null : player.info.tel;
                    }
                )
                .filter(tel => tel !== null)
                .map(tel => Format.telNumber(tel))
}

function playerCallTelNums(playerIds, getPlayer) {
    return playerIds
               .map(
                    (playerId, n) => {
                        const isUnreg = playerId.indexOf(UNREG) === 0;
                        const player = isUnreg ? null : getPlayer(playerId);
                        return isUnreg ? null : player.info.tel;
                    }
                )
                .filter(tel => tel !== null)
                .map(tel => Format.callTelNumber(tel))
}
