import React from 'react';
import { connect } from 'react-redux';

import PlayerContainer from './playerContainer';
import Div from '../../../div';
import * as Actions from '../../../../actions';

const PlayerDetailView = ({ params, closeView }) =>
    <Div css='View' hRel='100' wRel='100' setup='c start start' id='cypress.content.playerDetail'>
        <PlayerContainer playerId={params.playerId} closeView={closeView} />
    </Div>

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
   closeView: () => dispatch(Actions.popView())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerDetailView)
