import React from 'react';
import { connect } from 'react-redux';

import PlayerItem from './playerItem';
import UnregPlayerItem from './unregPlayerItem';
import { DataEvent } from '../../../../../const';

const PlayerListContainer = ({ game, playerList, getPlayer, filterPlayers = playerId => true, showPos, basePos=0 }) => {
    return playerList
        .filter(filterPlayers)
        .map(
             (playerId, i) => {
                const isUnregistered = playerId.indexOf(DataEvent.GameEvent.UnregisteredPlayer) === 0;
                return isUnregistered
                    ? <UnregPlayerItem key={playerId} playerId={playerId} game={game} pos={showPos ? basePos+i+1 : null} />
                    : <PlayerItem key={playerId} playerId={playerId} game={game} pos={showPos ? basePos+i+1 : null} />
            }
         );
}
    
const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerListContainer)
