import { TYPE } from '../actions';
import clone from '../../utils/clone';
import * as Selectors from '../selectors';

import { DataEvent } from '../../const';

export default function player(state, action, args) {
    if(state === undefined) {
        return null;
    }

    switch(action.type) {
        case TYPE.Init:
            return null;

        // update player game filter immediately on send, because we do not await a response
        case TYPE.Send:
            if( action.data
                && action.data.type === DataEvent.Player.Player
                && action.data.event
                && action.data.event.type === DataEvent.Player.UpdatePlayer
                && action.data.event.gameFilter) {
                const gameFilter = action.data.event.gameFilter;
                const player = state;
                
                if(player) {
                    const result = clone(player);
                    result.gameFilter = gameFilter;
                    return result;
                }
            }
            return clone(state);

        case TYPE.Received:
            if( args.session
                && Selectors.isDataCurrentPlayer(action.data, args.session.userId)
            ) {
                const player = Selectors.dataPlayer(action.data);
                return clone(player);
            } else {
                return clone(state);
            }

        default:
            return clone(state);
    }
}
