import React from 'react';

import Div from '../../../../div';
import Icon from '../../../../icon';

export default class UnregPlayerTextbox extends React.Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.clear = this.clear.bind(this);
        this.submit = this.submit.bind(this);

        this.state = { text: '' }
    }

    onChange(event) {
        this.setState({ text: event.target.value });
    }

    clear() {
        this.setState({ text: '' });
    }

    submit() {
        const name = this.state.text.trim();
        const playerId = this.props.getUnregId(name);

        const game = this.props.game;
        const inPlayers = game.players.indexOf(playerId) !== -1;
        const inBackups = game.backups.indexOf(playerId) !== -1;

        if(inPlayers) {
            global.alert('Už je v súpiske');
            return;
        }
        if(inBackups) {
            global.alert('Už je medzi náhradníkmi');
            return;
        }

        this.props.onSubmit(game, name);
        this.clear();
    }

    render() {
        return <Div setup='r start center' wRel='100' h='45'>
            <Div fx='42'>
                <Icon image='cancelDark' css='IconMedium' onClick={this.clear} />
            </Div>

            <Div setup='c start start' wRel='100'>
                <input type='text' maxLength={100} style={{ width: '100%', height: '25px', fontSize: '17px' }}
                       value={this.state.text} onChange={this.onChange} />
            </Div>

            <Div setup='r end center' fx='45'>
                { this.state.text !== ''
                    ? <Icon image='okDark' css='IconMedium' onClick={this.submit} />
                    : null
                }
            </Div>
        </Div>
    }
}
