import React from 'react';
import { connect } from 'react-redux';

import GameItem from '../../gameItemComponent';
import * as GameStatus from '../../../gameStatus';
import * as Requests from '../../../../requests';

const GameItemContainer = ({ player, game, confirm, toggle }) => 
    <GameItem game={game} isIce={false} confirm={confirm} toggle={toggle}
              isAdmin={player.isAdmin} isNonPlayer={player.isNonPlayer} />

const mapStateToProps = state => ({
    player: state.user.player,
    confirm: game => GameStatus.confirmGameToggle(game, state.user.player),
});

const mapDispatchToProps = dispatch => ({
    toggle: game => dispatch(
        Requests.toggleGame(game.ice.date, game.ice.rink, game.gameDate, !game.userFlags.going)
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GameItemContainer)
