import React from 'react';

import Readonly from './dateReadonly';
import * as DateTime from '../../../../../../utils/dateTime';

const COUNT = 8;

export default ({ now, name, value, valueStart, onChange, isNew, isEndDate, isDeleted, isPast, id }) => 
    (isNew || isEndDate) && !isDeleted && !isPast
        ? <select style={{width: '100%', height: '30px'}} className='fMedium' id={id}
                  value={value ? value.getTime().toString() : ''}
                  onChange={
                      event => onChange(name, parse(event.target.value))
                  }>

            { options(now, isEndDate ? valueStart : value, COUNT, isEndDate).map(
                date => <option key={date.key} value={date.key}>{date.label}</option>
            )}
        </select>
        : <Readonly date={value} />


function options(now, start, count, isEndDate) {
    if(isEndDate) {
        let endDateStart = new Date(start.getTime());
        while(endDateStart < now) {
            endDateStart.setDate(endDateStart.getDate() + 7);
        }
        return createDates(endDateStart, count, isEndDate);
    } else {
        const nowDay = (now.getDay()+6) % 7;
        const startDay = (start.getDay()+6) % 7;

        let diffDays = startDay - nowDay;
        if(diffDays < 0) {
            diffDays += 7;
        }

        const startDate = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() + diffDays,
            start.getHours(),
            start.getMinutes()
        );

        return createDates(startDate, count, isEndDate);
    }
}

function createDates(date, count, isEndDate) {
    const result = [];
    if(isEndDate) {
        result.push({ key: '', label: '---'});
    }

    date = new Date(date.getTime() - (date.getTime()%60000));
    DateTime.makeDates(date, count).forEach(
        d => result.push({
            key: d.getTime().toString(),
            label: d.getDate() + '.' + (d.getMonth() + 1)
        })
    );
    
    return result;
}

function parse(value) {
    return value ? new Date(parseInt(value, 10)) : null;
}
