import React from 'react';
import { connect } from 'react-redux';

import Ice from './ice';
import Div from '../../../div';
import Button from '../../../button';
import * as Requests from '../../../../requests';

const IceContainer = ({ now, iceKey, newIce, iceHolder, getIce, confirm, update, isDev }) => {
    const ice = newIce || getIce(iceKey);
    const isNew = newIce !== undefined;

    return <React.Fragment>
        <Ice ice={ice} isNew={isNew} iceHolder={iceHolder} confirm={confirm} update={update} now={now} isDev={isDev} />

        { !isNew && !ice.deleted
            ? <Div setup='r center center' h='60' wRel='100'>
                <Button text='Vymaž ľad'
                        onClick={() => update.deleteIce(iceHolder.ice)}
                        confirm={() => confirm.deleteIce(iceHolder.ice)}
                        delay={true} />
            </Div>
            : null
        }
    </React.Fragment>
}


const mapStateToProps = state => ({
    now: state.time.now,
    isDev: state.user.player.isDev,
    getIce: iceKey => state.get.iceByKey(iceKey.date, iceKey.rink)
});

const mapDispatchToProps = dispatch => ({
    confirm: {
        endDt: endDt => endDt !== null
            ? window.confirm(
                `Nastaviť trvanie ľadu do ${endDt.getDate()}.${endDt.getMonth()+1}.${endDt.getFullYear()} (vrátane)?`
            )
            : window.confirm('Nastaviť neobmedzené trvanie ľadu?'),

        deleteIce: () => global.window.confirm('Naozaj vymazať ľad?'),

        vip: (ice, newValue) => global.window.confirm(newValue ? 'Naozaj nastaviť ako VIP?' : 'Naozaj zrušiť VIP?')
    },

    update: {
        endDt: (ice, endDt) => {
            ice.endDt = endDt;
            dispatch(Requests.updateIce(ice));
        },

        vip: (ice, vipValue) => {
            ice.vip = vipValue;
            dispatch(Requests.updateIce(ice));
        },

        deleteIce: ice => {
            dispatch(Requests.deleteIce(ice));
        }
    }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IceContainer)
