import React from 'react';
import { connect } from 'react-redux';

import PlayerItem from '../playerItemComponent';

const PlayerList = ({ showHidden, letter, selectPlayer, getPlayers }) =>
    getPlayers(letter, showHidden)
        .map((player, n) =>
            <PlayerItem key={n} player={player} selectPlayer={selectPlayer} inList={true} />
        )

const mapStateToProps = state => ({
    getPlayers: (letter, showHidden) => state.get.playersByLetter(letter, showHidden)
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerList)
