import React from 'react';

import Div from '../../../div';
import * as Css from '../../../css';

export default ({ now, weekGames }) =>
    <Div setup='r center center' wRel='100' h='60'
         class='bgLighter fgDark fBig bordBottom' style={{ textAlign: 'center' }}>
         { Css.weekTitle(now, weekGames.year, weekGames.week) }
    </Div>
