import padStart from './padStart';

const telNumberCleanupRegex = new RegExp(/-|\+|\\|\/| /g);
const telNumberParseRegex = new RegExp(/^00/g);

export function telNumber(str) {
    const arr = str.split('');

    const parts = [];
    while(arr.length > 3) {
        parts.unshift(arr.splice(arr.length-3).join(''));
    }
    return `+${arr.join('')} ${parts.join(' ')}`;
}

export function callTelNumber(str, isUrl = false) {
    return `${isUrl ? 'tel:+' : '+'}${str}`;
}

export function parseTelNumber(str) {
    let trimmed = str !== null && str !== undefined  ? str.trim() : null;
    if(trimmed !== null) {
        const cleaned = trimmed.replace(telNumberCleanupRegex, '');
        return cleaned.replace(telNumberParseRegex, '');
    } else {
        return null;
    }
}

export function date(d, withTime = false) {
    const time = withTime
        ? padStart(d.getHours().toString(), 2, '0') + ':' + padStart(d.getMinutes().toString(), 2, '0')
        : null;
    return `${d.getDate()}.${d.getMonth()+1}.${d.getFullYear()}${time ? (' ' + time) : ''}`;
}

export function timeElapsed(seconds) {
    if(seconds === null || seconds === undefined) {
        return '?';
    }

    const min = Math.floor(seconds / 60);
    const sec = seconds % 60;
    return (min !== 0 ? min + ' min ' : '') + sec + ' s';
}
