import React from 'react';

import Div from '../../div';
import * as DateTime from '../../../../utils/dateTime';

export default ({ date, clss, isVip, isPast }) =>
    <React.Fragment>
        <Div w='60' style={{ fontWeight: 'bold' }}>{ dateDesc(date) }</Div>
        { isVip ? <Div w='40' style={{ fontWeight: 'bold', color: isPast ? '#C08080' : 'red' }}>VIP</Div> : null }
        <Div w='100' class={clss}>{ dayDesc(date) }</Div>
    </React.Fragment>

function dayDesc(date) {
    return DateTime.titleDayOfWeek(date.getDay(), true, true);
}

function dateDesc(date) {
    return date ? (date.getDate()+'.'+(date.getMonth()+1)) : '';
}
