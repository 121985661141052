import { TYPE } from '../actions';

import clone from '../../utils/clone';

export default function notify(state, action, args) {
    if(state === undefined) {
        return [ ];
    }

    switch(action.type) {
        case TYPE.Notify:
            if(action.push) {
                state.push(clone(action.push));
            } else {
                if(action.pop) {
                    state.unshift();
                }
            }
            return clone(state);

        default:
            return clone(state);
    }
}
