import React from 'react';

import DateDay from './dateDay';
import DateIce from './dateIce';
import Rink from './rink';
import Status from './status';
import PlayerCount from './playerCount';
import Helper from './helper';

import Div from '../../div';
import Icon from '../../icon';
import SkillLevel from '../../skillLevel';
import Switch from '../../switch';
import * as GameStatus from '../../gameStatus';
import ModalInfo from '../../modal/modalInfo';

import padStart from '../../../../utils/padStart';

export default class GameItem extends React.Component {
    render() {
        const p = this.props;
        
        const game = p.game;
        const isIce = p.isIce;
        const isAdmin = p.isAdmin;
        const isNonPlayer = p.isNonPlayer;
        const confirm = p.confirm ? p.confirm(game) : null;

        const toggle = isNonPlayer ? null : p.toggle;

        const status = !isIce && !isNonPlayer
            ? GameStatus.switchStatus(
                game.userFlags.going,
                game.userFlags.backups,
                game.userFlags.warning,
                game.userFlags.tooStrong
            )
            : null;
        
        const isCancelled = game.gameFlags.isCancelled;
        const isNotice = game.gameFlags.isNoticeEnabled;
        const showNotice = isCancelled || isNotice;
        const noticeMessage = showNotice
            ? (
                game.gameFlags.isCancelled
                    ? `Termín je zrušený - ${game.gameFlags.cancelledReason}`
                    : (
                        game.gameFlags.isNoticeEnabled
                            ? game.gameFlags.noticeText
                            : ''
                    )
            )
            : null;
        
        const noHelpers = game.ice.noHelpers !== undefined ? game.ice.noHelpers : game.gameFlags.noHelpers;
        const userTooStrong = game.gameFlags.userTooStrong;

        const showDetail = !isIce && (isAdmin || !isCancelled)
            ? p.showDetail
            : null;

        let leftSideHeight = 
            showNotice
                ? (showDetail
                    ? (isAdmin && noHelpers && !isCancelled ? 80 : 60)
                    : 60
                )
                : (showDetail
                    ? (isAdmin && noHelpers && !isCancelled ? 70 : 60)
                    : 60
                );

        if(status) {
            leftSideHeight += 15;
        }

        const headerStyle = game.gameFlags.isPast ? { color: 'silver' } : null;

        return <Div setup={`c ${isIce ? 'center' : 'start'} center`} wRel='100'
                    css={isNonPlayer  ? 'ListItemNonPlayer' : 'ListItem'} style={{ position: 'relative' }}
                    id='cypress.content.gameItem'>

            <Div setup='r between center' wRel='100' style={headerStyle}>
                <Div setup='r start center'>
                    { isIce
                        ? <DateIce now={p.now} date={game.gameDate} from={game.ice.startDt} isVip={game.ice.vip} />
                        : <DateDay date={game.gameDate} isVip={game.ice.vip} isPast={game.gameFlags.isPast} />
                    }
                </Div>
                <Div w='57'>
                    { time(game.gameDateOverride || game.gameDate) } 
                </Div>
            </Div>

            <Div setup='r between start' wRel='100' class='fSmall'>
                <Div setup='c around start' h={isIce ? 70 : leftSideHeight}
                     wRel={!isIce && showNotice ? '100' : undefined}>
                    <SkillLevel level={game.ice.lvl} split={true} showInfoView={true} size='Small' />

                    <Rink rink={game.ice.rink} />

                    { showDetail
                        ? <PlayerCount game={game} showDetail={showDetail} />
                        : null
                    }

                    { isIce
                        ? <Div setup='r start center'>
                            <Icon css='IconSmall' image='automaticDark' onClick={() => p.showAutoJoin(game.ice)} />
                            <Div css='UserDetail' class='padLeftSmall'>Pravidelní hráči: {game.ice.autoJoinPos.length}</Div>
                        </Div>
                        : null
                    }

                    { isAdmin
                        ? <Helper inList={isIce || showDetail} game={game} setHelper={() => p.setHelper(game)}
                                  removeHelper={() => p.removeHelper(game)}
                                  getPlayer={p.getPlayer} />
                        : null
                    }

                </Div>
                
                { (isIce || !isCancelled) && !userTooStrong
                    ? <Div setup='r end center'>
                        { !game.gameFlags.isPast && status && status.info
                            ? <Div class='padRightSmall'>
                                <ModalInfo image={status.statusImage} size='Normal' >
                                    { status.info() }
                                </ModalInfo>
                            </Div>
                            : null
                        }

                        { !game.gameFlags.isPast && toggle
                            ? <Switch onClick={() => toggle(game)} confirm={confirm}
                                    width={80}
                                    on={game.userFlags.going}
                                    maybe={game.userFlags.backups || game.userFlags.tooStrong}
                                    warning={game.userFlags.warning}
                                    withSymbol={true} />
                            : null
                        }
                    </Div>
                    : null
                }
            </Div>

            { !isIce && showNotice
                ? <Div setup={`r ${isCancelled ? 'center' : 'end'} center`} wRel='100'>
                    <Div class={`fgWarn fBold fMedium ${isCancelled ? '' : 'bgNotice'}`}>{ noticeMessage }</Div>
                </Div>
                : null
            }

            { !isIce && !game.gameFlags.isCancelled && status
                ? <Status game={game}
                          style={{
                            position: 'absolute',
                            bottom: '3px',
                            right: '0px'
                          }}
                          isNotice={isNotice}
                  />
                : null
            }
        </Div>
    }
}

function time(d) {
    return d.getHours() + ':'
           + padStart(d.getMinutes().toString(), 2, '0');
}
