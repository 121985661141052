import Cookies from 'universal-cookie';

import { SessionCookie } from '../../const';

const cookies = new Cookies();

export function readSession() {
    const sessionCookie = cookies.get(SessionCookie.name);
    
    if(sessionCookie && sessionCookie.userId && sessionCookie.sessionId) {
        return sessionCookie;
    } else {
        return null;
    }
}

export function writeSession(session) {
    write(SessionCookie.name, session);
}

export function clearSession() {
    cookies.remove(SessionCookie.name);
}

function write(name, value) {
    const date = new Date();
    date.setFullYear(date.getFullYear() + SessionCookie.lifeYears);
    const options = { path: '/', expires: date };
    cookies.set(name, value, options);
}
