import React from 'react';
import { connect } from 'react-redux';

import WeeklyPlayersContainer from './weeklyPlayersContainer';
import IceItem from '../../iceItemComponent';
import Div from '../../../div';
import Button from '../../../button';
import * as Actions from '../../../../actions';
import * as Requests from '../../../../requests';

const IceListView = ({ params, isAdmin, getIce, selectIce, createIce, closeView, helperActions, showContacts, getPlayer }) => {
    const ice = getIce(params.iceKey);
    
    helperActions.getPlayer = getPlayer;
    helperActions.show = true;

    return <Div hRel='100' wRel='100' setup='c start start'>
        <IceItem ice={ice} inList={false} overrideInList={true} helperActions={helperActions} isAdmin={isAdmin} />

        <Div setup='r center center' h='50' wRel='100' class='fgDark fBigger bgLighter margBottomSmall'>
            Pravidelní hráči: {ice.autoJoinPos.length}
        </Div>

        <WeeklyPlayersContainer iceKey={params.iceKey} />

        <Div css='ButtonSectionPadding' />
        <Div setup='r center center' class='fixedDown' wRel='100'>
            <Div css='ButtonSection' setup='r around center' class='container'>
                <Button text='@' onClick={() => showContacts(ice)} />
                <Button text='Zavri' onClick={closeView} />
            </Div>
        </Div>
    </Div>
}

const mapStateToProps = state => ({
    isAdmin: state.user.player.isAdmin,
    getPlayer: state.get.playerById,
    getIce: iceKey => state.get.iceByKey(iceKey.date, iceKey.rink)
});

const mapDispatchToProps = dispatch => ({
    closeView: () => dispatch(Actions.popView()),

    helperActions: {
        setHelper: ice => dispatch(
            Actions.pushView(
                Actions.VIEW.Players,
                {
                    playerFilter: playerId => playerId !== ice.dedicatedHelperId,

                    selectPlayer: player => {                
                        dispatch(
                            Actions.popView(null, Actions.VIEW.Players)
                        );

                        dispatch(
                            Requests.setIceAutoDedicatedHelper(ice.date, ice.rink, player.id)
                        )
                    }
                }
            )
        ),

        removeHelper: ice => dispatch(
            Requests.setIceAutoDedicatedHelper(ice.date, ice.rink, null)
        )
    },

    showContacts: ice => dispatch(
        Actions.pushView(
            Actions.VIEW.PlayerContacts,
            {
                iceKey: {
                    date: ice.date,
                    rink: ice.rink
                }
            }
        )
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IceListView)
