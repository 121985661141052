import React from 'react';
import { connect } from 'react-redux';

import Div from '../div';

const ErrorView = ({ params }) =>
    <Div setup='c center center' wRel='100' hView='100' id='cypress.error'>
           {params.message}
    </Div>

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorView)
