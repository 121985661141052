import React from 'react';
import { connect } from 'react-redux';

import Div from '../div';
import TelNumber from '../telNumber';
import ProfileImage from '../profileImage';
import SmsNumber from '../smsNumber';
import ModalInfo from '../modal/modalInfo';
import * as DateTime from '../../../utils/dateTime';
import * as Requests from '../../requests';

class PlayerContextMenu extends React.Component {
    constructor(props) {
        super(props);
        this.onOpen = this.onOpen.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    onOpen() {
        this.props.enableUnloadEvent(false);
        this.refs.modal.onOpen();
    }

    onClose() {
        this.props.enableUnloadEvent(true);
    }

    render() {
        const props = this.props;
        const game = props.gameKey ? props.getGame(props.gameKey) : null;
        const isPast = game ? game.gameFlags.isPast : false;

        const ice = props.iceKey ? props.getIce(props.iceKey) : null;

        const player = props.player;     
        const playerName = player.info ? player.info.lastName + ' ' + player.info.firstName : '';
        const tel = player.info ? player.info.tel : null;
        
        const isHelper =
            props.isAuto
                ? (ice.helpers[ player.id ] ? true : false)
                : (game.helpers[ player.id ] ? true : false);

        const iceDate = props.isAuto ? ice.date : game.ice.date;
        const iceRink = props.isAuto ? ice.rink : game.ice.rink;
        const gameDate = props.isAuto ? null : game.gameDate;
        const descDate = props.isAuto ? ice.startDt : game.gameDate;

        const toggleHelper = props.isAuto ? props.toggleHelperIce : props.toggleHelperGame;

        const inPlayers = props.isAuto ? true : game.players.indexOf(player.id) !== -1;

        return <ModalInfo ref='modal' noImage={true} contentSize='200' onClose={this.onClose}>
            <Div setup='c start center' h='240' wRel='100'>
                <Div setup='r center center' h='60' wRel='100' class='bgOn fgBlack'>
                    <Div>{playerName}</Div>
                </Div>
                <Div setup='r start center' h='60' wRel='100' onClick={props.showPlayer}>
                    <ProfileImage info={player.info} css='ImgProfileBig' />
                    <Div setup='r center center' wRel='100'>
                        <Div>[ Otvor profil ]</Div>
                    </Div>
                </Div>
                { tel 
                    ? <Div setup='r center center' h='60'>
                        Tel:<TelNumber tel={tel} />
                    </Div>
                    : null
                }
                { tel 
                    ? <Div setup='r center center' h='60'>
                        sms:<SmsNumber tel={tel} />
                    </Div>
                    : null
                }

                { inPlayers && !isPast
                    ? <Div class='fNormal'>
                        <br />
                        <label>
                            {'Šatniar ' + helperCheckLabel(props.isAuto, descDate)}
                            <input type='checkbox' checked={isHelper} value='dummy'
                                   onChange={() => toggleHelper(iceDate, iceRink, gameDate, player.id, !isHelper)} />
                        </label>
                    </Div>
                    : null
                }
            </Div>
        </ModalInfo>
    }
}

function helperCheckLabel(isAuto, date) {
    return isAuto
        ? DateTime.titleDayOfWeek(date.getDay()) + ' ('  + DateTime.titleTime(date) + ')'
        : DateTime.titleDayOfWeek(date.getDay(), true, true) + ' ' + DateTime.titleDate(date) + ' (' + DateTime.titleTime(date) + ')';
}

const mapStateToProps = state => ({
    getGame: gameKey => state.get.gameByKey(gameKey.iceDate, gameKey.iceRink, gameKey.gameDate),
    getIce: iceKey => state.get.iceByKey(iceKey.date, iceKey.rink)
});

const mapDispatchToProps = dispatch => ({
    toggleHelperIce: (iceDate, iceRink, gameDate, playerId, value) => dispatch(
        Requests.toggleIceAutoHelper(iceDate, iceRink, playerId, value)
    ),

    toggleHelperGame: (iceDate, iceRink, gameDate, playerId, value, ) => dispatch(
        Requests.toggleGameHelper(iceDate, iceRink, gameDate, playerId, value)
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(PlayerContextMenu)
