import React from 'react';

import Div from '../../../div';
import PlayerListContainer from './playerListContainer';

export default ({ game }) =>
    <React.Fragment>
        <Div setup='c start start' wRel='100' id='cypressGamePlayers'>
            <Div setup='r center center' h='50' wRel='100' class='fgDark fBigger bgLighter'>    
                { (game.gameFlags.isLocked
                    ? 'Súpiska: '
                    : 'Poradie: ')
                  + game.players.length }
            </Div>
            <Div wRel='100' class='padTopSmall padRightSmall padBottomSmall'>
                <PlayerListContainer game={game} playerList={game.players} showPos={true} />
            </Div>
        </Div>
        
        { game.cancelledAll.length > 0
            ? <Div setup='r center center' h='50' wRel='100' class='fgDark fBigger bgLighter'>
                <Div>Odhlasovali sa: {game.cancelledAll.length}</Div>
            </Div>
            : null
        }

        <Div wRel='100' class='padTopSmall padRightSmall padBottomSmall'>
            <PlayerListContainer game={game} playerList={game.cancelledAll} />
        </Div>
    </React.Fragment>
