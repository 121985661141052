import { delay } from 'redux-saga'
import { take, call, race, select, put } from 'redux-saga/effects'
import dbg from 'debug';

import * as Notify from './notify';
import * as User from './user';
import Socket from '../wsSocketIO';
import { log } from '../logger';
import { PageReloadEvent } from '../../events';
import * as Actions from '../../actions';
import { ApiConst, DataEvent } from '../../../const';

const debug = dbg('redux');

const socket = new Socket();

export function* produceReceivedAction() {
    while(true) {
        const data = yield call(socket.receive);
        
        const isSyncResponse = data.type === DataEvent.SyncResponse;
        if(isSyncResponse) {
            if(data.reload === true) {
                log(debug, '<< Api - app version differs, reloading >>');
                PageReloadEvent.emit();
                global.document.location.reload();
                break;
            } else {
                if(data.init === true) {
                    log(debug, '<< Api - data timestamp differs, initializing >>');
                    yield put(Actions.init());
                    continue;
                } else {
                    if(data.syncData && data.syncData.length > 0) {
                        // unpack sync data and put as received actions
                        for(let i=0; i < data.syncData.length; i++) {
                            const dataObj = data.syncData[i];
                            dataObj.ver = data.ver;
                            dataObj.ts = data.ts;

                            const syncAction = {
                                type: Actions.TYPE.Received,
                                data: dataObj
                            };

                            yield put(syncAction);
                        }
                        continue;
                    }
                }
            }
        }

        yield put(Actions.connection(Actions.CONNECTION.Connected));
        
        const action = {
            type: Actions.TYPE.Received,
            data
        };

        // notify can compare the data before reducers are run
        yield call(matchAndHandleNotify, action)
        
        // user that was enabled must reinit
        yield call(matchAndHandleEnableUser, action);

        yield put(action);
    }
}

function* matchAndHandleNotify(action) {
    if(Notify.matchGamesDiff(action)) {
        yield call(Notify.handleGamesDiff, action);
    }
}

function* matchAndHandleEnableUser(action) {
    if(User.matchPlayerReceived(action)) {
        yield call(User.handleEnableUser, action);
    }
}

export function* produceErrorAction() {
    while(true) {
        yield call(socket.error);
        log(debug, '<< Api - error >>');
        yield put(Actions.connection(Actions.CONNECTION.Reconnect));
    }
}

export function* handleSend(action) {
    const user = yield select(state => state.user);
    const version = yield select(state => state.version);

    const session = user ? user.session : null;
    sendData(action.data, session, version);
    
    // logout, feedback and game filter update requests have no response
    const noResponseFlag = action.data && action.data.flags ? action.data.flags.noResponse : false;
    const awaitResponse = !noResponseFlag;
    
    if(awaitResponse) {
        yield race({
            sending: call(delayedSendingView),
            received: call(receivedData)
        })
    }
}

function sendData(data, session, version) {
    log(debug, '<< Api - send >> %O', data);
    
    if(session) {
        data.userId = session.userId;
        data.sessionId = session.sessionId;
    }
    
    if(version) {
        data.ver = version.version;
        data.ts = version.timestamp ? new Date(version.timestamp.getTime()) : version.timestamp;
    }

    socket.send(data);
}

function* delayedSendingView() {
    yield call(delay, ApiConst.SendingDelay);
    yield put(Actions.connection(Actions.CONNECTION.Sending));
    yield call(delay, ApiConst.SendingErrorDelay);
    yield put(Actions.connection(Actions.CONNECTION.Reconnect));
}

function* receivedData() {
    yield take(Actions.TYPE.Received);
}
