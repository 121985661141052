import React from 'react';
import { connect } from 'react-redux';

import IceContainer from './iceContainer';
import Div from '../../../div';
import Button from '../../../button';
import * as Actions from '../../../../actions';
import * as Requests from '../../../../requests';

const IceView = ({ params, confirmCreateIce, createIce, closeView }) => {
    const isNew = params.newIce !== undefined;
    const iceHolder = { ice: null };

    return <Div css='View' hRel='100' wRel='100' setup='c start start'>
        <IceContainer iceKey={params.iceKey} newIce={params.newIce} iceHolder={iceHolder} />

        <Div css='ButtonSectionPadding' />
        <Div setup='r center center' class='fixedDown' wRel='100'>
            <Div css='ButtonSection' setup='r around center' class='container'>
                { isNew
                    ? <Button text='Vytvor ľad'
                              onClick={() => createIce(iceHolder.ice)}
                              confirm={confirmCreateIce}
                              delay={true} />
                    : null
                }

                <Button text='Zavri' onClick={closeView} />
            </Div>
        </Div>
    </Div>
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
    confirmCreateIce: ice => window.confirm('Naozaj vytvoriť nový ľad?'),

    createIce: ice => {
        dispatch(Requests.createIce(ice));
        dispatch(Actions.popView());
    },

    closeView: () => dispatch(Actions.popView())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IceView)
