import React from 'react';

import Div from '../../../../div';

export default ({ date }) =>
    date
        ? <React.Fragment>
              <Div w='30'>{date.getDate()}.</Div>
              <Div w='30'>{date.getMonth()+1}.</Div>
              <Div w='45'>{date.getFullYear()}</Div>
        </React.Fragment>
        : ' ---'
