import React from 'react';
import { connect } from 'react-redux';

import Div from '../div';
import Button from '../button';
import * as Actions from '../../actions';

const SkillLevelInfo = ({ closeView }) =>
    <Div hView='100' class='padLeftSmall padRightSmall'>
        <Div class='fBig'><p>Úrovne sa delia do 5 skupín</p></Div>

        <p><b>Ľahká</b></p>
        <p>Sú hráči, ktorí ešte môžu mať problém aj so samotným korčuľovaním. Posielajú nepresné prihrávky, alebo majú problém spracovať prihávky cez pol ihriska, lebo im puk odskočí.</p>
        <p>Takmer vôbec sa v hre nerieši kde stoja, len približne, kto je obrana a útok. Ale aj tak sa obranca môže ocitnúť hocikde, väčšinou tak, kde ide puk.</p>
        <p>Väčšinou sú to hráči, čo hrali v detstve na rybníkoch, potom dlho nič, no ostali im príjemné spomienky a teraz objavili, že je príležitosť si niekde zahrať, napriek ich skillu.</p>

        <p><b>Ľahšia / stredná</b></p>
        <p>Ľahký stred by korčuľovanie mali zvládať, ale nebudú ešte úplne obratní, napríklad korčuľovanie vzad (prekladanie) im ešte robí problém. Ak ho zvládajú, tak možno len do jednej strany, ale už do druhej nie.</p>
        <p>Postavenie v hre na postoch (ľavé krídlo, ľavý obranca) ešte nie je veľmi organizované. Obrana vie, že je vzadu, ale to môže byť všetko. Ak vyjde po sebe 4-5 nahrávok v útoku, tak je to fajn. Stále to môže byť naháňačka všetci za pukom po celko ihrisku.</p>
        <p>Ale už sa na striedačke obvykle nájde niekto, kto hráčov upozorňuje, keď sa ocitnú mimo svojich postov. Ľahký stred sú chalani na tých rybníkoch z detstva, čo ím to išlo trochu lepšie.</p>

        <p><b>Stredná úroveň</b></p>
        <p>Ak má niekto talent a zdatnosť, že aj v dospelosti sa hýbe rýchlo cez úrovňe ľahká na začiatku, ľahký stred, tak sa rýchlo dopracuje aj na strednú úroveň, či lepší stred.</p>
        <p>Na strednej úrovni by mal hráč mať prehľad. V momente, keď dokorčuluje k puku pri mantineli, tak vie, kde má hráčov a prihráva im. Dokáže v rýchlosti prebrať aj menej presnú prihrávku a pokračovať v breaku. V obrane sa mu nestane, že prepadne.</p>
        <p>Spracovanie prihrávky je rýchle, ak vidí príležitosť prihráva, alebo strieľa, nepotrebuje ešte 3x premiešať puk. Má hlavu hore, keď korčuluje s pukom. V detstve / doraste možno chodil na hokejové tréningy.</p>

        <p><b>Lepšia stredná úroveň</b></p>
        <p>Keďže sa na túto úroveň nedosahujem ani okrajovo, nechodím na takú hrávať, nebudem o nej mudrovať. Na tejto úrovni sa vyskytujú aj bývalí hokejisti, ktorí už skončili kariéru a hrávajú už nesúťažne a pre radosť.</p>

        <p><b>Lepšia úroveň</b></p>
        <p>Sú väčinou bývalí hokejisti.</p>

        <Div css='ButtonSectionPadding' />
        <Div setup='r center center' class='fixedDown' wRel='100'>
            <Div css='ButtonSection' setup='r around center' class='container'>
                <Button text='Zavri' onClick={closeView} />
            </Div>
        </Div>
    </Div>

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => ({
    closeView: () => {
        dispatch(Actions.popView());
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SkillLevelInfo)
