import React from 'react';
import { connect } from 'react-redux';

import PlayerContextMenu from '../../playerContextMenu';
import Div from '../../../div';
import Icon from '../../../icon';
import ProfileImage from '../../../profileImage';
import * as Actions from '../../../../actions';

class PlayerItem extends React.Component {
    constructor(props) {
        super(props);
        this.onPlayerClick = this.onPlayerClick.bind(this);
    }

    onPlayerClick() {
        this.refs.contextMenu.onOpen();
    }

    render() {
        const props = this.props;
        const player = props.player;
        const onRemove = props.onRemove;
        const showPlayer = props.showPlayer;

        const warn = [];
        const info = player ? player.info : props.playerInfo;
        let style = null;
        
        if(player) {
            const isHelper = props.ice.helpers[ player.id ];
            if(isHelper) {
                warn.push('pravidelný šatniar');
            }
            if(player.isAdmin) {
                warn.push('admin');
            }
            style = (isHelper || player.isAdmin) ? { color: 'blue' } : null;
        }

        return <Div setup='r start center' wRel='100' h='47'>
            <Div fx='50'>
                { player
                    ? <Icon image='cancelDark' css='IconMedium' onClick={() => onRemove(player)} />
                    : <Div class='fMega padLeftSmall'>{(props.n+1)+'.'}</Div>
                }
            </Div>

            <Div setup='c start start' wRel='100' onClick={player ? this.onPlayerClick : null} style={style}>
                <Div wRel='100' style={{ textAlign: 'center' }}>{info.name ? info.name : info.lastName + ' ' + info.firstName }</Div>
                { warn.length > 0
                    ? <Div class='fSmall' wRel='100' style={{ textAlign: 'center' }}>{warn.join(', ')}</Div>
                    : null
                }
            </Div>

            <Div fx='50' class='clickable' onClick={player ? () => showPlayer(player) : null}>
                <ProfileImage info={info} css='ImgProfileMedium' />
            </Div>

            { player
                ? <PlayerContextMenu ref='contextMenu' isAuto={true} player={player} showPlayer={() => showPlayer(player)}
                                     enableUnloadEvent={props.enableUnloadEvent}
                                     iceKey={{ date: props.ice.date, rink: props.ice.rink }} />
                : null }
        </Div>
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
    enableUnloadEvent: enabled => dispatch(
        Actions.setGlobalParam(
            Actions.GLOBAL_PARAM.UnloadEventEnabled,
            enabled
        )
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerItem)
