import React from 'react';
import { connect } from 'react-redux';

import GameItem from './gameItemComponent';
import Div from '../div';
import * as Actions from '../../actions';

const NonPlayerContentView = ({ playerId, getNonPlayerGames, showGameDetail }) => {
    const games = getNonPlayerGames(playerId);

    return <Div setup='c start start' wRel='100' id='cypress.nonPlayerContent'>
        { games.map(
            (game, n) => <GameItem key={game.gameKey} game={game} isAdmin={false} showDetail={() => showGameDetail(game)} />
        )}
    </Div>
}

const mapStateToProps = state => ({
    playerId: state.user.player.id,
    getNonPlayerGames: playerId => state.get.nonPlayerGames(playerId)
});

const mapDispatchToProps = dispatch => ({
    showGameDetail: game => dispatch(
        Actions.pushView(
            Actions.VIEW.GameDetailAdmin,
            {
                gameKey: {
                    iceDate: game.ice.date,
                    iceRink: game.ice.rink,
                    gameDate: game.gameDate
                }
            }
        )
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NonPlayerContentView)
