import React from 'react';

import Div from '../../../div';
import ProfileImage from '../../../profileImage';

export default ({ playerId, game, pos }) => {
    const flags = game.playerFlags[ playerId ]; 
    const style = flags.warning ? { fontStyle: 'italic' } : undefined;

    return <Div setup='r start center' wRel='100'>
        { pos !== null
            ? <Div fx='50' class='fMega' style={{ textAlign: 'center' }}>{pos}.</Div>
            : <Div fx='50' />
        }
        <Div setup='c start start' style={style} wRel='100'>
            <Div>{flags.name}</Div>
            { flags.warning
                ? <Div class='fSmall'>nejde a hľadá náhradníka</Div>
                : null
            }
        </Div>
        <ProfileImage info={{ imageUrl: flags.imageUrl }} css='ImgProfileMedium' />
    </Div>
}
