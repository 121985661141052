import * as Actions from './actions';
import * as Format from '../utils/format';
import { DataEvent } from '../const';

export function sync() {
    return wrap({
        type: DataEvent.Sync
    });
}

export function session(token) {
    return wrap({
        type: DataEvent.Session.Session,
        userId: token.userId,
        event: {
            type: DataEvent.Session.Token,
            token: token.userToken,
            loginProfile: token.loginProfile
        }
    });
}

export function logout() {
    return wrap({
        type: DataEvent.Session.Session,
        event: {
            type: DataEvent.Session.DropSession
        },
        flags: {
            noResponse: true
        }
    });
}

export function player() {
    return wrap({
        type: DataEvent.Player.Player
    });
}

export function players() {
    return wrap({
        type: DataEvent.Player.Player,
        event: {
            type: DataEvent.Player.Players
        }
    });
}

export function updatePlayer(playerData) {
    if(playerData.info && playerData.info.tel) {
        playerData.info.tel = Format.parseTelNumber(playerData.info.tel);
    }

    return wrap({
        type: DataEvent.Player.Player,
        event: {
            type: DataEvent.Player.UpdatePlayer,
            info: playerData.info,
            reg: playerData.reg,
            imageData: playerData.imageData
        }
    });
}

export function updatePlayerFilter(gameFilter) {
    return wrap({
        type: DataEvent.Player.Player,
        event: {
            type: DataEvent.Player.UpdatePlayer,
            gameFilter
        },
        flags: {
            noResponse: true
        }
    });
}

export function clearPlayerFilter() {
    return updatePlayerFilter({ rinkGroup:[], lvl:[] });
}

export function updatePlayerByAdmin(playerId, playerData, imageData) {
    if(playerData.info && playerData.info.tel) {
        playerData.info.tel = Format.parseTelNumber(playerData.info.tel);
    }

    return wrap({
        type: DataEvent.Player.Player,
        event: {
            type: DataEvent.Player.UpdatePlayerByAdmin,
            playerId,
            info: playerData.info,
            reg: playerData.reg,
            hadDidntRead: playerData.hadDidntRead,
            adminNote: playerData.adminNote,
            imageData
        }
    });
}

export function updatePlayerFlags(playerId, flags) {
    return wrap({
        type: DataEvent.Player.Player,
        event: {
            type: DataEvent.Player.UpdateFlags,
            playerId,
            flags
        }
    });
}

export function ices() {
    return wrap({
        type: DataEvent.Ice.Ice,
        event: {
            type: DataEvent.Ice.Ices
        }
    });
}

export function createIce(ice) {
    return wrap({
        type: DataEvent.Ice.Ice,
        event: {
            type: DataEvent.Ice.CreateIce,
            ice
        }
    });
}

export function updateIce(ice) {
    return wrap({
        type: DataEvent.Ice.Ice,
        event: {
            type: DataEvent.Ice.UpdateIce,
            ice: {
                date: ice.date,
                rink: ice.rink,
                endDt: ice.endDt,
                deleted: ice.deleted,
                comment: ice.comment,
                vip: ice.vip
            }
        }
    });
}

export function deleteIce(iceKey) {
    return wrap({
        type: DataEvent.Ice.Ice,
        event: {
            type: DataEvent.Ice.UpdateIce,
            ice: {
                date: iceKey.date,
                rink: iceKey.rink,
                deleted: true
            }
        }
    });
}

export function games() {
    return wrap({
        type: DataEvent.Game.Game,
        event: {
            type: DataEvent.Game.Games
        }
    });
}

export function toggleGame(iceDate, iceRink, gameDate, going) {
    return wrap({
        type: DataEvent.GameEvent.GameEvent,
        event: {
            type: DataEvent.GameEvent.JoinLeave,
            gameEvent: {
                ice: {
                    date: iceDate,
                    rink: iceRink,
                },
                gameDate,
                going
            }
        }
    });
}

export function toggleGameHelper(iceDate, iceRink, gameDate, playerId, value) {
    return wrap({
        type: DataEvent.GameEvent.GameEvent,
        event: {
            type: DataEvent.GameEvent.ToggleHelper,
            gameEvent: {
                ice: {
                    date: iceDate,
                    rink: iceRink,
                },
                gameDate,
                playerId,
                isHelper: value
            }
        }
    });
}

export function setDedicatedGameHelper(iceDate, iceRink, gameDate, playerId, value) {
    return wrap({
        type: DataEvent.GameEvent.GameEvent,
        event: {
            type: DataEvent.GameEvent.SetDedicatedHelper,
            gameEvent: {
                ice: {
                    date: iceDate,
                    rink: iceRink,
                },
                gameDate,
                playerId,
                isHelper: value
            }
        }
    });
}

export function toggleIceAuto(iceDate, iceRink, going) {
    return wrap({
        type: DataEvent.GameEvent.GameEvent,
        event: {
            type: DataEvent.GameEvent.JoinLeaveWeekly,
            gameEvent: {
                ice: {
                    date: iceDate,
                    rink: iceRink
                },
                going
            },
        }
    });
}

export function editWeeklyPlayers(iceDate, iceRink, playerId, going) {
    return wrap({
        type: DataEvent.Ice.Ice,
        event: {
            type: DataEvent.Ice.EditAutoJoin,
            iceEvent: {
                ice: {
                    date: iceDate,
                    rink: iceRink
                },
                playerId,
                going
            }
        }
    });
}

export function toggleIceAutoHelper(iceDate, iceRink, playerId, value) {
    return wrap({
        type: DataEvent.Ice.Ice,
        event: {
            type: DataEvent.Ice.ToggleAutoHelper,
            iceEvent: {
                ice: {
                    date: iceDate,
                    rink: iceRink
                },
                playerId,
                isHelper: value
            },
        }
    });
}

export function setIceAutoDedicatedHelper(iceDate, iceRink, playerId) {
    return wrap({
        type: DataEvent.Ice.Ice,
        event: {
            type: DataEvent.Ice.SetDedicatedAutoHelper,
            iceEvent: {
                ice: {
                    date: iceDate,
                    rink: iceRink
                },
                playerId,
                isDedicatedHelper: true
            },
        }
    });
}

export function movePlayerToBackup(iceDate, iceRink, gameDate, playerId) {
    return wrap({
        type: DataEvent.GameEvent.GameEvent,
        event: {
            type: DataEvent.GameEvent.EditPlayer,
            gameEvent: {
                ice: {
                    date: iceDate,
                    rink: iceRink
                },
                gameDate,
                playerId,
                toBackups: true
            }
        }
    });
}

export function movePlayerToPlayers(iceDate, iceRink, gameDate, playerId) {
    return wrap({
        type: DataEvent.GameEvent.GameEvent,
        event: {
            type: DataEvent.GameEvent.EditPlayer,
            gameEvent: {
                ice: {
                    date: iceDate,
                    rink: iceRink
                },
                gameDate,
                playerId,
                toPlayers: true
            }
        }
    });
}

export function addUnregisteredPlayer(iceDate, iceRink, gameDate, name) {
    return wrap({
        type: DataEvent.GameEvent.GameEvent,
        event: {
            type: DataEvent.GameEvent.EditPlayer,
            gameEvent: {
                ice: {
                    date: iceDate,
                    rink: iceRink
                },
                gameDate,
                playerId: DataEvent.GameEvent.UnregisteredPlayer + name,
                toPlayers: true
            }
        }
    });
}

export function removePlayer(iceDate, iceRink, gameDate, playerId) {
    return wrap({
        type: DataEvent.GameEvent.GameEvent,
        event: {
            type: DataEvent.GameEvent.EditPlayer,
            gameEvent: {
                ice: {
                    date: iceDate,
                    rink: iceRink
                },
                gameDate,
                playerId,
                remove: true
            }
        }
    });
}

export function markPlayerPaid(iceDate, iceRink, gameDate, playerId, hasPaid) {
    return wrap({
        type: DataEvent.GameEvent.GameEvent,
        event: {
            type: DataEvent.GameEvent.MarkPlayer,
            gameEvent: {
                ice: {
                    date: iceDate,
                    rink: iceRink
                },
                gameDate,
                playerId,
                markPaid: hasPaid
            }
        }
    });
}

export function markPlayerDark(iceDate, iceRink, gameDate, playerId, isDark) {
    return wrap({
        type: DataEvent.GameEvent.GameEvent,
        event: {
            type: DataEvent.GameEvent.MarkPlayer,
            gameEvent: {
                ice: {
                    date: iceDate,
                    rink: iceRink
                },
                gameDate,
                playerId,
                markDark: isDark
            }
        }
    });
}

export function cancelGame(iceDate, iceRink, gameDate, isCancelled, cancelledReason = null) {
    return wrap({
        type: DataEvent.GameEvent.GameEvent,
        event: {
            type: DataEvent.GameEvent.CancelGame,
            gameEvent: {
                ice: {
                    date: iceDate,
                    rink: iceRink
                },
                gameDate,
                isCancelled,
                cancelledReason
            }
        }
    });
}

export function setGameNotice(iceDate, iceRink, gameDate, isNoticeEnabled, noticeText = null) {
    return wrap({
        type: DataEvent.GameEvent.GameEvent,
        event: {
            type: DataEvent.GameEvent.SetGameNotice,
            gameEvent: {
                ice: {
                    date: iceDate,
                    rink: iceRink
                },
                gameDate,
                isNoticeEnabled,
                noticeText
            }
        }
    });
}

export function logError(message, info) {
    return wrap({
        type: DataEvent.Feedback,
        message,
        info,
        flags: {
            noResponse: true
        }
    });
}

export function cypressInitServer(context, test, serverDate) {
    return wrap({
        type: DataEvent.Cypress,
        init: true,
        context,
        test,
        serverDate
    });
}

export function cypressSetServerDate(serverDate) {
    return wrap({
        type: 'cypress',
        setDateValue: true,
        serverDate
    });
}

export function cypressReadLog(context, logName, serverDate) {
    return wrap({
        type: DataEvent.Cypress,
        readLog: true,
        context,
        logName,
        serverDate
    });
}

export function cypressLeaveServer() {
    return wrap({
        type: DataEvent.Cypress,
        leave: true
    })
}

function wrap(data) {
    return {
        type: Actions.TYPE.Send,
        data
    }
}
