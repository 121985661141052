import { connect } from 'react-redux';
import React from 'react';

import PlayerItem from '../playerItemComponent';
import Div from '../../../div';

const ListByStatusContainer = ({ playersByStatus, playerFilter, selectPlayer }) => {
    const players = playersByStatus(playerFilter);

    return <React.Fragment>
        { header('Nový(+) / zmenený(?)') }
        { players.verify.map(
                (player, n) => <PlayerItem key={n} player={player} selectPlayer={selectPlayer} inList={true} />
          ) }

        { header('Nemá úroveň(-)') }
        { players.hasNoLevel.map(
                (player, n) => <PlayerItem key={n} player={player} selectPlayer={selectPlayer} inList={true} />
          ) }

        { header('Blokovaný(!)') }
        { players.isDisabled.map(
                (player, n) => <PlayerItem key={n} player={player} selectPlayer={selectPlayer} inList={true} />
          ) }
    </React.Fragment>
}

const mapStateToProps = state => ({
    playersByStatus: playerFilter => state.get.playerListsByStatus(playerFilter)
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListByStatusContainer)

function header(text) {
    return <Div setup='r center center' wRel='100' h='60'
         class='bgLighter fgDark fBig bordBottom' style={{ textAlign: 'center' }}>
         { text }
    </Div>
}
