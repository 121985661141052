import { TYPE, CONNECTION, VIEW } from '../actions';
import clone from '../../utils/clone';

export default function viewStack(state, action) {
    return state ? selectView(state, action) : emptyView();
}

function selectView(state, action) {
    const stack = clone(state.stack);

    switch(action.type) {
        case TYPE.Init:
            return emptyView();

        case TYPE.EmptyView:
            return emptyView();

        case TYPE.LoginView:
            return baseView(VIEW.Login);

        case TYPE.RegistrationView:
            return baseView(VIEW.Registration);

        case TYPE.ContentView:
            return baseView(VIEW.Content);

        case TYPE.ErrorView:
            return {
                stack: [ {
                    type: VIEW.Error,
                    params: clone(action.params)
                } ],
                sendCount: state.sendCount,
                connection: null
            }

        case TYPE.PushView:
            if(state.connection) {
                return clone(state);
            }

            if(action.view.isRootMenuItem) {
                popMenuViews(stack);
            }

            const stackIndex = indexInStack(stack, action.view)
            if(stackIndex !== -1) {
                console.log('view stact splice');
                stack.splice(stackIndex);
            }
            
            if(action.context) {
                storeContext(stack, action.context);
            } else {
                clearContext(stack);
            }

            setTopViewScroll(stack, action.scrollPos);
            stack.push(clone(action.view));

            return {
                stack,
                sendCount: state.sendCount,
                connection: clone(state.connection)
            }

        case TYPE.PopView:
            if(action.count !== null) {
                for(let i = 0; i < action.count; i++) {
                    if(stack.length > 1) {
                        stack.pop();
                    }
                }
            }
            
            if(action.until !== null) {
                while(stack[ stack.length - 1 ].type !== action.until) {
                    stack.pop();
                }
                stack.pop();
            }

            return {
                stack,
                sendCount: state.sendCount,
                connection: clone(state.connection)
            }

        case TYPE.BaseView:
            if(stack.length > 0) {
                stack[ 0 ].scrollPos = 0;
            }

            return {
                stack: stack.slice(0, 1),
                sendCount: state.sendCount,
                connection: clone(state.connection)
            }

        case TYPE.Connection:
            switch(action.state) {
                case CONNECTION.Sending:
                    if(state.connection === null) {
                        setTopViewScroll(stack, action.scrollPos, true);
                    }

                    return {
                        stack,
                        sendCount: action.clearSendCount
                            ? 1
                            : state.sendCount + 1,
                        connection: {
                            type: VIEW.Sending,
                            params: {
                                msg: 'Pripájam...'
                            }
                        }
                    };

                case CONNECTION.Reconnect:
                    setTopViewScroll(stack, action.scrollPos, false);
                    return {
                        stack,
                        sendCount: state.sendCount,
                        connection: {
                            type: VIEW.Reconnect
                        }
                    };

                case CONNECTION.Connected:
                    const sendCount = state.sendCount > 0 ? state.sendCount - 1 : 0;
                    return {
                        stack,
                        sendCount,
                        connection: sendCount > 0
                            ? clone(state.connection)
                            : null
                    }

                default:
                    return {
                        stack,
                        sendCount: state.sendCount,
                        connection: null
                    }
            }

        default:
            return clone(state);
    }
}

function emptyView() {
    return {
        stack: [ ],
        sendCount: 0,
        connection: null
    }
}

function baseView(type) {
    return {
        stack: [ { type } ],
        sendCount: 0,
        connection: null
    }
}

function setTopViewScroll(stack, scrollPos, overwrite = true) {
    const topView = stack.length > 0
        ? stack[ stack.length - 1 ]
        : null;

    if(topView) {
        if(topView.scrollPos === undefined || overwrite) {
            topView.scrollPos = scrollPos;
        }
    }
}

function indexInStack(stack, view) {
    const there = stack.filter(v => v.type === view.type);
    if(there.length !== 0) {
        return stack.indexOf(there[0]);
    } else {
        return -1;
    }
}

function popMenuViews(stack) {
    const hasRootMenuItem = stack.filter(view => view.isRootMenuItem).length > 0;
    if(hasRootMenuItem) {
        while(!stack[ stack.length-1 ].isRootMenuItem) {
            stack.pop();
        }
        stack.pop();
    }
}

function storeContext(stack, context) {
    const top = stack[ stack.length - 1 ];
    if(top) {
        top.context = context;
    }
}

function clearContext(stack) {
    const top = stack[ stack.length - 1 ];
    if(top) {
        delete top.context;
    }
}
