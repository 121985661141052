import React from 'react';

import Div from '../../../div';
import Icon from '../../../icon';

export default () =>
    <Div setup='r between center' wRel='100' css='ViewHeader'>
        <Div fx='100' setup='r start center'>
            <Icon css='IconBig' image='ice' />
            <Div class='padLeft'>Ľady</Div>
        </Div>
    </Div>
