import React from 'react';

import Div from '../../../../div';

export default ({ name, value, onChange, id }) =>
    <label style={{ width:'100%' }}>
        <Div setup='r between center' wRel='100'>
            <Div>VIP ľad:</Div>
            <Div fx='50' style={{textAlign: 'right' }}>
                <input type='checkbox' style={{height: '35px', width: '35px', margin: '0px'}}
                       checked={value ? 'checked' : ''} onChange={() => onChange(name, !value)}
                       id={id} />
            </Div>
        </Div>
    </label>