import { connect } from 'react-redux';

import Login from './login';
import * as Actions from '../../../actions';
import * as Requests from '../../../requests';
import { ThirdParty, isProduction } from '../../../../const';

function getLoginResult(result, errorHandler, isProd = true) {
    if(result.credential && result.clientId) {
        if(result.clientId !== ThirdParty.Google.AppId) {
            return null;
        }

        const loginResult = {
            userId: ThirdParty.Google.Prefix + 'token',
            userToken: ThirdParty.Google.Prefix + result.credential
        };
        
        if(result.profileObj) {
            loginResult.loginProfile = {
                email: result.profileObj.email,
                familyName: result.profileObj.familyName,
                givenName: result.profileObj.givenName
            }
        }

        return loginResult;
    } else {
        if(!isProd && result.userId.indexOf(ThirdParty.Cypress.Prefix) === 0) {
            return result;
        }
    }

    errorHandler(JSON.stringify(result, null, "\t"), null);
    return null;
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
    onLoggedIn: (loginResult, errorHandler) => {
        if(isProduction()) {
            const result = getLoginResult(loginResult, errorHandler);
            if(result) {
                dispatch(Actions.token(result));
            }
        } else {
            dispatch(
                Actions.token(
                    getLoginResult(loginResult, errorHandler, false)
                )
            );
        }
    },
    onLoginError: (message) => {
        dispatch(Requests.logError(message, null))
    }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)
